const initialState = {
  isLoading: false,
  totalRegistros: 0,
  mensagem: [],
  open: false,
  id: 0,
  tipo: '',
  titulo: '',
  textoMensagem: '',
  dataInicio: '',
  dataTermino: '',
  mensagensLojas: [],
  mensagensFornecedores: [],
  todasLojas: false,
  todosFornecedores: false,
  modulo: 0,
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function mensagem(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_MENSAGEM':
      return {
        ...state,
        mensagem: action.mensagem,
        totalRegistros: action.totalRegistros
      }
    case 'MENSAGEM_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.mensagem.id,
        tipo: action.mensagem.tipo,
        titulo: action.mensagem.titulo,
        textoMensagem: action.mensagem.textoMensagem,
        dataInicio: action.mensagem.dataInicio,
        dataTermino: action.mensagem.dataTermino,
        mensagensLojas: action.mensagem.mensagensLojas,
        mensagensFornecedores: action.mensagem.mensagensFornecedores,
        todasLojas: action.mensagem.todasLojas,
        todosFornecedores: action.mensagem.todosFornecedores,
        modulo: action.mensagem.modulo,
        excluido: action.mensagem.excluido
      }
    case 'MENSAGEM_UPDATED':
      return state
    case 'MENSAGEM_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'MENSAGEM_CLEAR_ALL':
      return {
        ...state,
        mensagem: []
      }
    case 'MENSAGEM_CLEAR_MENSAGEMLOJAS':
      return {
        ...state,
        mensagensLojas: []
      }
    case 'MENSAGEM_CLEAR_MENSAGEMFORNECEDORES':
      return {
        ...state,
        mensagensFornecedores: []
      }
    case 'MENSAGEM_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'MENSAGEM_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'MENSAGEM_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
