import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Header, Footer } from '../../_reports';
import moment from 'moment'
import {
  Grid,
  LinearProgress
} from '@material-ui/core'

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 25
  },
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const RelatorioVendaInsumoPrint = (props) => {

  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;
  const relatorio = props.relatorio.relatorio.lista

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 3000);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready ?
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer height="100%" width="100%" >
          <Document onRender={onRendered}>
            <Page orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header title='Relatório de Vendas de Insumos' />
              {idLoja == 0 ?
                <View style={[styles.row, { marginTop: 40 }]} key={0} >
                  <View style={[styles.header, { width: 90 }]}>
                    <Text>Data</Text>
                  </View>
                  <View style={[styles.header, { width: 170 }]}>
                    <Text>Loja</Text>
                  </View>
                  <View style={[styles.header, { width: 200 }]}>
                    <Text>Produto</Text>
                  </View>
                  <View style={[styles.header, { width: 250 }]}>
                    <Text>Chave Nota</Text>
                  </View>
                  <View style={[styles.header, { width: 90 }]}>
                    <Text>Quantidade</Text>
                  </View>
                </View>
                :
                <View style={[styles.row, { marginTop: 40 }]} key={0} >
                  <View style={[styles.header, { width: 150 }]}>
                    <Text>Data</Text>
                  </View>
                  <View style={[styles.header, { width: 200 }]}>
                    <Text>Produto</Text>
                  </View>
                  <View style={[styles.header, { width: 250 }]}>
                    <Text>Chave Nota</Text>
                  </View>
                  <View style={[styles.header, { width: 200 }]}>
                    <Text>Quantidade</Text>
                  </View>
                </View>
              }
              {
                relatorio !== undefined && relatorio.length > 0 ?
                  relatorio.map(n => {
                    var retorno = (
                      idLoja == 0 ?
                        <View style={[styles.row]} key={n.id} >
                          <View style={[styles.cell, { width: 90 }]}>
                            <Text>{n.data && moment(n.data).format("DD/MM/YYYY HH:mm")}</Text>
                          </View>
                          <View style={[styles.cell, { width: 170 }]}>
                            <Text>{n.loja}</Text>
                          </View>
                          <View style={[styles.cell, { width: 200 }]}>
                            <Text>{n.descricaoProduto}</Text>
                          </View>
                          <View style={[styles.cell, { width: 250 }]}>
                            <Text>{n.chaveNota || ''}</Text>
                          </View>
                          <View style={[styles.cell, { width: 90 }]} align="right">
                            <Text>{n.quantidade ? n.quantidade.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}</Text>
                          </View>
                        </View>
                        :
                        <View style={[styles.row]} key={n.id} >
                          <View style={[styles.cell, { width: 150 }]}>
                            <Text>{n.data && moment(n.data).format("DD/MM/YYYY HH:mm")}</Text>
                          </View>
                          <View style={[styles.cell, { width: 200 }]}>
                            <Text>{n.descricaoProduto}</Text>
                          </View>
                          <View style={[styles.cell, { width: 250 }]}>
                            <Text>{n.chaveNota || ''}</Text>
                          </View>
                          <View style={[styles.cell, { width: 200 }]} align="right">
                            <Text>{n.quantidade ? n.quantidade.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}</Text>
                          </View>
                        </View>
                    );
                    return retorno;
                  })
                :
                <View style={[styles.row]} key={0} >
                  <View style={[styles.cell, { width: 800 }]}>
                    <Text>Nenhum registro encontrado</Text>
                  </View>
                </View>
              }
              <Footer />
            </Page>
          </Document>
        </PDFViewer>
      </div>
      :
      <Grid lg={12} md={12} xl={12} xs={12}>
        <LinearProgress color="secondary" />
      </Grid>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioVendaInsumoPrintPage = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(RelatorioVendaInsumoPrint));
export { connectedRelatorioVendaInsumoPrintPage as RelatorioVendaInsumoPrint };
