import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Avatar,
  Backdrop,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  Input,
  IconButton,
  LinearProgress,
  MenuItem,
  Tooltip,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  FormControlLabel,
  Switch

} from '@material-ui/core'
import {
  ValidatorForm,
  SelectValidator,
  TextValidator
} from 'react-material-ui-form-validator'
import { notificacaoAction, notificacaoFiltroAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { Page, ImageUploader, EditorHeader } from 'components'
import { uploadImage } from '_services'
import { errorsUploadImage, getInitials } from 'utils'
import SaveIcon from '@material-ui/icons/Save';
import PersonIcon from '@material-ui/icons/Person';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SendIcon from '@material-ui/icons/Send';
import { AccessControl } from '../../components/Utils/AccessControl'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import { ModalNotificacaoFiltrosItens } from './modalNotificacaoFiltrosItens'
import { ModalNotificacaoUsuarios } from './modalNotificacaoUsuarios'
import moment from 'moment'
import { ModalNotificacaoTestes } from './modalNotificacaoTestes'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const NotificacaoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, notificacao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)
  const confirm = useConfirm()
  const [, forceUpdate] = useState(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {

    if (params.id) {
      dispatch(notificacaoAction.getNotificacaoById(params.id))
    } else {
      dispatch(notificacaoAction.clear())
      setImagem(null)
    }

  }, []);

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...notificacao,
        caminhoImagem: null
      }
      dispatch(
        notificacaoAction.editNotificacaoInfo(notificacao.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(notificacaoAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const handleClickOpenModalNotificacaoTestes = () => {
    dispatch(notificacaoAction.openModalNotificacaoTestes(true));
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...notificacao,
          caminhoImagem: response.toString()
        }

        if (notificacao.id !== 0) {
          dispatch(
            notificacaoAction.editNotificacaoInfo(notificacao.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(notificacaoAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {

  }, [ready, props.notificacao.isLoading])

  useEffect(() => {
    setImagem(notificacao.caminhoImagem)
  }, [notificacao.caminhoImagem, open]);

  useEffect(() => {
    //  forceUpdate(n => !n);
  }, [open, imagem]);

  const handleChange = (prop) => (event) => {
    dispatch(notificacaoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      programado: props.notificacao.programado,
      dataHora: (params.id || props.notificacao.programado ? props.notificacao.dataHora : moment().format('YYYY-MM-DD HH:mm:ss')),
      titulo: props.notificacao.titulo,
      descricao: props.notificacao.descricao,
      caminhoImagem: props.notificacao.caminhoImagem,
      acao: props.notificacao.acao,
      chave: props.notificacao.chave
    }

    if (params.id) {
      dispatch(notificacaoAction.editNotificacaoInfo(params.id, payload, true))
    } else {
      dispatch(notificacaoAction.createNotificacao(payload))
    }

  };

  const handleSend = (event) => {

    if (params.id) {
      dispatch(notificacaoAction.sendNotificacao(params.id))
    }
    else if (notificacao.id) {
      dispatch(notificacaoAction.sendNotificacao(notificacao.id))
    }

  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta notificação?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(notificacaoFiltroAction.deleteNotificacaoFiltroById(id))
    })
  }

  const handleClickOpenModalNotificacaoFiltrosItens = () => {
    dispatch(notificacaoFiltroAction.clearInfo())
    dispatch(notificacaoAction.openModalNotificacaoFiltrosItens(true))
  }

  const handleClickOpenModalNotificacaoUsuarios = () => {
    dispatch(notificacaoAction.openModalNotificacaoUsuarios(true))
  }

  function switchFiltro(val) {
    var answer = "";
    switch (val) {
      case "lojasFidelizacoes":
        answer = "Usuários que fidelizaram nas Lojas";
        break;
      case "lojasTrocas":
        answer = "Usuários que realizaram trocas nas Lojas";
        break;
      case "produtosFidelizacoes":
        answer = "Usuários que fidelizaram os Produtos";
        break;
      case "produtosTrocas":
        answer = "Usuários que trocaram pelos Produtos";
        break;
      case "gruposFidelizacoes":
        answer = "Usuários que fidelizaram os Grupos";
        break;
      case "gruposTrocas":
        answer = "Usuários que trocaram pelos Grupos";
        break;
      case "usuariosCadastradosApp":
        answer = "Todos usuários cadastrados no App";
        break;
      case "mulheresCadastradasApp":
        answer = "Todos usuários do sexo feminino cadastrados no App";
        break;
      case "homensCadastradosApp":
        answer = "Todos usuários do sexo masculino cadastrados no App";
        break;
      default:
        answer = "";
    }
    return answer;
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Notificação'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Notificações - Editar Notificação'
          : 'Gerência de Notificações - Nova Notificação'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={2000000}
              resolutionWidth={640}
              resolutionHeight={360}
              resolutionType={"less"}
              removeImage={removeImage}
              props={notificacao}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>

        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Notificações"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Notificação' tabela='Notificacao' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.notificacao.programado}
                            onChange={handleChange('programado')}
                            color="primary"
                          />
                        }
                        label="Programar"
                      />
                    </Grid>
                    <Grid item lg={5} md={5} xl={5} xs={12}>
                      {
                        props.notificacao.programado ?
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="dataHora"
                            label="Data/Hora *"
                            margin="normal"
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            value={props.notificacao.dataHora ? moment(props.notificacao.dataHora).format("yyyy-MM-DDTHH:mm") : ''}
                            onChange={handleChange('dataHora')}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        :
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="dataHora"
                            label="Data/Hora"
                            type="datetime-local"
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            value={props.notificacao.dataHora ? moment(props.notificacao.dataHora).format("yyyy-MM-DDTHH:mm") : ''}
                            value={moment(props.notificacao.dataHora).format("yyyy-MM-DDTHH:mm")}
                            variant="outlined"
                            disabled
                          />
                      }
                    </Grid>
                    <Grid item lg={5} md={5} xl={5} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="dataHoraEnvio"
                        label="Data/Hora Envio"
                        margin="normal"
                        value={props.notificacao.dataHoraEnvio ? moment(props.notificacao.dataHoraEnvio).format("DD/MM/YYYY HH:mm") : ''}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="titulo"
                        label="Título *"
                        margin="normal"
                        onChange={handleChange('titulo')}
                        value={props.notificacao.titulo}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição *"
                        margin="normal"
                        onChange={handleChange('descricao')}
                        value={props.notificacao.descricao}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}

                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Ação"
                        labelId="acao"
                        errorMessages={['Campo obrigatório!']}
                        value={props.notificacao.acao || 'Home'}
                        onChange={handleChange('acao')}
                        name="acao"
                        fullWidth
                        inputProps={{
                          name: "Acao",
                          id: "acao"
                        }}
                        input={<Input id="acao" />}
                        margin="normal"
                        variant="outlined"
                        id="acao"
                      >
                        <MenuItem value={'DetalheNotificacao'}>Conteúdo da Notificação</MenuItem>
                        <MenuItem value={'Home'}>Tela inicial</MenuItem>
                        <MenuItem value={'OpenLink'}>Abrir link externo</MenuItem>
                        <MenuItem value={'Trocas'}>Tela de trocas</MenuItem>
                        <MenuItem value={'LojasMenu'}>Lojas</MenuItem>
                      </SelectValidator>
                    </Grid>
                    {props.notificacao.acao == 'OpenLink' ?
                      <Grid item lg={8} md={8} xl={8} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          label="Chave"
                          margin="normal"
                          onChange={handleChange('chave')}
                          value={props.notificacao.chave}
                          variant="outlined"
                        />
                      </Grid>
                      :
                      <Grid item lg={8} md={8} xl={8} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          label="Chave"
                          margin="normal"
                          type="number"
                          onChange={handleChange('chave')}
                          value={props.notificacao.chave}
                          variant="outlined"
                        />
                      </Grid>
                    }
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions >
                  <Grid item lg={12} md={12} xl={12} xs={12}>

                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/Notificacoes')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.notificacao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.notificacao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.notificacao.isLoading ? 'Atualizando notificação...' : 'Atualizar') : (props.notificacao.isLoading ? 'Salvando notificação...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>

            </Card>
          </Grid>
        </Grid>

        <Grid item lg={4} md={6} xl={3} />

        {(props.notificacao.id !== 0 ?
          <Grid item lg={8} md={6} xl={9} xs={12}>
            <Grid item md={12} xs={12}>
              <Card>
                <ValidatorForm
                  className={classes.form}
                  ref={formRef}
                  id="formNotificacao"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardHeader
                    subheader="Defina quais usuários receberão a notificação"
                    title={
                      <Typography variant="h4">
                        <b>{'Filtro de Usuários'}</b>
                      </Typography>
                    }
                  />
                  <Divider />
                  <CardContent lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>

                    <Grid item lg={8} md={8} xl={8} xs={8} style={{ justifyContent: 'center', alignContent: 'center' }}>
                      <Button style={{ width: '150px', alignContent: 'center' }} name="btnNovo" id="btnNovo" className="btn" variant="contained" color="primary" component='a' onClick={() => handleClickOpenModalNotificacaoFiltrosItens()}>Novo&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>

                    <Grid item lg={4} md={4} xl={4} xs={4} style={{
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlign: 'right',
                      justifyContent: 'right'
                    }}>
                      {(props.notificacao.dataHoraEnvio != null ?
                        <>
                          <Typography>
                            Taxa de Abertura {(props.notificacao ? props.notificacao.taxaAbertura : 0)}% de {(props.notificacao ? props.notificacao.totalNotificacoesUsuarios : 0)}
                          </Typography>
                          <LinearProgress color="secondary" variant="determinate" value={props.notificacao.taxaAbertura} />
                        </>
                        :
                        <Typography>
                          Usuários que receberão a notificação: {(props.notificacao ? props.notificacao.totalNotificacoesUsuarios : 0)}
                        </Typography>
                      )}
                    </Grid>
                  </CardContent>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'left', alignContent: 'center', marginLeft: '12px' }}>
                    <TableContainer>
                      <Table>
                        {/*<TableHead>
                          <TableRow>
                            <TableCell>Data/Hora</TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>*/}
                        <TableBody>
                          {undefined !== props.notificacaoFiltro.notificacaoFiltro && props.notificacaoFiltro.notificacaoFiltro.length
                            ? props.notificacaoFiltro.notificacaoFiltro.map((n) => {
                              return (
                                <TableRow hover key={n.id} lg={12} md={12} xl={12} xs={12} >
                                  <TableCell component="th" scope="row" lg={12} md={12} xl={12} xs={12} >
                                    {switchFiltro(n.filtro)}
                                  </TableCell>
                                  <TableCell align="right" lg={12} md={12} xl={12} xs={12} style={{ display: 'flex' }}>
                                    <AccessControl
                                      rule={'notificacoes.delete'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <IconButton
                                          aria-label="Delete"
                                          onClick={() => handleClick(n.id)}
                                          size="small"
                                        >
                                          <Tooltip title="Excluir">
                                            <DeleteIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    />
                                    <AccessControl
                                      rule={'notificacoes.edit'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <IconButton
                                          aria-label="Edit"
                                          component="a"
                                          onClick={() => handleClickOpenModalNotificacaoFiltrosItens()}
                                          size="small"
                                          disabled={props.notificacao.isLoading}
                                          startIcon={<SaveIcon />}
                                          endIcon={props.notificacao.isLoading && <CircularProgress size={24} />}
                                        >
                                          <Tooltip title="Editar Filtros">
                                            <Tooltip>
                                              <FilterListIcon />
                                            </Tooltip>
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Divider />
                  <CardActions >

                    {/*
                    <Grid item lg={3} md={3} xl={3} xs={3}>
                      <Typography>
                        Taxa de Abertura {(props.notificacao ? props.notificacao.taxaAbertura : 0)}%
                      </Typography>
                      <LinearProgress color="secondary" variant="determinate" value={props.notificacao.taxaAbertura} />
                    </Grid>
                    */}

                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlign: 'right',
                      justifyContent: 'right'
                    }}>
                      <Button
                        color="default"
                        variant="contained"
                        onClick={() => handleClickOpenModalNotificacaoUsuarios()}
                        variant="contained"
                        disabled={props.notificacao.isLoading || undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0 && props.notificacao.totalNotificacoesUsuarios == 0}
                        startIcon={<PersonIcon />}
                        endIcon={props.notificacaoFiltro.isLoading && <CircularProgress size={24} />}
                      >
                        Ver lista de Usuários
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleClickOpenModalNotificacaoTestes()}
                        variant="contained"
                        disabled={props.notificacao.isLoading || undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0}
                        startIcon={<SendIcon />}
                        endIcon={props.notificacao.isLoading && <CircularProgress size={24} />}
                      >
                        Testar envio
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSend()}
                        variant="contained"
                        disabled={props.notificacao.isLoading || undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0}
                        startIcon={<SendIcon />}
                        endIcon={props.notificacao.isLoading && <CircularProgress size={24} />}
                      >
                        Enviar
                      </Button>
                    </Grid>

                  </CardActions>

                </ValidatorForm>
              </Card>
            </Grid>
          </Grid>
          :
          null
        )}

      </Grid>

      <ModalNotificacaoFiltrosItens />
      <ModalNotificacaoUsuarios />
      <ModalNotificacaoTestes />

    </Page>

  )
}

NotificacaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedNotificacaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(NotificacaoEdit))
)
export { connectedNotificacaoEditPage as NotificacaoEdit }
