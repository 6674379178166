import React, { useEffect, useState, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Typography
} from "@material-ui/core"
import { AccessControl } from "../../components/Utils/AccessControl"
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import CloseIcon from '@material-ui/icons/Close';
import { estadoAction, totvsInsumoPrecoReferenciaAction, totvsInsumoUnidadeAction } from '../../_actions'
import { isEmpty } from 'lodash'
import moment from 'moment'

export const InsumoPrecoReferenciaModal = ({ modal, toggle, idTotvsInsumoPreco, idTotvsInsumo }, props) => {
  const dispatch = useDispatch()
  const { className, ...rest } = props
  const classes = props
  const formRef = createRef(null);

  const estados = useSelector(state => state.estado.estado)
  const unidades = useSelector(state => state.totvsInsumoUnidade.totvsInsumoUnidade)

  const totvsInsumoPrecoReferencia = useSelector(state => state.totvsInsumoPrecoReferencia)

  useEffect(() => {
    if (idTotvsInsumoPreco) {
      dispatch(totvsInsumoPrecoReferenciaAction.getTotvsInsumoPrecoReferenciaById(idTotvsInsumoPreco))
    } else {
      dispatch(totvsInsumoPrecoReferenciaAction.clear())
    }

    if (idTotvsInsumo) {
      dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeSelectTotvsInsumo(idTotvsInsumo))
    }

    dispatch(estadoAction.getEstadoSelect())
  }, [])

  const handleChange = (prop) => (event) => {
    dispatch(totvsInsumoPrecoReferenciaAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {
    const payload = {
      id: idTotvsInsumoPreco,
      idTotvsInsumo: idTotvsInsumo,
      idTotvsInsumoUnidade: totvsInsumoPrecoReferencia.idTotvsInsumoUnidade,
      preco: totvsInsumoPrecoReferencia.preco,
      idEstado: totvsInsumoPrecoReferencia.idEstado,
      dataHoraAtualizacao: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    if (idTotvsInsumoPreco) {
      dispatch(totvsInsumoPrecoReferenciaAction.editTotvsInsumoPrecoReferenciaInfo(idTotvsInsumoPreco, payload, true))
    } else {
      dispatch(totvsInsumoPrecoReferenciaAction.createTotvsInsumoPrecoReferencia(payload))
    }

    toggle()
  };

  return (
    <Dialog open={modal} onClose={toggle} fullWidth>
      <ValidatorForm
        ref={formRef}
        id="formPrecoReferencia"
        onSubmit={(event) => handleSubmit(event)}
      >
        <DialogContent>
          <DialogContentText>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              {(idTotvsInsumoPreco ? 'EDITAR' : 'ADICIONAR') + ' PREÇO DE REFERÊNCIA'}
            </Typography>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <SelectValidator
                id="unidade"
                label="Unidade *"
                name="unidade"
                value={totvsInsumoPrecoReferencia.idTotvsInsumoUnidade || ''}
                onChange={handleChange('idTotvsInsumoUnidade')}
                inputProps={{
                  name: "Unidade *",
                  id: "Unidade",
                  shrink: true
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              >
                <MenuItem disabled value="">
                  <em>Unidade *</em>
                </MenuItem>
                {!isEmpty(unidades) &&
                  undefined !== unidades &&
                  unidades.length &&
                  unidades.map((row, index) => (
                    <MenuItem key={index} value={row.value}>
                      {row.text}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
            <Grid item lg={9} md={9} xl={9} xs={12}>
              <TextValidator
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="normal"
                fullWidth
                type="number"
                label="Preço *"
                id="preco"
                value={totvsInsumoPrecoReferencia.preco}
                onChange={handleChange('preco')}
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item lg={3} md={3} xl={3} xs={12}>
              <SelectValidator
                id="estado"
                label="Estado *"
                name="estado"
                value={totvsInsumoPrecoReferencia.idEstado || ''}
                onChange={handleChange('idEstado')}
                inputProps={{
                  name: "Estado *",
                  id: "Estado",
                  shrink: true
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              >
                <MenuItem disabled value="">
                  <em>Estado *</em>
                </MenuItem>
                {!isEmpty(estados) &&
                  undefined !== estados &&
                  estados.length &&
                  estados.map((row, index) => (
                    <MenuItem key={index} value={row.value}>
                      {row.text}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={toggle}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
          >
            {idTotvsInsumoPreco ? 'Editar' : 'Adicionar'}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}
