import { crudService } from '../_services'
import { toast } from 'react-toastify'

export const totvsAction = {
  updateProdutosByIdLoja,
  updateProdutos,
  importarFichaTecnica
}

function updateProdutosByIdLoja(idLoja) {
  return (dispatch) => {
    const id = toast.loading("Atualizando cardápio...")
    let apiEndpoint = 'totvs/cardapio/update/' + idLoja
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.statusText == 'OK' || response.request.statusText == 'OK' || response.request.status == 200) {
          toast.dismiss(id);
          toast.success("Feito! Cardápio atualizado com sucesso! 😎", { autoClose: false });
        }
      }).catch((err) => {
        toast.dismiss(id);
        return err.response;
      })
  }
}

function updateProdutos() {
  return (dispatch) => {
    const id = toast.loading("Isso pode levar algum tempo. Atualizando cardápios...")
    let apiEndpoint = 'totvs/cardapio/update'
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.statusText == 'OK' || response.request.statusText == 'OK' || response.request.status == 200) {
          toast.dismiss(id);
          toast.success("Feito! Cardápios atualizados com sucesso! 😎", { autoClose: false });
        }
      }).catch((err) => {
        toast.dismiss(id);
        return err.response;
      })
  }
}


function importarFichaTecnica(payload) {
  return (dispatch) => {
    const id = toast.loading("Importando ficha técnica...")
    let apiEndpoint = 'totvs/importar/fichaTecnica';
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.statusText == 'OK' || response.request.statusText == 'OK' || response.request.status == 200) {
          toast.dismiss(id);
          toast.success("Feito! Ficha técnica importada com sucesso! 😎", { autoClose: false });
        }
      }).catch((err) => {
        toast.dismiss(id);
        //toast.update(id, {
        //  render: "Oops! Erro ao importar ficha técnica1! 😥", autoClose: 2000, type: "error", isLoading: false
        //});
        return err.response;
      });
  }
}

export function isLoading() {
  return {
    type: 'TOTVS_ISLOADING'
  }
}
export function notIsLoading() {
  return {
    type: 'TOTVS_NOTISLOADING'
  }
}
