import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { listaPrecoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  CircularProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination 
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import TitleContentListaPreco from '../TitleContentListaPreco'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const ListaPreco = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { listaPreco } = props.listaPreco
  const { totalRegistros } = props.listaPreco
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.listaPreco);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc'
    };

    dispatch(listaPrecoAction.getListaPreco(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(listaPrecoAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(listaPrecoAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.listaPreco.page = 0;
    dispatch(listaPrecoAction.onChangeProps('term', fakeEvent))
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Lista de Preço?',
      description: 'Essa operação é irreversível',
      disabled: props.listaPreco.isLoading,
      confirmationText: props.listaPreco.isLoading ? 'Excluindo Lista de Preços...' : 'Sim, excluir!',
      cancellationText: 'Não!'
      
    }).then(() => {
      dispatch(listaPrecoAction.deleteListaPrecoById(id))
    })
  }

  return (
    <Page className={classes.root} title="Listas de Preços">
      <AccessControl
        rule={'listasPrecos'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContentListaPreco
              rule={'listasPrecos.create'}
              length={listaPreco !== undefined ? listaPreco.length : 0}
              subTitle={'GERÊNCIA DE LISTA DE PREÇO'}
              title={'Lista de Listas de Preços'}
              href={'/listaPreco'}
            />
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== listaPreco && listaPreco.length
                    ? listaPreco.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.nome}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'listasPrecos.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/listaPreco/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title="Editar">
                                      <Tooltip >
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'listasPrecos.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip
                                      title="Excluir"
                                      >
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
ListaPreco.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    listaPreco: state.listaPreco
  }
}
const connectedListaPrecoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ListaPreco))
)
export { connectedListaPrecoPage as ListaPreco }
