import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const produtoAction = {
  getProduto,
  getProdutoSelect,
  getProdutoByLojaSelect,
  getProdutoCliente,
  addProduto,
  getProdutoById,
  changeDetailsProduto,
  onChangeDetailsProduto,
  onChangeProps,
  editProdutoInfo,
  editProdutoInfoTodos,
  editProdutosDetails,
  createProduto,
  deleteProdutoById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getProduto(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'produtos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeProdutosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getProdutoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'produtos/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeProdutosList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getProdutoByLojaSelect(id) {
  return (dispatch) => {
    let apiEndpoint = 'produtos/select/loja/' + id;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeProdutosListSelect(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getProdutoCliente(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'produtos/cliente/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeProdutosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createProduto(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'produtos/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createProdutoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/produtos')
        } else {
          toast.err('Oops! Erro ao cadastrar produto! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar produto! 😥')
        return err.response;
      })
  }
}

function getProdutoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'produtos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editProdutosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function changeDetailsProduto(produto) {
  return (dispatch) => {
    if (produto) {
      dispatch(onChangeDetailsProduto(produto))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsProduto(produto) {
  return [
    {
      type: 'CHANGE_DETAILS_PRODUTO',
      id: produto.id,
      nome: produto.nome,
      descricao: produto.descricao,
    }
  ]
}

function editProdutoInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'produtos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedProdutoInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/produtos')
          }
        }
      }).catch((err) => {
          //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar produto! 😥')
          return err.response;
      })
  }
}

function editProdutoInfoTodos(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'produtos/todos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedProdutoInfo())
          toast.info(ret.message);
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/produtos')
          }
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar produto! 😥')
        return err.response;
      })
  }
}

function deleteProdutoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'produtos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteProdutosDetails())
      dispatch(getProduto(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addProduto(payload) {
  return [{ type: 'ADD_PRODUTO', produto: payload }, clear()]
}

export function changeProdutosList(produto, totalRows) {
  return {
    type: 'FETCHED_ALL_PRODUTO',
    produto: produto,
    totalRegistros: totalRows
  }
}

export function changeProdutosListSelect(produto, totalRows) {
  return {
    type: 'FETCHED_ALL_PRODUTO_SELECT',
    produto: produto,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'PRODUTO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'PRODUTO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'PRODUTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editProdutosDetails(produto) {
  return {
    type: 'PRODUTO_DETAIL',
    id: produto.id,
    nome: produto.nome,
    descricao: produto.descricao,
    idProdutoReferencia: produto.idProdutoReferencia,
    caminhoImagem: produto.caminhoImagem,
    trocavel: produto.trocavel,
    novidade: produto.novidade,
    pontos: produto.pontos,
    fatorPontuacao: produto.fatorPontuacao,
    preco: produto.preco,
    limiteTrocaClientes: produto.limiteTrocaClientes,
    total: produto.total,
    idLoja: produto.idLoja
  }
}

export function updatedProdutoInfo() {
  return {
    type: 'PRODUTO_UPDATED'
  }
}

export function createProdutoInfo() {
  return {
    type: 'PRODUTO_CREATED_SUCCESSFULLY'
  }
}

export function deleteProdutosDetails() {
  return {
    type: 'DELETED_PRODUTO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'PRODUTO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'PRODUTO_NOTISLOADING'
  }
}
