import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  Avatar
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  subTitle: {
    fontWeight: 200
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3),
    height: 5,
    borderRadius: 5
  }
}))

const ClientesRecorrentesChartCard = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const clientesRecorrentes30dias = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.clientesRecorrentes30dias : undefined) : undefined))
  const rankingClientesRecorrentes30dias = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.rankingClientesRecorrentes30dias : undefined) : undefined))
  const totalLojas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.totalLojas : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  //const percentage = () => {
  //  var total = eventosFuturos + eventosPassados
  //  var currentValue = eventosFuturos
  //  var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))

  //  if (isNaN(percentage)) {
  //    return ' '
  //  } else {
  //    return percentage
  //  }
  //}
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              RECORRENTES
            </Typography>
            <Tooltip title='Clientes que pontuaram nos últimos 30 dias'>
              <Typography variant="h3">
                {isLoading ? (
                  <Skeleton />
                ) : (
                    (isNaN(clientesRecorrentes30dias) ? 0 : clientesRecorrentes30dias)
                )}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Typography
              className={classes.subTitle}
              gutterBottom
              variant="body2"
            >
              Últimos 30 dias
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PersonIcon/>
            </Avatar>
            <Typography variant="h5" style={{ marginTop: 5 }}>
              {isLoading ? (
                <Skeleton />
              ) : (
                (rankingClientesRecorrentes30dias != null ? rankingClientesRecorrentes30dias + 'º / ' + totalLojas : '')
              )}
            </Typography>
          </Grid>
        </Grid>
        {/*
        <LinearProgress
          className={classes.progress}
          value={percentage()}
          variant="determinate"
        />
        <Typography variant="body2">
          {' '}
          {isLoading ? <Skeleton /> : `${percentage()}% dos eventos`}
        </Typography>
        */}
      </CardContent>
    </Card>
  )
}

ClientesRecorrentesChartCard.propTypes = {
  className: PropTypes.string
}

export default ClientesRecorrentesChartCard
