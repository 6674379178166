const initialState = {
  isLoading: false,
  totalRegistros: 0,
  categoriaCompra: [],
  id: 0,
  nome: '',
  cpv: false,
  excluido: false,
  loja: null,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function categoriaCompra(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CATEGORIACOMPRA':
      return {
        ...state,
        categoriaCompra: action.categoriaCompra,
        totalRegistros: action.totalRegistros
      }
    case 'CATEGORIACOMPRA_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.categoriaCompra.id,
        nome: action.categoriaCompra.nome,
        cpv: action.categoriaCompra.cpv,
        excluido: action.categoriaCompra.excluido,
      }
    case 'CATEGORIACOMPRA_UPDATED':
      return state
    case 'CATEGORIACOMPRA_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
      }
    case 'CATEGORIACOMPRA_CLEAR_ALL':
      return {
        ...state,
        categoriaCompra: []
      }
    case 'CATEGORIACOMPRA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CATEGORIACOMPRA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CATEGORIACOMPRA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
