const initialState = {
  usuarioLoja: [],
  id: '',
  isLoading: false,
  idUsuario: '',
  idLoja: '',
  excluido: null,
  loja: {},
  categoriaId: 0
}

export function usuarioLoja(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USUARIO_LOJA_SUCCESS':
      return {
        ...state,
        idLoja: '',
        usuarioLoja: [
          ...state.usuarioLoja,
          action.usuarioLoja
        ]
      }
    case 'FETCHED_ALL_USUARIO_LOJA':
      return {
        ...state,
        usuarioLoja: action.usuarioLoja
      }
    case 'USUARIO_LOJA_DETAIL':
      return {
        ...state,
        id: action.id,
        idUsuario: action.idUsuario,
        idLoja: action.idLoja,
        excluido: action.excluido,
        loja: action.loja,
        categoriaId: action.categoriaId
      }
    case 'USUARIO_LOJA_UPDATED':
      return state
    case 'USUARIO_LOJA_ADD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_LOJA_ADD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_LOJA_ADD_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_LOJA_CLEAR':
      return initialState
    case 'USUARIO_LOJA_CLEAR_ALL':
      return {
        ...state,
        usuarioLoja: []
      }
    case 'USUARIO_LOJA_CLEAR_SELECTED':
      return {
        ...state,
        id: '',
        isLoading: false,
        idUsuario: '',
        idLoja: '',
        excluido: null,
        loja: {},
        categoriaId: 0
      }
    case 'CHANGE_DETAILS_USUARIO_LOJA':
      return {
        ...state,
        id: action.id,
        idUsuario: action.idUsuario,
        idLoja: action.idLoja,
        excluido: action.excluido,
        loja: action.loja
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        usuarioLoja: action.user.usuariosLojas
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        usuarioLoja: action.user.usuariosLojas
      }
    default:
      return state
  }
}
