import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { respostaChecklistOperacaoAction, relatorioAction, checklistOperacaoAction, checklistOperacaoItemAction } from '../../_actions'
import { PDFViewer, Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { toast } from 'react-toastify'
import { history } from '../../utils'
import moment from 'moment'
import {
  Grid,
  LinearProgress
} from '@material-ui/core'

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 25
  },
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  rowTop: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1
  },
  rowBottom: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  pageHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  instrucao: {
    fontSize: 7,
    fontFamily: 'Roboto-Regular',

    position: 'absolute',
    top: 10,
    left: 20,
    right: 25,

    paddingBottom: 15
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    padding: 1,
    textAlign: 'center',
    borderRight: 1,
  },
  cellLeft: {
    padding: 1,
    textAlign: 'left'
  },
  cellSmall: {
    padding: 1,
    fontSize: 6,
    textAlign: 'center',
    height: 12,
  },
  cellSmall2: {
    display: 'flex',
    textAlign: 'center',
    paddingLeft: 1,
    paddingRight: 1,
    marginLeft: 1,
    marginRight: 1
  },
  cellSpace: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    paddingLeft: 1,
    paddingRight: 1,
    marginLeft: 1,
    marginRight: 1,
  },
  ordemProducaoPage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ordemProducaoHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',

    fontFamily: 'Roboto-Bold',
    fontSize: 10,

    border: 1,
    padding: 5
  },
});

const OrdemDeProducaoPage = () => {
  return (
    <Page orientation="landscape" size="A4" style={styles.page} wrap={true}>
      <View style={[styles.ordemProducaoPage, { justifyContent: 'space-between' }]}>
        {Array.from({ length: 3 }).map((_, index) => (
          <View style={{ width: '32%' }} key={index}>

            {/* Page Header */}
            <View style={styles.ordemProducaoHeader}>
              <Image
                style={{ width: 50 }}
                src={require('../../img/logo_vermelho.png')}
              />
              <View style={{ alignItems: 'center' }}>
                <Text>OP - ORDEM DE PRODUÇÃO</Text>
                <Text>QUIERO CAFÉ</Text>
              </View>
            </View>

            {/* Table Header */}
            <View>
              <View style={[styles.header, { width: '65%', border: 0 }]}>
                <Text></Text>
              </View>
              <View style={[styles.header, { width: '25%', border: 0 }]}>
                <Text></Text>
              </View>
              <View style={[styles.header, { width: '10%', border: 0 }]}>
                <Text></Text>
              </View>

              <View style={[styles.ordemProducaoPage, { justifyContent: 'space-around', fontSize: 9 }]}>
                <Text>DATA:</Text>
                <Text></Text>
              </View>
            </View>

            <View style={[styles.row]}>
              <View style={[styles.header, { width: '65%' }]}>
                <Text>INSUMO A PRODUZIR</Text>
              </View>
              <View style={[styles.header, { width: '25%' }]}>
                <Text>QUANTIDADE</Text>
              </View>
              <View style={[styles.header, { width: '10%' }]}>
                <Text>OK</Text>
              </View>
            </View>

            {/* Table Rows */}
            {Array.from({ length: 25 }).map((_, i) => (
              <View style={[styles.row, { height: 17 }]} key={i}>
                <View style={{ width: '65%', borderRight: 1 }}>
                  <Text></Text>
                </View>
                <View style={{ width: '25%', borderRight: 1 }}>
                  <Text></Text>
                </View>
                <View style={{ width: '10%', borderRight: 0 }}>
                  <Text></Text>
                </View>
              </View>
            ))}
          </View>
        ))}
      </View>
    </Page>
  )
}

const RelatorioChecklistOperacao = (props) => {

  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;

  const [load, setLoad] = useState(false);
  const [groupPrint, setGroupPrint] = useState(false);

  const [headerHeight, setHeaderHeight] = useState(0);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { checklistOperacaoItem, isLoading } = useSelector(
    (state) => state.checklistOperacaoItem
  )

  const [dias, setDias] = useState([]);

  useEffect(() => {
    console.log('headerHeight', headerHeight)
  }, [headerHeight])

  useEffect(() => {

    dispatch(checklistOperacaoItemAction.clear());
    dispatch(respostaChecklistOperacaoAction.clear());

    dispatch(checklistOperacaoItemAction.isLoading());

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
      history.push('/checklistsOperacoesCards/categorias');
    }

    const checklistsIDs = params.id.split(',').map(Number)

    setGroupPrint(checklistsIDs.length > 1)

    let payload = {
      IdsChecklistsOperacao: checklistsIDs,
      IdLoja: idLoja
    };

    dispatch(relatorioAction.getRelatorioChecklistOperacao(payload));

    let payload1 = {
      IdsChecklistOperacao: checklistsIDs,
      IdLoja: idLoja,
      DataInicial: moment(params.dataInicio).format('YYYY-MM-DD'),
      DataFinal: moment(params.dataFim).format('YYYY-MM-DD')
    };
    dispatch(checklistOperacaoItemAction.getChecklistsOperacaoItemSemana(payload1, true))

    let payload2 = {
      IdsChecklistOperacao: checklistsIDs,
      IdLoja: idLoja,
      DataInicial: moment(params.dataInicio).format('YYYY-MM-DD'),
      DataFinal: moment(params.dataFim).format('YYYY-MM-DD')
    };
    dispatch(respostaChecklistOperacaoAction.getRespostasChecklistOperacaoBySemana(payload2, true))

    var startOfWeek = moment(params.dataInicio, 'YYYY-MM-DD');
    var endOfWeek = moment(params.dataFim, 'YYYY-MM-DD');

    var day = startOfWeek;
    var days = [];

    while (day <= endOfWeek) {
      days.push(moment(day).format('YYYY-MM-DD'));
      day = moment(day).add('days', 1)
    }

    setDias(days);

    setLoad(true);
  }, []);

  useEffect(() => {

    const checklistsIDs = params.id.split(',').map(Number);

    //alert(isLoading);
    if (!isLoading) {
      setTimeout(() => {
        setReady(true);
      }, (5000 + (6000 * checklistsIDs.length)));
    }
  }, [isLoading]);

  const onRendered = () => {
    //alerts.clean();
  };

  const getChecklistOperacaoName = (index) => {
    if (checklistOperacaoItem[index] && checklistOperacaoItem[index].nome) {
      return checklistOperacaoItem[index].nome
    }

    return ''
  }

  const getChecklistOperacaoInstrucao = (index) => {
    if (checklistOperacaoItem[index] && checklistOperacaoItem[index].instrucao) {
      return checklistOperacaoItem[index].instrucao
    }

    return ''
  }

  const getEstoque = (estoque, unidadeProducao) => {
    let value = ''

    if (estoque != null && unidadeProducao != null) {
      if (unidadeProducao.arredondado) {
        value = Math.round(estoque).toLocaleString('pt-br')
      } else {
        value = estoque.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      }

      return value + ' ' + unidadeProducao.abreviacao
    }

    return value
  }

  const getCalculoDias = (estoque, coeficiente, diasVencimento) => {
    if (estoque != null && coeficiente != null && diasVencimento != null) {
      let result = Number((coeficiente * (diasVencimento + 1)).toFixed(1))

      if (result % 1 === 0) {
        result = Math.floor(result)
      }

      return result.toLocaleString('pt-br') + " dia(s)"
    }

    return ''
  }

  function getContagem(index, codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.respostasChecklistMap[index] && props.respostaChecklistOperacao.respostaChecklistOperacao.respostasChecklistMap[index].length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.respostasChecklistMap[index].find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {
        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.find(p => p.idChecklistOperacaoItem == codigo);
          if (item && item.contagem) {
            return item.contagem;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  function getProducao(index, codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.respostasChecklistMap[index] && props.respostaChecklistOperacao.respostaChecklistOperacao.respostasChecklistMap[index].length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.respostasChecklistMap[index].find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {
        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.find(p => p.idChecklistOperacaoItem == codigo);;
          if (item && item.producao) {
            return item.producao;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  return (

    (
      (ready && !isLoading && props.relatorio != null && props.relatorio.relatorio != null) ?
        <PDFViewer height="100%" width="100%" >
          <Document onRender={onRendered}>
            {props.relatorio.relatorio.map((c, i) => {

              return (
                <Page orientation="landscape" size="A4" style={styles.page} wrap={true}>

                  <Header
                    infoTop={getChecklistOperacaoInstrucao(i) ? 'INSTRUÇÕES: ' + getChecklistOperacaoInstrucao(i) : null}
                    title={getChecklistOperacaoName(i)}
                    setHeaderHeight={setHeaderHeight}
                    fixed
                  />

                  <View fixed key={i} style={{ marginTop: headerHeight }}>
                    <View style={styles.row} >
                      <View style={[styles.header, { width: 95, border: 0 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.header, { width: 40, border: 0 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.header, { width: 55, border: 0 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.header, { width: 55, border: 0 }]}>
                        <Text></Text>
                      </View>
                      {
                        (
                          undefined !== dias && dias.length ?
                            dias.map((dia) =>
                              <View style={[styles.header, { width: 79.4 }]}>
                                <Text>{moment(dia).format('DD/MM/YYYY')}</Text>
                              </View>
                            )
                            :
                            null
                        )
                      }
                    </View>

                    <View style={[styles.row, { marginTop: 0 }]} key={i} >
                      <View style={[styles.header, { width: 95 }]}>
                        <Text>Item</Text>
                      </View>
                      <View style={[styles.header, { width: 40 }]}>
                        <Text>Validade (dias)</Text>
                      </View>
                      <View style={[styles.header, { width: 55 }]}>
                        <Text>Estoque mínimo</Text>
                      </View>
                      <View style={[styles.header, { width: 55 }]}>
                        <Text>Estoque máximo</Text>
                      </View>

                      {
                        (
                          undefined !== dias && dias.length ?
                            dias.map((dia, i) =>
                              <>
                                <View>
                                  <View style={{ flexDirection: 'row' }}>
                                    <View style={[styles.cellSmall, { width: 38, fontFamily: 'Roboto-Bold', marginHorizontal: 1 }]}>
                                      <Text>Contagem</Text>
                                    </View>
                                    <View style={[styles.cellSmall, { width: 38, fontFamily: 'Roboto-Bold', marginHorizontal: 1 }]}>
                                      <Text>Quantidade</Text>
                                    </View>
                                  </View>
                                  <View>
                                    <View style={[styles.cellSmall, { width: 76, fontFamily: 'Roboto-Bold', marginHorizontal: 1 }]}>
                                      <Text>
                                        {
                                          (
                                            i == 0 ? 'Seg passada' :
                                              i == 1 ? 'Ter passada' :
                                                i == 2 ? 'Qua passada' :
                                                  i == 3 ? 'Qui passada' :
                                                    i == 4 ? 'Sex passada' :
                                                      i == 5 ? 'Sáb passado' :
                                                        i == 6 ? 'Dom passado' :
                                                          ''
                                          )
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </>
                            )
                            :
                            null
                        )
                      }
                    </View>
                  </View>

                  {
                    (idLoja !== '' && idLoja !== undefined && checklistOperacaoItem[i] !== undefined && checklistOperacaoItem[i].checklistsOperacaoItens !== undefined && checklistOperacaoItem[i].checklistsOperacaoItens.length > 0 && undefined !== dias && dias.length) ?
                      checklistOperacaoItem[i].checklistsOperacaoItens.map(n => {
                        return (
                          <View wrap={false}>
                            <View style={styles.rowTop} key={n.id}>
                              <View style={[styles.cell, { width: 95 }]}>
                                <Text>{n.descricao}</Text>
                              </View>
                              {
                                n.totvsInsumo?.contabilizado ?
                                  <>
                                    <View style={[styles.cell, { width: 40 }]}>
                                      <Text>{(n.diasVencimento != null ? n.diasVencimento : '')}</Text>
                                    </View>
                                    <View style={[styles.cell, { width: 55 }]}>
                                      <Text>{getEstoque(n.estoqueMinimo, n.unidadeProducao)}</Text>
                                    </View>
                                    <View style={[styles.cell, { width: 55 }]}>
                                      <Text>{getEstoque(n.estoqueMaximo, n.unidadeProducao)}</Text>
                                    </View>
                                  </>
                                  :
                                  <View style={[styles.cell, { width: 150 }]}>
                                    <Text>NÃO CONTABILIZADO</Text>
                                  </View>
                              }

                              {
                                dias.map((dia, i) =>
                                  <>
                                    <View style={[styles.cellSpace, { borderRight: dias.length - 1 != i ? 1 : 0 }]}>
                                      <View style={[styles.cellSmall, { width: 35, borderBottom: 1, marginHorizontal: 1 }]}>
                                        <Text>{getContagem(n.idChecklistOperacao, n.id, dia)}</Text>
                                      </View>
                                      <View style={[styles.cellSmall, { width: 35, borderBottom: 1, marginHorizontal: 1 }]}>
                                        <Text>{getProducao(n.idChecklistOperacao, n.id, dia)}</Text>
                                      </View>
                                    </View>
                                  </>
                                )
                              }

                            </View>

                            <View style={styles.rowBottom} key={n.idChecklistOperacao + '_' + n.id}>
                              <View style={[styles.cell, { width: 95 }]}>
                                <Text></Text>
                              </View>
                              {
                                n.totvsInsumo?.contabilizado ?
                                  <>
                                    <View style={[styles.cell, { width: 40 }]}>
                                      <Text></Text>
                                    </View>
                                    <View style={[styles.cell, { width: 55 }]}>
                                      <Text>{getCalculoDias(n.estoqueMinimo, n.coeficienteEstoqueMinimo, n.diasVencimento)}</Text>
                                    </View>
                                    <View style={[styles.cell, { width: 55 }]}>
                                      <Text>{getCalculoDias(n.estoqueMaximo, n.coeficienteEstoqueMaximo, n.diasVencimento)}</Text>
                                    </View>
                                  </>
                                  :
                                  <View style={[styles.cell, { width: 150 }]}>
                                    <Text></Text>
                                  </View>
                              }
                              {
                                (n.vendaUltimaSegunda != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimaSegunda.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }
                              {
                                (n.vendaUltimaTerca != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimaTerca.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }
                              {
                                (n.vendaUltimaQuarta != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimaQuarta.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }
                              {
                                (n.vendaUltimaQuinta != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimaQuinta.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }
                              {
                                (n.vendaUltimaSexta != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimaSexta.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }
                              {
                                (n.vendaUltimoSabado != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimoSabado.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }
                              {
                                (n.vendaUltimoDomingo != null ?
                                  <View style={[styles.cellSmall2, { width: 77, borderRight: 1 }]}>
                                    <Text style={{ fontSize: 7 }} >
                                      {
                                        n.vendaUltimoDomingo.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                      }
                                    </Text>
                                  </View>
                                  : <Text></Text>)
                              }

                            </View>
                          </View>
                        );
                      })
                      : null
                  }

                  <Footer />
                </Page>
              )
            })}

            {groupPrint ?
              Array.from({ length: 3 }).map((_, i) => (
                <OrdemDeProducaoPage />
              ))
              : null}

          </Document>
        </PDFViewer>
        :
        <Grid lg={12} md={12} xl={12} xs={12}>
          <LinearProgress color="secondary" />
        </Grid>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioChecklistPage = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(RelatorioChecklistOperacao));
export { connectedRelatorioChecklistPage as RelatorioChecklistOperacao };
