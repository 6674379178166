const initialState = {
  isLoading: false,
  totalRegistros: 0,
  banner: [],
  id: 0,
  nome: '',
  descricao: '',
  dataHoraInicio: '',
  dataHoraTermino: '',
  caminhoImagem: '',
  caminhoImagemBanner: '',
  acao: '',
  chave: '',
  excluido: false,
  page: 0,
  limit: 10,
  order: 'dataHoraInicio',
  direction: 'desc',
  term: ''
}

export function banner(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_BANNER':
      return {
        ...state,
        banner: action.banner,
        totalRegistros: action.totalRegistros
      }
    case 'BANNER_DETAIL':
      return {
        ...state,
        isLoading: false,
        acao: action.banner.acao,
        caminhoImagem: action.banner.caminhoImagem,
        caminhoImagemBanner: action.banner.caminhoImagemBanner,
        chave: action.banner.chave,
        dataHoraInicio: action.banner.dataHoraInicio,
        dataHoraTermino: action.banner.dataHoraTermino,
        descricao: action.banner.descricao,
        excluido: action.banner.excluido,
        id: action.banner.id,
        nome: action.banner.nome,
      }
    case 'BANNER_UPDATED':
      return state
    case 'BANNER_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'BANNER_CLEAR_ALL':
      return {
        ...state,
        banner: []
      }
    case 'BANNER_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'BANNER_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'BANNER_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
