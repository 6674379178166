import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Avatar,
  FormControlLabel,
  withStyles,
  Paper,
  Grid,
  Button,
  Box,
  Typography,
  CssBaseline,
  Checkbox,
  Link,
  IconButton
} from '@material-ui/core'
import { connect } from 'react-redux'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { authActions, usuarioAction } from '../../_actions'
import { history } from '../../utils'
import { withRouter } from 'react-router-dom'
import packageInfo from '../../../package.json'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import ModalRequestPasswordReset from './ModalRequestPasswordReset'

const styles = (theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(/images/logo_quiero_cafe.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.secondary.dark
        : theme.palette.secondary.light,
    backgroundSize: '40rem',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  facebook: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#3b5998'
  },
  backButtom: {}
})

function Copyright() {
  return (
    <>
      <Typography variant="body2" align="center">
        {'Desenvolvido por © '}
        <Link color="inherit" href="https://fortalezatec.com.br/">
          FortalezaTec
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" align="center">
        Versão do sistema: {packageInfo.version}
      </Typography>
    </>
    
  )
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      senha: '',
      showPassword: false,
      submitted: false
    }
  }

  componentDidMount() {
    if (localStorage.getItem('auth')) {
      history.push('/principal')
    }
  }
  handleChange = (prop) => (event) => {
    if (prop === 'email') {
      this.setState({ [prop]: event.target.value.toLowerCase() })
    } else {
      this.setState({ [prop]: event.target.value })
    }
  }

  handleChangeBox = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { email, senha } = this.state
    const { dispatch } = this.props
    if (email && senha) {
      dispatch(authActions.login(email, senha))
    }
  }

  responseFacebook = (response) => {
    const { dispatch } = this.props
    if (response.accessToken) {
      dispatch(authActions.facebookLogin(response.accessToken, true))
    }
  }
  handleClickopenModalRequestPasswordReset = () => {
    const { dispatch } = this.props
    dispatch(usuarioAction.openModalRequestPasswordReset(true))
  }
  render() {
    const { loggingIn } = this.props
    const { classes } = this.props
    return (
      <Grid container className={classes.root}>
        <ModalRequestPasswordReset currentEmail={this.state.email}/>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Grid container justifyContent="flex-start">
              <IconButton
                className={classes.backButton}
                onClick={() => history.goBack()}
                aria-label="Voltar"
                color="primary"
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <ValidatorForm
              className={classes.form}
              ref="form"
              onSubmit={this.handleSubmit}
            >
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="E-mail *"
                value={this.state.email}
                className={classes.textField}
                onChange={this.handleChange('email')}
                id="email"
                name="email"
                autoComplete="email"
                validators={['required', 'isEmail']}
                errorMessages={[
                  'Você precisa informar o e-mail',
                  'E-mail inválido'
                ]}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                value={this.state.senha}
                onChange={this.handleChange('senha')}
                name="senha"
                label="Senha *"
                type={this.state.showPassword ? 'text' : 'password'}
                id="senha"
                autoComplete="current-senha"
                validators={['required']}
                errorMessages={['Opss! Você esqueceu da senha.']}
              />
              <Grid container justifyContent="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleChangeBox('showPassword')}
                      value="showPassword"
                      color="primary"
                    />
                  }
                  label="Mostrar senha"
                />
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loggingIn}
                type="submit"
                className={classes.submit}
                startIcon={<LockOpenIcon />}
                endIcon={loggingIn && <CircularProgress size={24} />}
              >
                {loggingIn ? 'Validando informações... ' : 'Entrar'}
              </Button>

             {/*
              <FacebookLogin
                appId="243512746745330"
                autoLoad={false}
                isMobile={false}
                disableMobileRedirect={true}
                fields="name,email,picture"
                callback={this.responseFacebook}
                render={(renderProps) => (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loggingIn}
                    onClick={renderProps.onClick}
                    className={classes.facebook}
                    startIcon={<FacebookIcon />}
                    endIcon={loggingIn && <CircularProgress size={24} />}
                  >
                    {loggingIn
                      ? 'Validando informações... '
                      : 'Entrar com o Facebook'}
                  </Button>
                )}
                        />
                */}

            </ValidatorForm>
            <Grid container justifyContent="center" style={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Link
                  component="button"
                  onClick={this.handleClickopenModalRequestPasswordReset}
                  variant="body1"
                >
                  {'Esqueceu a senha?'}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </Grid>
      </Grid>
    )
  }
}
Login.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication
  return {
    loggingIn
  }
}
const connectedLoginPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Login))
)
export { connectedLoginPage as Login }
