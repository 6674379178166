import React from 'react';
import ImageUploading from 'react-images-uploading';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import {
  makeStyles,
  Avatar,
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fade,
  Modal,
  Typography,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red"
    }
  },
  img: {
    outline: "none"
  }
}));

const ImageUploader = ({ images, onChangeImage, onError, acceptType, maxFileSize, resolutionWidth, resolutionHeight, resolutionType, removeImage, props, imagem, getInitials, classes, rest, className, isBanner }) => {
  const modalClasses = useStyles();
  const [imagemModal, setImagemModal] = React.useState("false");
  const [openModal, setOpenModal] = React.useState(false);

  function bytesToMB(bytes) {
    return bytes / (1024 * 1024);
  }

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleImage = () => {
    setImagemModal(imagem);
    setOpenModal(true);
  };

  return (
    <>
      <ImageUploading
        maxFileSize={maxFileSize}
        onError={onError}
        acceptType={acceptType}
        value={images}
        onChange={onChangeImage}
        dataURLKey="data_url"
        resolutionWidth={resolutionWidth}
        resolutionHeight={resolutionHeight}
        resolutionType={resolutionType}
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <Card {...rest} className={classes.root} style={{ marginBottom: 10 }}>
            <CardContent {...dragProps} className={classes.content}>
              {isDragging ? (
                <>
                  <CloudUploadTwoToneIcon
                    color="primary"
                    style={{ fontSize: 80 }}
                  />
                  <Typography variant="h3" color="initial">
                    Solte a imagem aqui!
                  </Typography>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      props != null &&
                        imagem != null &&
                        imagem != ''
                        ? 'Clique para visualizar a imagem!'
                        : 'Clique para adicionar uma imagem!'
                    }
                  >
                    <Avatar
                      key={(imagem != '' && imagem != null ? imagem.substr(imagem.lastIndexOf('/'), imagem.length - imagem.lastIndexOf('/')) : '')}
                      onClick={imagem ? handleImage : onImageUpload}
                      variant="rounded"
                      className={classes.avatar}
                      //remover cache = new Date().getTime()} 
                      src={imagem}
                    >
                      {props.nome != null ? getInitials(props.nome) : getInitials(props.titulo)}
                    </Avatar>
                  </Tooltip>
                  <Typography
                    align="center"
                    className={classes.nome}
                    gutterBottom
                    variant="h3"
                  >
                    {/*{produto.nome}*/}
                  </Typography>
                </>
              )}
            </CardContent>
            <Divider />
            <CardActions className={classes.uploadButton}>
              <Button color="primary" variant="text" onClick={onImageUpload}>
                {!isBanner ? props && imagem ? 'Trocar imagem' : 'Carregar imagem' :
                  props && imagem ? 'Trocar imagem (banner)' : 'Carregar imagem (banner)'}
              </Button>
              {props && imagem && (
                <Button variant="text" onClick={removeImage}>
                  {!isBanner ? 'Remover imagem' : 'Remover imagem (banner)'}
                </Button>
              )}
            </CardActions>
          </Card>
        )}
      </ImageUploading>

      <Modal
        className={modalClasses.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal} timeout={500} className={modalClasses.img}>
          <img
            src={imagemModal}
            alt="imagem"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
      </Modal>

      <Typography variant="subtitle2" color="#BDBDBD">
        Tamanho máximo da imagem para upload: {bytesToMB(maxFileSize).toFixed(2)} MB.
      </Typography>
      <Typography variant="subtitle2" color="#BDBDBD">
        Resolução máxima: {resolutionWidth}x{resolutionHeight}px.
      </Typography>
    </>
  );
}

export default ImageUploader;
