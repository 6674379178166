import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import {
  Grid,
  CircularProgress,
  Table,
  TableContainer,
  TableRow,
  Input,
  Button
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { parametroAction, totvsAction, totvsInsumoAction } from '../../_actions'
import CloseIcon from '@material-ui/icons/Close';
import ImportIcon from '@material-ui/icons/ImportExport';
import { StickyContainer, Sticky } from 'react-sticky';
import { history } from '../../utils'
import { AccessControl } from '../../components/Utils/AccessControl'
import moment from 'moment'

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const InsumoModal = ({ modal, toggle, props }) => {

  const dispatch = useDispatch()
  const [file, setFile] = useState(undefined)

  const parametro = useSelector(
    (state) => state.parametro
  )

  const isLoadingInsumoModal = useSelector(
    (state) => state.totvsInsumo.isLoading
  )

  useEffect(() => {

    dispatch(totvsInsumoAction.clear())
    dispatch(totvsInsumoAction.clearAll())
    dispatch(parametroAction.getParametroByChave('ultimaFichaTecnicaImportada'));

  }, [])

  useEffect(() => {
    console.log('parametro', parametro)
  }, [parametro]);

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  const handleSubmitFile = (e) => {

    if (file != undefined) {

      const formData = new FormData()
      formData.append('file', file)

      dispatch(totvsAction.importarFichaTecnica(formData));

      toggle();
      history.push("/insumos");
    } else {
      toast.error('Selecione um arquivo para upload!');
    }
  }

  return (
    <div>
      <Dialog
        open={modal}
        fullWidth
        maxWidth={false}
        onClose={toggle}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
                IMPORTAR FICHA TÉCNICA
              </Typography>
            </Grid>
            <AccessControl
              rule={'totvsInsumos.create'} //permissão necessária para acessar conteúdo
              yes={() => (
                <Grid item>
                  <Grid item xs={6} align="right">
                    <Button
                      color="default"
                      variant="contained"
                      endIcon={<CloseIcon />}
                      onClick={toggle}
                    >
                      Fechar
                    </Button>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </DialogTitle>
        <DialogContent sx={styles}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <TableContainer>
                <Table id="tableResumo">
                  <TableRow>
                    <Typography>Arquivo *</Typography>
                    <Input
                      id="arquivo"
                      label="Arquivo"
                      onChange={e => handleChangeFile(e)}
                      type="file"
                      accept=".xls,.xlsx"
                      formControl
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      style={{ width: '40%' }}
                    />
                  </TableRow>
                  <br />
                  <DialogActions style={{
                    justifyContent: 'space-between',
                    marginLeft: 0,
                    paddingLeft: 0,
                    marginRight: 0,
                    paddingRight: 0
                  }}>
                    {(parametro != null ?
                      <Typography>Última importação: <b>{parametro.valor}</b></Typography>
                      :
                      ''
                    )}
                    <StickyContainer>
                      <Sticky relative={false} isSticky={true}>
                        {({ style }) => (
                          <Grid>
                            <AccessControl
                              rule={'totvsInsumos.create'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <Grid item lg={12} md={12} xl={12} xs={12} style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  paddingRight: 0,
                                  marginRight: 0
                                }}>
                                  <Button
                                    color="default"
                                    onClick={toggle}
                                    variant="contained"
                                    style={{
                                      justifyContent: 'right'
                                    }}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    style={{
                                      justifyContent: 'right',
                                      marginRight: 0
                                    }}
                                    disabled={isLoadingInsumoModal}
                                    startIcon={<ImportIcon />}
                                    endIcon={isLoadingInsumoModal && <CircularProgress size={24} />}
                                    onClick={(e) => handleSubmitFile(e)}
                                  >
                                    {isLoadingInsumoModal ? 'Importando arquivo...' : 'Importar arquivo'}
                                  </Button>
                                </Grid>
                              )}
                            />
                          </Grid>
                        )}
                      </Sticky>
                    </StickyContainer>
                  </DialogActions>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
