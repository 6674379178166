const initialState = {
  isLoading: false,
  parametro: [],
  id: '',
  descricao: '',
  chave: '',
  valor: '',
  tipo: '',
  totalRegistros: 0,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
};

export function parametro(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PARAMETRO':
      return {
        ...state,
        parametro: action.parametro,
        totalRegistros: action.totalRegistros
      }
    case 'PARAMETRO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.parametro.id,
        descricao: action.parametro.descricao,
        chave: action.parametro.chave,
        valor: action.parametro.valor,
        tipo: action.parametro.tipo
      }
    case 'PARAMETRO_UPDATED':
      return state
    case 'PARAMETRO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'PARAMETRO_CLEAR_ALL':
      return {
        ...state,
        parametro: []
      }
    case 'PARAMETRO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'PARAMETRO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'PARAMETRO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
