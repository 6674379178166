export const options = {
  interaction: {
    mode: 'dataset'
  },
  responsive: true,
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat((currentValue / total * 100).toFixed(2));
        return data.labels[tooltipItem.index] + ' ' + percentage + ' %';
      },
    }
  }
}
