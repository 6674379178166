import { crudService } from '../_services'

export const totvsVendaAction = {
  validarNota,
  clear,
  clearAll
}


function validarNota(payload) {
  return (dispatch) => {
    let apiEndpoint = 'totvs/validarNota/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        dispatch(changeTotvsVendasList(response.data))
      }).catch((err) => {
       // dispatch(clear())
        return err.response;
      })
  }
}

export function clear() {
  return {
    type: 'TOTVS_VENDA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'TOTVS_VENDA_CLEAR_ALL'
  }
}

export function editTotvsVendasDetails(totvsVenda) {
  return {
    type: 'TOTVS_VENDA_DETAIL',
    id: totvsVenda.id,
    isLoading: totvsVenda.isLoading,
    data: totvsVenda.data,
    chaveNota: totvsVenda.chaveNota,
    dataImportacao: totvsVenda.dataImportacao,
    valorTotal: totvsVenda.valorTotal,
    valorFidelidade: totvsVenda.valorFidelidade,
    idLoja: totvsVenda.idLoja,
    loja: totvsVenda.loja
  }
}

export function changeTotvsVendasList(totvsVenda) {
  return {
    type: 'FETCHED_ALL_TOTVS_VENDA',
    totvsVenda: totvsVenda
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'TOTVS_VENDA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function createTotvsVendasInfo() {
  return {
    type: 'TOTVS_VENDA_CREATED_SUCCESSFULLY'
  }
}

export function isLoading() {
  return {
    type: 'TOTVS_VENDA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TOTVS_VENDA_NOTISLOADING'
  }
}

