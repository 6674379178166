import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, totvsInsumoUnidadeAction } from '../../_actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Card,
  CardContent,
  CircularProgress,
  Button,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print'
import { Page } from 'components'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  gridButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    textAlign: 'right'
  }
})

const RelatorioNivelServico = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [dataInicial, setDataInicial] = React.useState(undefined)
  const [dataFinal, setDataFinal] = React.useState(undefined)
  const [codigoProduto, setCodigoProduto] = React.useState(0)

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { produto } = useSelector(
    (state) => state.produto
  )

  const options = produto.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {
    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();

    setDataInicial(moment(dataIni).format("YYYY-MM-DD"));
    setDataFinal(moment(dataFi).format("YYYY-MM-DD"));

    dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeSelect());

    dispatch(relatorioAction.clear());
  }, []);

  useEffect(() => {

  }, [idLoja]);

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selectedProduto = produto.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        totvsInsumoUnidadeAction.editTotvsInsumoUnidadesDetails(
          selectedProduto[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleSubmit = (event) => {

    if (dataInicial != undefined && dataFinal != undefined) {

      if (dataFinal > dataInicial) {
        let payload = {
          IdLoja: idLoja ? idLoja : 0,
          DataInicial: moment(dataInicial).format("YYYY-MM-DD"),
          DataFinal: moment(dataFinal).format("YYYY-MM-DD"),
          CodigoProduto: value ? value.id : 0
        };

        dispatch(relatorioAction.getRelatorioNivelServico(payload, true));
      }
      else {
        toast.error('Data final deve ser maior que a Data inicial!')
      }
    } else {
      dispatch(relatorioAction.clear());
    }
  };

  const handleChangeDataInicial = (e) => {
    setDataInicial(e.target.value)
  }

  const handleChangeDataFinal = (e) => {
    setDataFinal(e.target.value)
  }

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [props.relatorio.isLoading])

  const handlePrint = () => {
    window.open('/relatorioNivelServico', '_blank');
  }

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  return (
    <Page className={classes.root} title="Relatório - Nível de Serviço">
      <AccessControl
        rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioNivelServicos"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Nível de Serviço'}
                    />
                    <Grid item xl={6} lg={6} md={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            InputLabelProps={{ shrink: true }}
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Início *"
                            type="date"
                            value={dataInicial}
                            onChange={handleChangeDataInicial}
                            id="dataInicial"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            InputLabelProps={{ shrink: true }}
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Final *"
                            type="date"
                            value={dataFinal}
                            onChange={handleChangeDataFinal}
                            id="dataFinal"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                        {/*<Grid item xl={6} lg={6} md={6} xs={12}>
                          <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            defaultValue={null}
                            getOptionLabel={option => option.nome}
                            renderInput={(params) => <TextField {...params} label="SELECIONE UMA UNIDADE DE INSUMO... " variant="outlined" fullWidth />}
                            style={{ width: "312px" }}
                            fullWidth
                          />
                        </Grid>
                        */}
                        <Grid item xl={6} lg={6} md={6} xs={12} style={{
                          alignItems: 'bottom',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'right',
                          textAlign: 'right'
                        }}>
                          <Grid>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              disabled={props.relatorio.isLoading}
                              startIcon={<SearchIcon />}
                              endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                            >
                              {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                            </Button>
                          </Grid>
                          <Grid>
                            <Button
                              color="primary"
                              type="submit"
                              onClick={() => handlePrint()}
                              variant="outlined"
                              startIcon={<PrintIcon />}
                            >
                              Imprimir
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} xs={12}>
                      {(undefined !== relatorio && undefined !== relatorio.itens && relatorio.itens.length ?
                        <Typography style={{ fontSize: "22px", fontWeight: 'bold', textAlign: "right", marginRight: 5 }}>
                          NÍVEL DE SERVIÇO: {(relatorio.mediaNivelServico ? relatorio.mediaNivelServico.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : (0).toLocaleString('pt-br', { minimumFractionDigits: 2 }))}%
                        </Typography>
                        :
                        null
                      )}
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Loja</TableCell>
                        <TableCell>Unidade de Insumo</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell>Quantidade faturada</TableCell>
                        <TableCell>Nível de Serviço</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && undefined !== relatorio.itens && relatorio.itens.length
                        ? relatorio.itens.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {moment(n.data).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.loja || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.descricaoProduto || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.quantidade ? n.quantidade.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.quantidadeFaturada ? n.quantidadeFaturada.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.nivelServico ? n.nivelServico.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : (0).toLocaleString('pt-br', { minimumFractionDigits: 2 })}%
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (dataInicial != undefined && dataFinal != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={5} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={5} colSpan={6} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                          </TableRow>
                        )
                      }
                      {/*{undefined !== relatorio && undefined !== relatorio.itens && relatorio.itens.length*/}
                      {/*  ?*/}
                      {/*  <TableRow>*/}
                      {/*    <TableCell rowSpan={3} colSpan={4}><b>Total</b></TableCell>*/}
                      {/*    <TableCell rowSpan={3}><b>{getTotal()}</b></TableCell>*/}
                      {/*  </TableRow>*/}
                      {/*  :*/}
                      {/*  null*/}
                      {/*}*/}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RelatorioNivelServico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedRelatorioNivelServicoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioNivelServico))
)
export { connectedRelatorioNivelServicoPage as RelatorioNivelServico }
