import { crudService } from '../_services'
import { toast } from 'react-toastify'
import { totvsInsumoAction } from '.'

export const totvsInsumoPrecoReferenciaAction = {
  getTotvsInsumoPrecoReferenciaById,
  createTotvsInsumoPrecoReferencia,
  editTotvsInsumoPrecoReferenciaInfo,
  editTotvsInsumoPrecoReferenciaInfoByRelatorio,
  deleteTotvsInsumoPrecoReferenciaById,
  onChangeProps,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getTotvsInsumoPrecoReferenciaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosPrecosReferencias/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTotvsInsumoPrecosReferenciasDetails(response.data))
    })
  }
}

function createTotvsInsumoPrecoReferencia(payload, idCompra) {
  return (dispatch) => {
    dispatch(isLoading())
    const idToast = toast.loading("Atualizando preços...")
    let apiEndpoint = 'totvsInsumosPrecosReferencias/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.dismiss(idToast);
          dispatch(createTotvsInsumoPrecoReferenciaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(totvsInsumoAction.getTotvsInsumoById(payload.idTotvsInsumo))
          dispatch(notIsLoading())
        }
      }).catch((err) => {
        toast.dismiss(idToast);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar preço! 😥')
        return err.response;
      })
  }
}

function editTotvsInsumoPrecoReferenciaInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    const idToast = toast.loading("Atualizando preços...")
    let apiEndpoint = 'totvsInsumosPrecosReferencias/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.dismiss(idToast);
          dispatch(updatedTotvsInsumoPrecoReferenciaInfo())

          dispatch(totvsInsumoAction.getTotvsInsumoById(payload.idTotvsInsumo))
          dispatch(notIsLoading())
        }
      }).catch((err) => {
        toast.dismiss(idToast);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar preço! 😥')
        return err.response;
      })
  }
}

function editTotvsInsumoPrecoReferenciaInfoByRelatorio(relatorio) {
  return (dispatch) => {
    const idToast = toast.loading("Atualizando preços...")

    let apiEndpoint = 'totvsInsumosPrecosReferencias/relatorio'

    crudService.put(apiEndpoint, relatorio)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.dismiss(idToast);
          dispatch(updatedTotvsInsumoPrecoReferenciaInfo())

          //dispatch(totvsInsumoAction.getTotvsInsumoById(payload.idTotvsInsumo))
          dispatch(notIsLoading())
        }
      }).catch((err) => {
        toast.dismiss(idToast);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar preços! 😥')
        return err.response;
      })
  }
}

function deleteTotvsInsumoPrecoReferenciaById(id, idTotvsInsumo) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumosPrecosReferencias/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTotvsInsumoPrecosReferenciasDetails())
      dispatch(totvsInsumoAction.getTotvsInsumoById(idTotvsInsumo))
      dispatch(notIsLoading())
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

export function addTotvsInsumoPrecoReferencia(payload) {
  return [{ type: 'ADD_TOTVS_INSUMO_PRECO_REFERENCIA', totvsInsumoPrecoReferencia: payload }, clear()]
}

export function changeTotvsInsumoPrecosReferenciasList(totvsInsumoPrecoReferencia, totalRows) {
  return {
    type: 'FETCHED_ALL_TOTVS_INSUMO_PRECO_REFERENCIA',
    totvsInsumoPrecoReferencia: totvsInsumoPrecoReferencia,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTotvsInsumoPrecosReferenciasDetails(totvsInsumoPrecoReferencia) {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_DETAIL',
    id: totvsInsumoPrecoReferencia.id,
    idTotvsInsumo: totvsInsumoPrecoReferencia.idTotvsInsumo,
    idTotvsInsumoUnidade: totvsInsumoPrecoReferencia.idTotvsInsumoUnidade,
    idEstado: totvsInsumoPrecoReferencia.idEstado,
    dataHoraAtualizacao: totvsInsumoPrecoReferencia.dataHoraAtualizacao,
    preco: totvsInsumoPrecoReferencia.preco,
    excluido: totvsInsumoPrecoReferencia.excluido,
  }
}

export function updatedTotvsInsumoPrecoReferenciaInfo() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_UPDATED'
  }
}

export function createTotvsInsumoPrecoReferenciaInfo() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_CREATED_SUCCESSFULLY'
  }
}

export function deleteTotvsInsumoPrecosReferenciasDetails() {
  return {
    type: 'DELETED_TOTVS_INSUMO_PRECO_REFERENCIA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_NOTISLOADING'
  }
}

export function isModalOpen() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_ISMODALOPEN'
  }
}

export function notIsModalOpen() {
  return {
    type: 'TOTVS_INSUMO_PRECO_REFERENCIA_NOTISMODALOPEN'
  }
}
