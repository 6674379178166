import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { withStyles } from '@material-ui/core/styles'
import { usuarioAction } from '../../_actions'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { history } from 'utils'
import { CircularProgress } from '@material-ui/core'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    color: '#ffffff'
  },
  backButton: {
    marginTop: theme.spacing(1)
  }
})

class PasswordResetSuccess extends Component {

  handleOpenAndroid = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=br.com.forcontrol.quierocafe&hl=pt';
    return null;
  }

  handleOpeniOS = () => {
    window.location.href = 'https://apps.apple.com/us/app/quiero-fidelidade/id1582879059';
    return null;
  }

  handleOpenWeb = () => {
    history.push('/');
  }

  render() {
    const { loading } = this.props.usuario
    const { classes } = this.props

    return (
      <Container component={Paper} maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container justifyContent="flex-start">
            {/*<IconButton*/}
            {/*  className={classes.backButton}*/}
            {/*  onClick={() => history.goBack()}*/}
            {/*  aria-label="Voltar"*/}
            {/*  color="primary"*/}
            {/*>*/}
            {/*  <KeyboardBackspaceIcon />*/}
            {/*</IconButton>*/}
          </Grid>
          <Typography component="h2" variant="h6" style={{ marginTop: 20 }}>
            Senha redefinida com sucesso. Escolha a forma de login.
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.submit}
                endIcon={loading && <CircularProgress size={24} />}
                onClick={this.handleOpenAndroid}
              >
                {loading ? 'Carregando...' : 'Login Android'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.submit}
                endIcon={loading && <CircularProgress size={24} />}
                onClick={this.handleOpeniOS}
              >
                {loading ? 'Carregando...' : 'Login iOS'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.submit}
                endIcon={loading && <CircularProgress size={24} />}
                onClick={this.handleOpenWeb}
              >
                {loading ? 'Carregando...' : 'Login Franqueado (Web)'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    )
  }
}
PasswordResetSuccess.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return state
}
const connectedPasswordResetSuccessPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(PasswordResetSuccess))
)
export { connectedPasswordResetSuccessPage as PasswordResetSuccess }
