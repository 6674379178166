import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import moment from 'moment'

export const checklistOperacaoItemAction = {
  getChecklistOperacaoItem,
  getChecklistOperacaoItemSemana,
  getChecklistsOperacaoItemSemana,
  addChecklistOperacaoItem,
  getChecklistOperacaoItemById,
  getItensByChecklistOperacao,
  onChangeProps,
  editChecklistOperacaoItemInfo,
  editChecklistOperacoesItensDetails,
  createChecklistOperacaoItem,
  deleteChecklistOperacaoItemById,
  clear,
  clearAll,
  clearChecklistOperacoesItensLojas,
  clearChecklistOperacoesItens,
  isLoading
}

function getChecklistOperacaoItem(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading());
    let apiEndpoint = 'checklistsOperacoesItens' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading());
        dispatch(changeChecklistOperacoesItensList(response.data, null))
      })
      .catch((err) => {
        dispatch(notIsLoading());
        console.log(err.response);
        return err.response;
      })
  }
}

function getChecklistOperacaoItemSemana(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading());
    let apiEndpoint = 'checklistsOperacoesItens/semana' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading());
        dispatch(changeChecklistOperacoesItensList(response.data, null))
      })
      .catch((err) => {
        dispatch(notIsLoading());
        console.log(err.response);
        return err.response;
      })
  }
}

function getChecklistsOperacaoItemSemana(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    toast.info('Aguarde, pode levar um tempo... \n Tempo estimado: ' + moment.utc((5 + 16 * payload.IdsChecklistOperacao.length * 1000)).format('HH:mm:ss') + ' ⏰');
    let apiEndpoint = 'checklistsOperacoesItens/semana/ids';
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        let list = []

        if (response.data) {
          const checklistsMap = response.data.checklistsMap
          const checklistsOperacaoMap = response.data.checklistsOperacaoMap
          const datasMap = response.data.datasMap

          for (const key in checklistsMap) {
            if (checklistsMap.hasOwnProperty(key) && checklistsOperacaoMap.hasOwnProperty(key) && datasMap.hasOwnProperty(key)) {
              let checklistObj = {
                checklistsOperacaoItens: checklistsMap[key],
                tipo: checklistsOperacaoMap[key].tipo,
                nome: checklistsOperacaoMap[key].nome,
                instrucao: checklistsOperacaoMap[key].instrucao,
                dataUltimaVendaImportada: datasMap[key],
              };

              list.push(checklistObj)
            }
          }
          dispatch(notIsLoading())
          dispatch(changeChecklistOperacoesItensList(list, null))
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err.response);
        return err.response;
      })
  }
}

function createChecklistOperacaoItem(payload, filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklistsOperacoesItens/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createChecklistOperacaoItemInfo())
          toast.success('Feito! Item adicionado com sucesso! 😎')
          dispatch(notIsLoading())
          dispatch(clear())
          dispatch(getChecklistOperacaoItem(filterModel))

        } else {
          toast.err('Oops! Erro ao cadastrar item no checklist de operação! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar item no checklist de operação! 😥')
        return err.response;
      })
  }
}

function getChecklistOperacaoItemById(id) {   //pega os itens do checklist de operação
  return (dispatch) => {
    let apiEndpoint = 'checklistsOperacoesItens/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editChecklistOperacoesItensDetails(response.data))
    })
  }
}

function getItensByChecklistOperacao(id) {
  return (dispatch) => {
    let apiEndpoint = 'checklistsOperacoesItens/operacao' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editChecklistOperacoesItensDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'checklistOperacaoItem') {
      dispatch(handleOnChangeProps('checklistOperacaoItem.checklistsOperacaoItens', event))
    } else {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function editChecklistOperacaoItemInfo(id, payload, filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklistsOperacoesItens/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedChecklistOperacaoItemInfo())
          dispatch(clear())
          dispatch(notIsLoading())
          dispatch(getChecklistOperacaoItem(filterModel))
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar checklist de operação! 😥')
        return err.response;
      })
  }
}

function deleteChecklistOperacaoItemById(id, filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklistsOperacoesItens/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteChecklistOperacoesItensDetails())
      dispatch(getChecklistOperacaoItem(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addChecklistOperacaoItem(payload, props) {
  return [{ type: 'ADD_CHECKLIST_OPERACAO_ITEM', checklistOperacaoItem: payload },
  clear()
  ]
}

export function changeChecklistOperacoesItensList(checklistOperacaoItem, totalRows) {
  return {
    type: 'FETCHED_ALL_CHECKLIST_OPERACAO_ITEM',
    checklistOperacaoItem: checklistOperacaoItem,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_CLEAR_ALL'
  }
}

export function clearChecklistOperacoesItensLojas() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_CLEAR_LOJAS'
  }
}

export function clearChecklistOperacoesItens() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_CLEAR_ITENS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editChecklistOperacoesItensDetails(checklistOperacaoItem) {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_DETAIL',
    id: checklistOperacaoItem.id,
    descricao: checklistOperacaoItem.descricao,
    idChecklistOperacao: checklistOperacaoItem.idChecklistOperacao,
    idTotvsInsumo: checklistOperacaoItem.idTotvsInsumo,
    ordem: checklistOperacaoItem.ordem,
    diasVencimento: checklistOperacaoItem.diasVencimento,
    coeficienteEstoqueMinimo: checklistOperacaoItem.coeficienteEstoqueMinimo,
    coeficienteEstoqueMaximo: checklistOperacaoItem.coeficienteEstoqueMaximo,
    idUnidadeProducao: checklistOperacaoItem.idUnidadeProducao,
    pesoLiquido: checklistOperacaoItem.pesoLiquido,
    excluido: checklistOperacaoItem.excluido,
  }
}

export function updatedChecklistOperacaoItemInfo() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_UPDATED'
  }
}

export function createChecklistOperacaoItemInfo() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_CREATED_SUCCESSFULLY'
  }
}

export function deleteChecklistOperacoesItensDetails() {
  return {
    type: 'DELETED_CHECKLIST_OPERACAO_ITEM_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CHECKLIST_OPERACAO_ITEM_NOTISLOADING'
  }
}
