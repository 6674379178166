import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const categoriaInsumoAction = {
  getCategoriaInsumo,
  getCategoriaInsumoById,
  getCategoriaInsumoSelect,
  getCategoriaInsumoSelectChecklistOperacao,
  onChangeProps,
  editCategoriaInsumoInfo,
  editCategoriaInsumosDetails,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getCategoriaInsumo(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'categoriasInsumos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCategoriaInsumosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCategoriaInsumoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'categoriasInsumos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCategoriaInsumosDetails(response.data))
    })
  }
}

function getCategoriaInsumoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'categoriasInsumos/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCategoriaInsumosList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getCategoriaInsumoSelectChecklistOperacao() {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'categoriasInsumos/select/checklistOperacao';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading())
        dispatch(changeCategoriaInsumosList(response.data, 0))
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err.response);
        return err.response;
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editCategoriaInsumoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'categoriasInsumos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedCategoriaInsumoInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/insumos')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar categoria Insumo! 😥')
        //  console.log(err.response);
        return err.response;
      }
  )}
}
export function addCategoriaInsumo(payload) {
  return [{ type: 'ADD_CATEGORIAINSUMO', categoriaInsumo: payload }, clear()]
}

export function changeCategoriaInsumosList(categoriaInsumo, totalRows) {
  return {
    type: 'FETCHED_ALL_CATEGORIAINSUMO',
    categoriaInsumo: categoriaInsumo,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CATEGORIAINSUMO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CATEGORIAINSUMO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CATEGORIAINSUMO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCategoriaInsumosDetails(categoriaInsumo) {
  return [
    { type: 'CATEGORIAINSUMO_DETAIL', categoriaInsumo },
  ]
}

export function updatedCategoriaInsumoInfo() {
  return {
    type: 'CATEGORIAINSUMO_UPDATED'
  }
}

export function createCategoriaInsumoInfo() {
  return {
    type: 'CATEGORIAINSUMO_CREATED_SUCCESSFULLY'
  }
}

export function deleteCategoriaInsumosDetails() {
  return {
    type: 'DELETED_CATEGORIAINSUMO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'CATEGORIAINSUMO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CATEGORIAINSUMO_NOTISLOADING'
  }
}
