import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Page } from 'components'
import { Typography } from '@material-ui/core'
import {
  Header,
  StatisticsCustos
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardCompraAction, certificadoAction } from '_actions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(0)
    }
  },
  statistics: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3)
    }
  },
  notifications: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3)
    }
  },
  summary: {
    marginTop: theme.spacing(3)
  },
  titleCard: {
    fontWeight: 500,
    flex: 1,
    fontSize: 16,
    marginTop: 30,
    marginLeft: 10
  }
}))

const HomeCustos = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { dashboardHome, isLoading } = useSelector((state) => state.dashboardCompra)
  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {
    if (idLoja !== '') {
      dispatch(certificadoAction.notifyCertificadoStatus(idLoja))
      dispatch(dashboardCompraAction.getDashboardComprasHome(idLoja))
    }
    else {
      dispatch(dashboardCompraAction.getDashboardComprasHome(0))
    }
  }, [dispatch, idLoja])

  return (
    <Page className={classes.root} title="Home">
      <Header />
      <Typography component="h4" className={classes.titleCard}>
        RELATÓRIO DE CUSTOS
      </Typography>
      <StatisticsCustos
        idLoja={idLoja}
        isLoading={isLoading}
        dashboardHome={dashboardHome}
        className={classes.statistics}
      />
    </Page>
  )
}

export default HomeCustos
