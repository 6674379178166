import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  withStyles,
  Tooltip
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import { authActions } from '../../../../_actions'
import { withRouter } from 'react-router-dom'
import { SelectLoja } from './components'
import { history } from '../../../../utils'

const styles = (theme) => ({
  root: {
    boxShadow: 'none'
  }
})

const Topbar = ({ staticContext, theme, ...props }) => {
  const { className, onSidebarOpen, ...rest } = props
  const { classes } = props
  const dispatch = useDispatch()

  const blockNavigation = (message) => {
    const unblock = history.block((tx) => {
      const userConfirmed = window.confirm(message);

      unblock(); // Desbloqueia para evitar loops infinitos

      if (userConfirmed) {
        dispatch(authActions.logout())
      }

      return userConfirmed;
    });
  };

  const logout = () => {
    blockNavigation('Você tem certeza que gostaria de sair?')
    history.push('/login') // Necessário para ativar o bloqueio
  }

  return (
    <AppBar
      color="secondary"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/principal">
          <img
            width="25px"
            height="25px"
            alt="Logo"
            src="/images/logo_vermelho.svg"
          />
        </RouterLink>
        <div style={{ flexGrow: 1 }} />
        <Hidden mdDown>
          <div className={classes.selectLoja}>
            <SelectLoja disabled={false} />
          </div>
        </Hidden>
        <Hidden lgUp>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0
          }}>
            <SelectLoja disabled={false} />
          </div>
        </Hidden>
        {/*<Hidden mdDown>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>*/}
        <Tooltip title="Sair">
          <IconButton
            style={{ marginLeft: 10 }}
            color="inherit"
            onClick={logout}
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario
  }
}
const TopbarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Topbar))
)
export default TopbarPage
