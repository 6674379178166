import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { categoriaCompraAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
})

const CategoriaCompra = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { match: { params } } = props;
  const { categoriaCompra, totalRegistros } = props.categoriaCompra
  const { page, limit, term, direction, order } = useSelector(state => state.categoriaCompra);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc',
    };

    dispatch(categoriaCompraAction.getCategoria(filterModel, true));
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Categoria?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.categoriaCompra.isLoading,
      confirmationText: props.categoriaCompra.isLoading ? 'Excluindo categoria...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(categoriaCompraAction.deleteCategoriaById(id))
    })
  }

  const handleChange = prop => event => {
    dispatch(categoriaCompraAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(categoriaCompraAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.categoriaCompra.page = 0;
    dispatch(categoriaCompraAction.onChangeProps('term', fakeEvent))
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Categorias - Editar Categoria'
          : 'Gerência de Categorias - Nova Categoria'
      }
    >
      <AccessControl
        rule={'categoriasCompras'}
        yes={() => (
          <div>
            <TitleContent
              rule={'categoriasCompras.create'}
              length={undefined !== categoriaCompra && categoriaCompra.length > 0 ? categoriaCompra.length : 0}
              subTitle={'GERÊNCIA DE CATEGORIA'}
              title={'Lista de Categorias de Compras'}
              href={'/categoriaCompra'}
            />
            <Grid container spacing={3}>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={(event) => handleChangeTerm(event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>CPV</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== categoriaCompra && categoriaCompra.length
                    ? categoriaCompra.map((c) => {
                      
                      return (
                        <TableRow hover key={c.id}>
                          <TableCell component="th" scope="row">
                            {c.nome}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {c.cpv == true
                              ?
                              'SIM'
                              :
                              'NÃO'
                            }
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'categoriasCompras.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/categoriaCompra/${c.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip>
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'categoriasCompras.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(c.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )  
}

CategoriaCompra.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    categoriaCompra: state.categoriaCompra
  }
}
const connectedCategoriaCompraPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CategoriaCompra))
)
export { connectedCategoriaCompraPage as CategoriaCompra }
