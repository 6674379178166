import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Switch
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { mensagemAction, lojaAction, fornecedorAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import SaveIcon from '@material-ui/icons/Save';
import { EditorHeader, Page } from 'components'
import ModuloMensagem from '../../utils/enums/moduloMensagem';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const MensagemEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, mensagem, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  //parte de vincular lojas
  const [idLoja, setIdLoja] = useState(0);
  const [textLoja, setTextLoja] = useState('');
  const [vincularLojas, setVincularLojas] = useState(false);

  const lojas = useSelector((state) => state.loja.loja)

  //parte de vincular fornecedores
  const [idFornecedor, setIdFornecedor] = useState(0);
  const [textFornecedor, setTextFornecedor] = useState('');
  const [vincularFornecedores, setVincularFornecedores] = useState(false);

  const fornecedores = useSelector((state) => state.fornecedor.fornecedor)

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    dispatch(lojaAction.getLojaSelect())
    dispatch(fornecedorAction.getFornecedorSelect())

    dispatch(mensagemAction.clearMensagemLojas())

    if (params.id) {
      dispatch(mensagemAction.getMensagemById(params.id))

    } else {
      dispatch(mensagemAction.clear())
      dispatch(mensagemAction.clearMensagemLojas())
    }


  }, []);

  useEffect(() => {

  }, [ready, props.mensagem.isLoading])

  useEffect(() => {

    if (props.mensagem.todasLojas == true) {
      setVincularLojas(false);
      props.mensagem.mensagensLojas = []
    } else {
      setVincularLojas(true);
    }

  }, [props.mensagem.todasLojas])

  useEffect(() => {

    if (props.mensagem.todosFornecedores == true) {
      setVincularFornecedores(false);
      props.mensagem.mensagensFornecedores = []
    } else {
      setVincularFornecedores(true);
    }

  }, [props.mensagem.todosFornecedores])

  const handleChange = (prop) => (event) => {
    dispatch(mensagemAction.onChangeProps(prop, event))
  }

  const handleAddOnListLojas = e => {

    if (props.mensagem.mensagensLojas.filter(ts => ts.idLoja == parseInt(idLoja)).length > 0) {
      toast.error('Erro ao adicionar! Esta loja já existe!')
    }
    else {

      if (idLoja != '' && idLoja != '0') {
        props.mensagem.mensagensLojas.push({ 'idLoja': idLoja, 'idMensagem': props.mensagem.id, 'loja': { 'nome': textLoja } });
        forceUpdate(n => !n);
        setIdLoja(0);

      } else {
        toast.error('Informe ao menos uma loja!')
      }
    }
  };

  const handleRemoveOnListLojas = id => {
    props.mensagem.mensagensLojas = props.mensagem.mensagensLojas.filter(ts => ts.idLoja != parseInt(id));
    forceUpdate(n => !n);
  };

  const handleAddOnListFornecedores = e => {

    if (props.mensagem.mensagensFornecedores.filter(ts => ts.idFornecedor == parseInt(idFornecedor)).length > 0) {
      toast.error('Erro ao adicionar! Esta fornecedor já existe!')
    }
    else {

      if (idFornecedor != '' && idFornecedor != '0') {
        props.mensagem.mensagensFornecedores.push({ 'idFornecedor': idFornecedor, 'idMensagem': props.mensagem.id, 'fornecedor': { 'nome': textFornecedor } });
        forceUpdate(n => !n);
        setIdFornecedor(0);

      } else {
        toast.error('Informe ao menos uma fornecedor!')
      }
    }
  };

  const handleRemoveOnListFornecedores = id => {
    props.mensagem.mensagensFornecedores = props.mensagem.mensagensFornecedores.filter(ts => ts.idFornecedor != parseInt(id));
    forceUpdate(n => !n);
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      tipo: props.mensagem.tipo,
      modulo: props.mensagem.modulo,
      titulo: props.mensagem.titulo,
      textoMensagem: props.mensagem.textoMensagem,
      dataInicio: props.mensagem.dataInicio ? moment(props.mensagem.dataInicio).format("YYYY-MM-DD") : null,
      dataTermino: props.mensagem.dataTermino ? moment(props.mensagem.dataTermino).format("YYYY-MM-DD") : null,
      todasLojas: props.mensagem.todasLojas ? props.mensagem.todasLojas : false,
      todosFornecedores: props.mensagem.todosFornecedores ? props.mensagem.todosFornecedores : false,
      mensagensLojas: props.mensagem.todasLojas == false ? props.mensagem.mensagensLojas : [],
      mensagensFornecedores: props.mensagem.todosFornecedores == false ? props.mensagem.mensagensFornecedores : []
    }

    if (params.id) {
      dispatch(mensagemAction.editMensagemInfo(params.id, payload, true))
    } else {
      dispatch(mensagemAction.createMensagem(payload))
    }

  };

  const handleSelectChangeStateLojas = prop => event => {
    setIdLoja(event.target.value);
    setTextLoja(event.nativeEvent.target.innerText);
  };

  const handleSelectChangeStateFornecedores = prop => event => {
    setIdFornecedor(event.target.value);
    setTextFornecedor(event.nativeEvent.target.innerText);
  };

  function excludeFilterLojas(obj) {
    if (props.mensagem.mensagensLojas && props.mensagem.mensagensLojas.length > 0) {
      if (props.mensagem.mensagensLojas.filter(fl => fl.idLoja == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function excludeFilterFornecedores(obj) {
    if (props.mensagem.mensagensFornecedores && props.mensagem.mensagensFornecedores.length > 0) {
      if (props.mensagem.mensagensFornecedores.filter(fl => fl.idFornecedor == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Mensagem'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Mensagens - Editar Mensagem'
          : 'Gerência de Mensagens - Nova Mensagem'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formMensagem"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Mensagem"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Mensagem' tabela='Mensagem' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={2} xl={2} xs={6}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Módulo *"
                        labelId="modulo"
                        errorMessages={['Campo obrigatório!']}
                        value={props.mensagem.modulo}
                        onChange={handleChange('modulo')}
                        name="modulo"
                        fullWidth
                        inputProps={{
                          name: "Módulo",
                          id: "modulo"
                        }}
                        input={<Input id="modulo" />}
                        margin="normal"
                        variant="outlined"
                        id="modulo"
                      >
                        <MenuItem disabled value="">
                          <em>Módulo *</em>
                        </MenuItem>
                        {
                          Object.keys(ModuloMensagem).map((row, index) => (
                            <MenuItem key={index} value={index}>
                              {Object.values(ModuloMensagem).at(index)}
                            </MenuItem>
                          ))
                        }
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={6}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Tipo *"
                        labelId="tipo"
                        errorMessages={['Campo obrigatório!']}
                        value={props.mensagem.tipo}
                        onChange={handleChange('tipo')}
                        name="tipo"
                        fullWidth
                        inputProps={{
                          name: "Tipo",
                          id: "tipo"
                        }}
                        input={<Input id="tipo" />}
                        margin="normal"
                        variant="outlined"
                        id="tipo"
                      >
                        <MenuItem value={'I'}>Informativa</MenuItem>
                        <MenuItem value={'A'}>Alerta</MenuItem>
                        <MenuItem value={'U'}>Urgência</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="titulo"
                        label="Título *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('titulo')}
                        value={props.mensagem.titulo}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="mensagem"
                        label="Mensagem *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('textoMensagem')}
                        value={props.mensagem.textoMensagem}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Data de Início"
                        type="date"
                        value={props.mensagem.dataInicio || ''}
                        onChange={handleChange('dataInicio')}
                        id="dataInicio"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        fullWidth
                        id="dataTermino"
                        label="Data de Término"
                        margin="normal"
                        type="date"
                        onChange={handleChange('dataTermino')}
                        value={props.mensagem.dataTermino || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '20px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ alignContent: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" align="center">
                      <b>{'Lojas vinculadas'}</b>
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.mensagem.todasLojas}
                          onChange={handleChange('todasLojas')}
                          color="primary"
                        />
                      }
                      label="Selecionar todas as lojas"
                    />
                  </Grid>
                  {vincularLojas == false ?
                    <>
                      <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            disabled
                            id="idLoja"
                            label="Lojas vinculadas *"
                            value={idLoja}
                            onChange={handleSelectChangeStateLojas('idLoja')}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idLoja"
                          >
                            <MenuItem disabled value="" key={12}>
                              <em>Loja *</em>
                            </MenuItem>
                            {!isEmpty(lojas) &&
                              undefined !== lojas &&
                              lojas.length &&
                              lojas.filter(excludeFilterLojas).map((row, index) => (
                                <MenuItem key={'u' + index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                          <Button style={{ width: '200px', marginLeft: '15px' }} disabled name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnListLojas}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                            <Grid item lg={4} md={4} xl={4} xs={12}>
                              {
                                (props.mensagem && props.mensagem.mensagensLojas && props.mensagem.mensagensLojas.length > 0 ?
                                  props.mensagem.mensagensLojas.map(n => {
                                    return (
                                      (n.id !== '' ?
                                        <Button color="default" onClick={() => handleRemoveOnListLojas(n.idLoja)} key={'button' + n.id} disabled>
                                          <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                            {(n.loja ? n.loja.nome : n.idLoja)}
                                          </Badge>
                                          &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                        : null)
                                    );
                                  })
                                  :
                                  null
                                )
                              }
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                    :
                    <>
                      <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="idLoja"
                            label="Lojas vinculadas *"
                            value={idLoja}
                            onChange={handleSelectChangeStateLojas('idLoja')}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idLoja"
                          >
                            <MenuItem disabled value="" key={12}>
                              <em>Loja *</em>
                            </MenuItem>
                            {!isEmpty(lojas) &&
                              undefined !== lojas &&
                              lojas.length &&
                              lojas.filter(excludeFilterLojas).map((row, index) => (
                                <MenuItem key={'u' + index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                          <Button style={{ width: '200px', marginLeft: '15px' }} name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnListLojas}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                        </Grid>
                        <Grid item lg={4} md={4} xl={4} xs={12}>
                          <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                              {
                                (props.mensagem && props.mensagem.mensagensLojas && props.mensagem.mensagensLojas.length > 0 ?
                                  props.mensagem.mensagensLojas.map(n => {
                                    return (
                                      (n.id !== '' ?
                                        <Button color="default" onClick={() => handleRemoveOnListLojas(n.idLoja)} key={'button' + n.id}>
                                          <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                            {(n.loja ? n.loja.nome : n.idLoja)}
                                          </Badge>
                                          &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                        : null)
                                    );
                                  })
                                  :
                                  null
                                )
                              }
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  }
                  <Grid item md={12} xs={12} style={{ marginTop: '20px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  {
                    (props.mensagem.modulo !== Object.values(ModuloMensagem).indexOf(ModuloMensagem.FIDELIDADE)) ?
                    <>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{ alignContent: 'center', justifyContent: 'center' }}>
                        <Typography variant="h5" align="center">
                          <b>{'Fornecedores vinculados'}</b>
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.mensagem.todosFornecedores}
                              onChange={handleChange('todosFornecedores')}
                              color="primary"
                            />
                          }
                          label="Selecionar todos os fornecedores"
                        />
                      </Grid>
                      {vincularFornecedores == false ?
                        <>
                          <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                              <SelectValidator
                                validators={['required']}
                                errorMessages={['Campo obrigatório!']}
                                variant="outlined"
                                disabled
                                id="idFornecedor"
                                label="Fornecedores vinculados *"
                                value={idFornecedor}
                                onChange={handleSelectChangeStateFornecedores('idFornecedor')}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                fullWidth
                                displayEmpty
                                labelId="idFornecedor"
                              >
                                <MenuItem disabled value="" key={12}>
                                  <em>Fornecedor *</em>
                                </MenuItem>
                                {!isEmpty(fornecedores) &&
                                  undefined !== fornecedores &&
                                  fornecedores.length &&
                                  fornecedores.filter(excludeFilterFornecedores).map((row, index) => (
                                    <MenuItem key={'u' + index} value={row.value}>
                                      {row.text}
                                    </MenuItem>
                                  ))}
                              </SelectValidator>
                            </Grid>
                            <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                              <Button style={{ width: '200px', marginLeft: '15px' }} disabled name="btnFornecedores" id="btnFornecedores" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnListFornecedores}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                            </Grid>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                              <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                                <Grid item lg={4} md={4} xl={4} xs={12}>
                                  {
                                    (props.mensagem && props.mensagem.mensagensFornecedores && props.mensagem.mensagensFornecedores.length > 0 ?
                                      props.mensagem.mensagensFornecedores.map(n => {
                                        return (
                                          (n.id !== '' ?
                                            <Button color="default" onClick={() => handleRemoveOnListFornecedores(n.idFornecedor)} key={'button' + n.id} disabled>
                                              <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                                {(n.fornecedor ? n.fornecedor.nome : n.idFornecedor)}
                                              </Badge>
                                              &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                            : null)
                                        );
                                      })
                                      :
                                      null
                                    )
                                  }
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                        :
                        <>
                          <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                              <SelectValidator
                                validators={['required']}
                                errorMessages={['Campo obrigatório!']}
                                variant="outlined"
                                id="idFornecedor"
                                label="Fornecedores vinculados *"
                                value={idFornecedor}
                                onChange={handleSelectChangeStateFornecedores('idFornecedor')}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                fullWidth
                                displayEmpty
                                labelId="idFornecedor"
                              >
                                <MenuItem disabled value="" key={12}>
                                  <em>Fornecedor *</em>
                                </MenuItem>
                                {!isEmpty(fornecedores) &&
                                  undefined !== fornecedores &&
                                  fornecedores.length &&
                                  fornecedores.filter(excludeFilterFornecedores).map((row, index) => (
                                    <MenuItem key={'u' + index} value={row.value}>
                                      {row.text}
                                    </MenuItem>
                                  ))}
                              </SelectValidator>
                            </Grid>
                            <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                              <Button style={{ width: '200px', marginLeft: '15px' }} name="btnFornecedores" id="btnFornecedores" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnListFornecedores}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                            </Grid>
                            <Grid item lg={4} md={4} xl={4} xs={12}>
                              <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                                <Grid item lg={12} md={12} xl={12} xs={12}>
                                  {
                                    (props.mensagem && props.mensagem.mensagensFornecedores && props.mensagem.mensagensFornecedores.length > 0 ?
                                      props.mensagem.mensagensFornecedores.map(n => {
                                        return (
                                          (n.id !== '' ?
                                            <Button color="default" onClick={() => handleRemoveOnListFornecedores(n.idFornecedor)} key={'button' + n.id}>
                                              <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                                {(n.fornecedor ? n.fornecedor.nome : n.idFornecedor)}
                                              </Badge>
                                              &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                            : null)
                                        );
                                      })
                                      :
                                      null
                                    )
                                  }
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      }
                    </>
                    :
                    null
                  }
                </CardContent>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/mensagens')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.mensagem.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.mensagem.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.mensagem.isLoading ? 'Atualizando mensagem...' : 'Atualizar') : (props.mensagem.isLoading ? 'Salvando mensagem...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

MensagemEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedMensagemEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(MensagemEdit))
)
export { connectedMensagemEditPage as MensagemEdit }
