import React, { createRef, useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { totvsInsumoAction, totvsInsumoConversaoAction, compraAction, subcategoriaCompraAction, categoriaCompraAction } from '../../_actions'
import { AccessControl } from '../../components/Utils/AccessControl'
import DialogActions from '@material-ui/core/DialogActions';
import { StickyContainer, Sticky } from 'react-sticky';
import SaveIcon from '@material-ui/icons/Save'
import { apenasNumero, cnpjMask } from '../../utils';
import TipoInsumoPrioridade from '../../utils/enums/tipoInsumoPrioridade';
import { isEmpty } from 'lodash'

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const CompraClassificarInsumoModal = ({ modal, toggle, idCompra, idTotvsInsumoConversao, totvsInsumoSelected, compraProduto, categorias, subcategorias }, props) => {

  const dispatch = useDispatch()
  const { className, ...rest } = props
  const classes = props

  const [tab, setTab] = useState(0);

  const [idTotvsInsumo, setIdTotvsInsumo] = useState(null);
  const [idCategoria, setIdCategoria] = useState(0);
  const [idSubcategoria, setIdSubcategoria] = useState(0);

  const [inputValue, setInputValue] = useState('');

  const formRef = createRef(null);

  const { totvsInsumo } = useSelector(state => state.totvsInsumo)
  //const totvsInsumoSelected = useSelector(state => state.totvsInsumo)
  const totvsInsumoConversao = useSelector(state => state.totvsInsumoConversao)

  const { unidade } = useSelector(state => state.totvsInsumo)

  const categoriaCompra = useSelector(state => state.categoriaCompra)

  const options = Array.isArray(totvsInsumo) ? totvsInsumo?.map(u => ({ id: u.value, nome: u.text })) : [];

  useEffect(() => {
    dispatch(totvsInsumoAction.getTotvsInsumoSelect())

    //if (totvsInsumo?.id) {
    //  setValue({ id: totvsInsumo.id, nome: totvsInsumo.nome })
    //} else
    if (totvsInsumoSelected != null) {
      setIdTotvsInsumo({ id: totvsInsumoSelected.id?.toString(), nome: totvsInsumoSelected.nome })
    }

    if (idTotvsInsumoConversao != null && idTotvsInsumoConversao != 0) {
      dispatch(totvsInsumoConversaoAction.getTotvsInsumoConversaoById(idTotvsInsumoConversao))
    }

  }, [])

  useEffect(() => {
    if (totvsInsumoConversao) {
      setIdCategoria(totvsInsumoConversao.idCategoriaCompra)

      if (totvsInsumoConversao.idCategoriaCompra) {
        dispatch(subcategoriaCompraAction.getSubcategoriasByCategoriaId(totvsInsumoConversao.idCategoriaCompra))
      }

    }
  }, [totvsInsumoConversao.idCategoriaCompra])

  useEffect(() => {

    if (totvsInsumoConversao) {
      setIdSubcategoria(totvsInsumoConversao.idSubcategoriaCompra)
    }

  }, [totvsInsumoConversao.idSubcategoriaCompra])

  useEffect(() => {

    console.log('totvsInsumoConversao.idTotvsInsumo', totvsInsumoConversao.idTotvsInsumo)    

    if (totvsInsumoConversao) {

      if (totvsInsumoConversao.idTotvsInsumo) {
        dispatch(totvsInsumoAction.getTotvsInsumoById(totvsInsumoConversao.idTotvsInsumo))

        let selectedInsumo = totvsInsumo.filter(
          (item) => item.value == totvsInsumoConversao.idTotvsInsumo
        )

        if (selectedInsumo.length > 0) {

          let newSelectedInsumo = {
            id: selectedInsumo[0].value,
            nome: selectedInsumo[0].text
          }
          setIdTotvsInsumo(newSelectedInsumo)

        }

      }

    }

  }, [totvsInsumoConversao.idTotvsInsumo])

  const getPrioridadeValue = (tipo) => {
    return Object.values(TipoInsumoPrioridade).indexOf(tipo) + 1
  }

  const handleChange = (prop) => (event) => {
    dispatch(totvsInsumoConversaoAction.onChangeProps(prop, event))
  }

  const handleTabsChange = (event, value) => {
    setTab(value);
  }

  const handleChangeCategoria = (event) => {
    let categoriaId = event.target.value

    if (categoriaId) {

      var event2 = {
        type: 'select',
        target: {
          value: categoriaId
        }
      };
      dispatch(totvsInsumoConversaoAction.onChangeProps('idCategoriaCompra', event2))
      setIdCategoria(categoriaId)

    }

    dispatch(categoriaCompraAction.getCategoriaById(categoriaId))
    dispatch(subcategoriaCompraAction.getSubcategoriasByCategoriaId(categoriaId))

    setIdSubcategoria(0)
  }

  const handleChangeSubcategoria = (event) => {

    let subCategoriaId = event.target.value

    if (subCategoriaId) {

      var event2 = {
        type: 'select',
        target: {
          value: subCategoriaId
        }
      };
      dispatch(totvsInsumoConversaoAction.onChangeProps('idSubcategoriaCompra', event2))
      setIdSubcategoria(subCategoriaId)

    }

  }

  const handleChangeTotvsInsumo = (event, valueTtotvsInsumo) => {

    if (valueTtotvsInsumo) {

      setIdTotvsInsumo(valueTtotvsInsumo)

      var event2 = {
        type: 'select',
        target: {
          value: valueTtotvsInsumo.id
        }
      };
      dispatch(totvsInsumoConversaoAction.onChangeProps('idTotvsInsumo', event2))

    }

  }

  const handleSubmit = (event) => {

    let payload = {
      id: totvsInsumoConversao.id,
      idCategoriaCompra: idCategoria,
      idSubcategoriaCompra: idSubcategoria,
      idTotvsInsumo: idTotvsInsumo && idTotvsInsumo.id != 0 ? idTotvsInsumo.id : null,
      campo: totvsInsumoConversao.campo,
      tipo: totvsInsumoConversao.tipo,
      texto: totvsInsumoConversao.campo == 'CNPJFornecedor' ? apenasNumero(totvsInsumoConversao.texto) : totvsInsumoConversao.texto,
      campo2: totvsInsumoConversao.campo2 || null,
      tipo2: totvsInsumoConversao.tipo2 || null,
      texto2: totvsInsumoConversao.campo2 == 'CNPJFornecedor' ? apenasNumero(totvsInsumoConversao.texto2) || null : totvsInsumoConversao.texto2 || null,
      tipoValor: totvsInsumoConversao.tipoValor || null,
      conversao: totvsInsumoConversao.conversao || null,
      prioridade: totvsInsumoConversao.prioridade
    }

    //if (value && value.id && value.id != 0 && payload) {
    if (totvsInsumoConversao.id) {
      dispatch(totvsInsumoConversaoAction.editTotvsInsumoConversaoInfo(totvsInsumoConversao.id, payload))
    } else {
      dispatch(totvsInsumoConversaoAction.createTotvsInsumoConversao(payload, idCompra))
    }

    toggle()
    //} else {
    //  toast.error('Selecione um Insumo')
    //}
  };

  const handleSubmitWithoutRules = (event) => {
    let payload = {
      ...compraProduto,
      idCategoriaCompra: idCategoria,
      idSubcategoriaCompra: idSubcategoria,
      idTotvsInsumo: idTotvsInsumo && idTotvsInsumo.id != 0 ? idTotvsInsumo.id : null,
    }

    //if (value && value.id && value.id != 0) {
    if (compraProduto && payload) {
      dispatch(compraAction.editCompraProdutoInfo(compraProduto.id, payload, idCompra))
      toggle()
      dispatch(compraAction.clear());
    }
    //} else {
    //  toast.error('Selecione um Insumo')
    //}
  }

  const handleClean = () => {
    dispatch(totvsInsumoConversaoAction.clear());

    setIdCategoria(0);
    setIdSubcategoria(0);
    setIdTotvsInsumo(null);
  }

  return (
    <Dialog
      open={modal}
      onClose={toggle}
      maxWidth='md'
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              CLASSIFICAR INSUMO
            </Typography>
          </Grid>
          <AccessControl
            rule={'fornecedores.list'}
            yes={() => (
              <Grid item xs={6} align="right">
                <Button
                  color="default"
                  variant="contained"
                  endIcon={<CloseIcon />}
                  onClick={toggle}
                >
                  Fechar
                </Button>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles}>
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant={'scrollable'}
        >
          <Tab
            key={0}
            label={"COM REGRA"}
            value={0}
          />
          <Tab
            key={1}
            label={"SEM REGRA"}
            value={1}
            disabled={!idCompra}
          />
        </Tabs>
        <ValidatorForm
          ref={formRef}
          id="formClassificarInsumo"
          onSubmit={(event) => !tab ? handleSubmit(event) : handleSubmitWithoutRules(event)}
        >
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <SelectValidator
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                className={classes.textField}
                id="idCategoriaCompra"
                label="Categoria *"
                value={idCategoria || ''}
                onChange={handleChangeCategoria}
                name="categoria"
                inputProps={{
                  name: "Categoria *",
                  id: "categoria",
                  shrink: true
                }}
                variant="outlined"
                margin="normal"
                fullWidth
              >
                <MenuItem disabled value="">
                  <em>Categoria *</em>
                </MenuItem>
                {!isEmpty(categorias) &&
                  undefined !== categorias &&
                  categorias.length &&
                  categorias.map((row, index) => (
                    <MenuItem key={index} value={row.value}>
                      {row.text}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <SelectValidator
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                className={classes.textField}
                id="idSubcategoriaCompra"
                label="Subcategoria *"
                disabled={!idCategoria}
                value={idSubcategoria || ''}
                onChange={handleChangeSubcategoria}
                name="subcategoria"
                inputProps={{
                  name: "Subcategoria *",
                  id: "subcategoria",
                  shrink: true
                }}
                variant="outlined"
                margin="normal"
                fullWidth
              >
                <MenuItem disabled value="">
                  <em>Subcategoria *</em>
                </MenuItem>
                {!isEmpty(subcategorias) &&
                  undefined !== subcategorias &&
                  subcategorias.length &&
                  subcategorias.map((row, index) => (
                    <MenuItem key={index} value={row.id}>
                      {row.nome}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Autocomplete
                value={idTotvsInsumo}
                onChange={(event, value) => {
                  handleChangeTotvsInsumo(event, value)
                }}
                margin="normal"
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                defaultValue={null}
                getOptionLabel={option => option.nome ? option.nome : ''}
                renderInput={(params) => <TextField {...params} label="Selecione um Insumo" variant="outlined" fullWidth required={categoriaCompra.cpv} />}
                style={{ width: '100%', marginTop: 9 }}
                fullWidth
              />
              {(idTotvsInsumo && unidade?.id) && <Typography variant="body2" style={{ marginTop: 5 }}>Unidade para classificação: {unidade.abreviacao}</Typography>}
            </Grid>
            {!tab ?
              <>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    className={classes.textField}
                    label="Campo *"
                    labelId="campo"
                    value={totvsInsumoConversao.campo}
                    onChange={handleChange('campo')}
                    name="campo"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      name: "Campo",
                      id: "campo"
                    }}
                    input={<Input id="campo" />}
                    margin="normal"
                    variant="outlined"
                    id="campo"
                  >
                    <MenuItem disabled value={''}>Campo</MenuItem>
                    <MenuItem value={'codigoProduto'}>CÓDIGO INTERNO DO PRODUTO</MenuItem>
                    <MenuItem value={'CNPJFornecedor'}>CNPJ DO FORNECEDOR</MenuItem>
                    <MenuItem value={'descricao'}>DESCRIÇÃO DO PRODUTO</MenuItem>
                    <MenuItem value={'GTIN/CEAN'}>GTIN/CEAN COMERCIAL</MenuItem>
                    <MenuItem value={'GTIN/CEANTributavel'}>GTIN/CEAN TRIBUTÁVEL</MenuItem>
                    <MenuItem value={'NCM'}>NCM</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    className={classes.textField}
                    label="Tipo *"
                    labelId="tipo"
                    value={totvsInsumoConversao.tipo}
                    onChange={handleChange('tipo')}
                    name="tipo"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      name: "Tipo",
                      id: "tipo"
                    }}
                    input={<Input id="tipo" />}
                    margin="normal"
                    variant="outlined"
                    id="tipo"
                  >
                    <MenuItem disabled value={''}>Tipo</MenuItem>
                    <MenuItem value={'igual'}>IGUAL</MenuItem>
                    <MenuItem value={'contem'}>CONTÉM</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="texto"
                    label="Texto *"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    margin="normal"
                    onChange={handleChange('texto')}
                    value={totvsInsumoConversao.campo == 'CNPJFornecedor' ? cnpjMask(totvsInsumoConversao.texto) || '' : totvsInsumoConversao.texto || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <SelectValidator
                    className={classes.textField}
                    label="Campo 2"
                    labelId="campo2"
                    value={totvsInsumoConversao.campo2}
                    onChange={handleChange('campo2')}
                    name="campo2"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      name: "Campo 2",
                      id: "campo2"
                    }}
                    input={<Input id="campo2" />}
                    margin="normal"
                    variant="outlined"
                    id="campo2"
                  >
                    <MenuItem disabled value={''}>Campo 2</MenuItem>
                    <MenuItem value={'codigoProduto'}>CÓDIGO INTERNO DO PRODUTO</MenuItem>
                    <MenuItem value={'CNPJFornecedor'}>CNPJ DO FORNECEDOR</MenuItem>
                    <MenuItem value={'descricao'}>DESCRIÇÃO DO PRODUTO</MenuItem>
                    <MenuItem value={'GTIN/CEAN'}>GTIN/CEAN COMERCIAL</MenuItem>
                    <MenuItem value={'GTIN/CEANTributavel'}>GTIN/CEAN TRIBUTÁVEL</MenuItem>
                    <MenuItem value={'NCM'}>NCM</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <SelectValidator
                    validators={(totvsInsumoConversao.campo2 ? ['required'] : null)}
                    errorMessages={(totvsInsumoConversao.campo2 ? ['Campo obrigatório!'] : null)}
                    className={classes.textField}
                    label="Tipo 2"
                    labelId="tipo2"
                    value={totvsInsumoConversao.tipo2}
                    onChange={handleChange('tipo2')}
                    name="tipo2"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      name: "Tipo 2",
                      id: "tipo2"
                    }}
                    input={<Input id="tipo2" />}
                    margin="normal"
                    variant="outlined"
                    id="tipo2"
                  >
                    <MenuItem disabled value={''}>Tipo 2</MenuItem>
                    <MenuItem value={'igual'}>IGUAL</MenuItem>
                    <MenuItem value={'contem'}>CONTÉM</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <TextValidator
                    validators={(totvsInsumoConversao.campo2 ? ['required'] : null)}
                    errorMessages={(totvsInsumoConversao.campo2 ? ['Campo obrigatório!'] : null)}
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="texto2"
                    label="Texto 2"
                    margin="normal"
                    onChange={handleChange('texto2')}
                    value={totvsInsumoConversao.campo2 == 'CNPJFornecedor' ? cnpjMask(totvsInsumoConversao.texto2) || '' : totvsInsumoConversao.texto2 || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    className={classes.textField}
                    label="Tipo Valor"
                    labelId="tipoValor"
                    value={totvsInsumoConversao.tipoValor}
                    onChange={handleChange('tipoValor')}
                    name="tipoValor"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      name: "Tipo Valor",
                      id: "tipoValor"
                    }}
                    input={<Input id="tipoValor" />}
                    margin="normal"
                    variant="outlined"
                    id="tipoValor"
                  >
                    <MenuItem disabled value={''}>Tipo Valor</MenuItem>
                    <MenuItem value={'C'}>Valor Unitário Comercial</MenuItem>
                    <MenuItem value={'T'}>Valor Unitário Tributável</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="conversao"
                    label="Conversão"
                    margin="normal"
                    type="number"
                    onChange={handleChange('conversao')}
                    value={totvsInsumoConversao.conversao || 0}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      step: 0.00001,
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    className={classes.textField}
                    label="Prioridade"
                    labelId="prioridade"
                    value={totvsInsumoConversao.prioridade}
                    onChange={handleChange('prioridade')}
                    name="prioridade"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      name: "Prioridade",
                      id: "prioridade"
                    }}
                    input={<Input id="prioridade" />}
                    margin="normal"
                    variant="outlined"
                    id="prioridade"
                  >
                    <MenuItem disabled value={''}>Prioridade</MenuItem>
                    <MenuItem value={getPrioridadeValue(TipoInsumoPrioridade.BAIXA)}>BAIXA</MenuItem>
                    <MenuItem value={getPrioridadeValue(TipoInsumoPrioridade.MEDIA)}>MÉDIA</MenuItem>
                    <MenuItem value={getPrioridadeValue(TipoInsumoPrioridade.ALTA)}>ALTA</MenuItem>
                  </SelectValidator>
                </Grid>
              </>
              : null}
            <Grid item lg={12} md={12} xl={12} xs={12} style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlign: 'right',
              justifyContent: 'right'
            }}>
              <Button
                color="default"
                onClick={() => handleClean()}
                variant="contained"
              >
                Limpar
              </Button>
              <Button
                color="primary"
                className={classes.textField}
                type="submit"
                variant="contained"
                disabled={totvsInsumoConversao.isLoading}
                startIcon={<SaveIcon />}
                endIcon={totvsInsumoConversao.isLoading && <CircularProgress size={24} />}
              >
                {totvsInsumoConversao.isLoading ? 'Salvando...' : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </DialogContent>
      <DialogActions>
        <StickyContainer>
          <Sticky relative={false} isSticky={true}>
            {({ style }) => (
              <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between", marginRight: 12 }} spacing={3}>

              </Grid>
            )}
          </Sticky>
        </StickyContainer>
      </DialogActions>
    </Dialog >
  );
}
