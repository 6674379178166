import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRouteWithLayout = (props) => {
  const { layout: Layout, component: Component, type: Type, ...rest } = props

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        localStorage.getItem('user') ? (
          <React.Fragment key={10}>
            <Layout type={Type}>
              <Component {...matchProps} />
            </Layout>
          </React.Fragment>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  type: PropTypes.string,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default PrivateRouteWithLayout
