import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'
import { clienteAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalClientesTransferirPontos = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const [emailClienteDestino, setEmailClienteDestino] = React.useState('');
  const formRef = React.createRef(null);
  const { match: { params } } = props;

  const { openModalClientesTransferirPontos, isLoading } = useSelector(
    (state) => state.cliente
  )

  const handleClose = () => {
    dispatch(clienteAction.openModalClientesTransferirPontos(false))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let payload = {
      idClienteOrigem: parseInt(params.id),
      emailClienteDestino: emailClienteDestino.trim().toLowerCase()
    }

    dispatch(clienteAction.transferPontosCliente(payload))
    setEmailClienteDestino('')
    dispatch(clienteAction.openModalClientesTransferirPontos(false))
  }

  return (
    <Dialog
      open={openModalClientesTransferirPontos}
      onClose={handleClose}
      aria-labelledby="E-mail cliente"
      maxWidth="sm"
      fullWidth
    >     
      <ValidatorForm
        className={classes.form}
        ref={formRef}
        id="formTransferirPontos"
        onSubmit={(event) => handleSubmit(event)}
      >
        <DialogTitle id="form-dialog-title">
          Digite o e-mail do cliente para qual você deseja transferir os pontos
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', paddingTop: 0, marginTop: 0 }}>
          <TextValidator
            className={classes.textField}
            fullWidth
            onInput={stringUtils.toInputLowerCase}
            id="emailClienteDestino"
            label="E-mail do cliente destino *"
            onChange={(e) => setEmailClienteDestino(e.target.value)}
            value={emailClienteDestino}
            variant="outlined"
            margin="normal"
            validators={['required']}
            errorMessages={['Campo obrigatório!']}
          />
        </DialogContent>
        <DialogActions>
          <Grid
            style={{ padding: 5 }}
            spacing={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                {isLoading
                  ? 'Transferindo pontos...'
                  : 'Transferir pontos'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

ModalClientesTransferirPontos.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalClientesTransferirPontosPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ModalClientesTransferirPontos))
)
export { connectedModalClientesTransferirPontosPage as ModalClientesTransferirPontos }
