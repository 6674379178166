import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const trocaAction = {
  getTroca,
  addTroca,
  getTrocaById,
  onChangeProps,
  /*  editTrocaInfo,*/
  approveTroca,
  editTrocasDetails,
  createTroca,
  deleteTrocaById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getTroca(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'trocas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTrocasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createTroca(payload) {
  return (dispatch) => {
    //  console.log('entrou na action');
    const id = toast.loading("Realizando troca...")
    let apiEndpoint = 'trocas/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createTrocaInfo())
      toast.update(id, { render: "Feito! Troca realizada com sucesso! 😎", autoClose: 5000, type: "success", isLoading: false });
      //dispatch(clear())

      let filterModel1 = {
        Page: 0,
        Limit: 10,
        Term: '',
        Order: 'dataHora',
        Direction: 'desc',
        IdCliente: payload.IdCliente
      };
      dispatch(trocaAction.getTroca(filterModel1, true));

    }).catch(() => {
      toast.dismiss(id);
      dispatch(clear())
      let filterModel = {
        Page: 0,
        Limit: 10,
        Term: '',
        Order: 'dataHora',
        Direction: 'desc',
        IdCliente: payload.IdCliente
      };

      dispatch(trocaAction.getTroca(filterModel, true));
    })
  }
}

function approveTroca(id, idCliente) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'trocas/approve/' + id
    crudService
      .post(apiEndpoint)
      .then(() => {
        toast.success('Feito! Troca aprovada com sucesso! 😎')
        dispatch(clear())

        let filterModel = {
          Page: 0,
          Limit: 10,
          Term: '',
          Direction: 'desc',
          IdCliente: idCliente
        };

        dispatch(trocaAction.getTroca(filterModel, true));
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
}

function request(id) {
  return { type: 'EDIT_TROCA_REQUEST', id }
}

function failure(error) {
  return { type: 'EDIT_TROCA_FAILURE', error }
}

function getTrocaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'trocas/' + id
    crudService.get(apiEndpoint).then((response) => {
      // console.log('response.data', response.data)
      dispatch(editTrocasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

//function editTrocaInfo(id, payload, redirect) {
//  return (dispatch) => {
//    let apiEndpoint = 'trocas/' + id
//    crudService.put(apiEndpoint, payload).then(() => {
//      dispatch(updatedTrocaInfo())
//      dispatch(clear())
//      if (redirect) {
//        history.push('/trocas')
//      }
//    })
//  }
//}

function deleteTrocaById(filterModel, id) {
  return (dispatch) => {
    let apiEndpoint = 'trocas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTrocasDetails())
      dispatch(getTroca(filterModel))
    })
  }
}

export function addTroca(payload) {
  return [{ type: 'ADD_TROCA', troca: payload }, clear()]
}

export function changeTrocasList(troca, totalRows) {
  return {
    type: 'FETCHED_ALL_TROCA',
    troca: troca,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'TROCA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'TROCA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TROCA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTrocasDetails(troca) {
  return [
    { type: 'TROCA_DETAIL', troca },
  ]
}

export function updatedTrocaInfo() {
  return {
    type: 'TROCA_UPDATED'
  }
}

export function createTrocaInfo() {
  return {
    type: 'TROCA_CREATED_SUCCESSFULLY'
  }
}

export function deleteTrocasDetails() {
  return {
    type: 'DELETED_TROCA_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'TROCA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TROCA_NOTISLOADING'
  }
}
