import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const unidadeAction = {
  getUnidade,
  addUnidade,
  getUnidadeById,
  getUnidadeSelect,
  onChangeProps,
  editUnidadeInfo,
  editUnidadesDetails,
  createUnidade,
  deleteUnidadeById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getUnidade(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'unidades' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUnidadesList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createUnidade(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'unidades/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createUnidadeInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/unidades')
        } else {
          toast.err('Oops! Erro ao cadastrar unidade! 😥')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar unidade! 😥')
        return err.response;
      })
  }
}

function getUnidadeById(id) {
  return (dispatch) => {
    let apiEndpoint = 'unidades/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editUnidadesDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editUnidadeInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'unidades/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedUnidadeInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/unidades')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar unidade! 😥')
        //  console.log(err.response);
        return err.response;
      }
  )}
}

function deleteUnidadeById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'unidades/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteUnidadesDetails())
      dispatch(getUnidade(filterModel))
      dispatch(notIsLoading())
    })
  }
}

function getUnidadeSelect() {
  return (dispatch) => {
    let apiEndpoint = 'unidades/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUnidadesList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

export function addUnidade(payload) {
  return [{ type: 'ADD_UNIDADE', unidade: payload }, clear()]
}

export function changeUnidadesList(unidade, totalRows) {
  return {
    type: 'FETCHED_ALL_UNIDADE',
    unidade: unidade,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'UNIDADE_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'UNIDADE_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'UNIDADE_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editUnidadesDetails(unidade) {
  return [
    { type: 'UNIDADE_DETAIL', unidade },
  ]
}

export function updatedUnidadeInfo() {
  return {
    type: 'UNIDADE_UPDATED'
  }
}

export function createUnidadeInfo() {
  return {
    type: 'UNIDADE_CREATED_SUCCESSFULLY'
  }
}

export function deleteUnidadesDetails() {
  return {
    type: 'DELETED_UNIDADE_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'UNIDADE_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'UNIDADE_NOTISLOADING'
  }
}
