import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const checklistOperacaoAction = {
  getChecklistOperacao,
  getChecklistOperacaoDia,
  getChecklistOperacaoSelect,
  addChecklistOperacao,
  getChecklistOperacaoById,
  onChangeProps,
  editChecklistOperacaoInfo,
  editChecklistOperacoesDetails,
  duplicateChecklistOperacao,
  createChecklistOperacao,
  deleteChecklistOperacaoById,
  clear,
  clearAll,
  clearChecklistOperacoesLojas,
  clearChecklistOperacoesItens
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'nome',
  Direction: 'asc'
};

function getChecklistOperacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'checklistsOperacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeChecklistOperacoesList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getChecklistOperacaoDia(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading());
    let apiEndpoint = 'checklistsOperacoes/dia' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(notIsLoading());
        dispatch(changeChecklistOperacoesList(response.data, null))
      })
      .catch((err) => {
        dispatch(notIsLoading());
        console.log(err.response);
        return err.response;
      })
  }
}

function getChecklistOperacaoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'checklistsOperacoes/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeChecklistOperacoesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createChecklistOperacao(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklistsOperacoes/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createChecklistOperacaoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(getChecklistOperacaoById(ret.key))
          dispatch(clear())
          dispatch(clearAll())
          dispatch(clearChecklistOperacoesItens());
          dispatch(notIsLoading())
          history.push('/checklistOperacao/' + ret.key)
        } else {
          toast.err('Oops! Erro ao cadastrar checklist de operação! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar checklist de operação! 😥')
        return err.response;
      })
  }
}

function duplicateChecklistOperacao(id, redirect) {
  return (dispatch) => {
    let apiEndpoint = 'checklistsOperacoes/duplicar/' + id
    crudService.post(apiEndpoint).then((response) => {
      dispatch(createChecklistOperacaoInfo())
      toast.success('Feito! Planilha de contagem duplicada com sucesso! 😎')
      dispatch(clear())
      dispatch(notIsLoading())
      if (redirect) {
        var ret = JSON.parse(response.request.responseText);
        history.push('/checklistOperacao/' + ret.key) // retorna para a lista e atualiza
      }
    })
  }
}

function getChecklistOperacaoById(id) {
  return (dispatch) => {
    dispatch(isLoading());
    let apiEndpoint = 'checklistsOperacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(notIsLoading());
      dispatch(editChecklistOperacoesDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'checklistOperacoes') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function editChecklistOperacaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklistsOperacoes/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedChecklistOperacaoInfo())
          dispatch(clear())
          dispatch(clearAll())
         dispatch(notIsLoading())
          history.push('/checklistsOperacoes')
        }// retorna para a lista e atualiza        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar checklist de operação! 😥')
        return err.response;
      })
  }
}

function deleteChecklistOperacaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklistsOperacoes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteChecklistOperacoesDetails())
      dispatch(getChecklistOperacao(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addChecklistOperacao(payload, props) {
  return [{ type: 'ADD_CHECKLIST_OPERACAO', checklistOperacao: payload },
  clear()
  ]
}

export function changeChecklistOperacoesList(checklistOperacao, totalRows) {
  return {
    type: 'FETCHED_ALL_CHECKLIST_OPERACAO',
    checklistOperacao: checklistOperacao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CHECKLIST_OPERACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CHECKLIST_OPERACAO_CLEAR_ALL'
  }
}

export function clearChecklistOperacoesLojas() {
  return {
    type: 'CHECKLIST_OPERACAO_CLEAR_LOJAS'
  }
}

export function clearChecklistOperacoesItens() {
  return {
    type: 'CHECKLIST_OPERACAO_CLEAR_ITENS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CHECKLIST_OPERACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editChecklistOperacoesDetails(checklistOperacao) {
  return {
    type: 'CHECKLIST_OPERACAO_DETAIL',
    id: checklistOperacao.id,
    nome: checklistOperacao.nome,
    instrucao: checklistOperacao.instrucao,
    tipo: checklistOperacao.tipo,
    periodicidade: checklistOperacao.periodicidade,
    idCategoriaInsumo: checklistOperacao.idCategoriaInsumo,
    todasLojas: checklistOperacao.todasLojas,
    checklistsOperacaoLojas: checklistOperacao.checklistsOperacaoLojas,
    checklistsOperacaoItens: checklistOperacao.checklistsOperacaoItens,
    excluido: checklistOperacao.excluido,
  }
}

export function updatedChecklistOperacaoInfo() {
  return {
    type: 'CHECKLIST_OPERACAO_UPDATED'
  }
}

export function createChecklistOperacaoInfo() {
  return {
    type: 'CHECKLIST_OPERACAO_CREATED_SUCCESSFULLY'
  }
}

export function deleteChecklistOperacoesDetails() {
  return {
    type: 'DELETED_CHECKLIST_OPERACAO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'CHECKLIST_OPERACAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CHECKLIST_OPERACAO_NOTISLOADING'
  }
}
