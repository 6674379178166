import moment from 'moment'

const initialState = {
  isLoading: false,
  openModalNotificacaoFiltrosItens: false,
  openModalNotificacaoTestes: false,
  openModalNotificacaoUsuarios: false,
  id: 0,
  dataHora: moment(new Date()).format('YYYY-MM-DD HH:mm'),
  titulo: '',
  descricao: '',
  caminhoImagem: '',
  acao: 'DetalheNotificacao',
  chave: '',
  programado: false,
  dataHora: null,
  dataHoraEnvio: null,
  excluido: false,
  notificacoesFiltros: [],
  totalNotificacoesUsuarios: 0,
  taxaAbertura: 0,
  page: 0,
  limit: 10,
  order: 'dataHora',
  direction: 'desc',
  term: '',
  automatic: false
}

export function notificacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_NOTIFICACAO':
      return {
        ...state,
        notificacao: action.notificacao,
        totalRegistros: action.totalRegistros
      }
    case 'NOTIFICACAO_DETAIL':
      return {
        ...state,
        isLoading: false,
        acao: action.notificacao.acao,
        caminhoImagem: action.notificacao.caminhoImagem,
        chave: action.notificacao.chave,
        programado: action.notificacao.programado,
        dataHora: action.notificacao.dataHora,
        dataHoraEnvio: action.notificacao.dataHoraEnvio,
        descricao: action.notificacao.descricao,
        excluido: action.notificacao.excluido,
        id: action.notificacao.id,
        notificacoesFiltros: action.notificacao.notificacoesFiltros,
        taxaAbertura: action.notificacao.taxaAbertura,
        titulo: action.notificacao.titulo,
        automatic: action.notificacao.automatic,
        totalNotificacoesUsuarios: action.notificacao.totalNotificacoesUsuarios
      }
    case 'NOTIFICACAO_UPDATED':
      return state
    case 'NOTIFICACAO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        automatic: state.automatic
      }
    case 'NOTIFICACAO_CLEAR_ALL':
      return {
        ...state,
        notificacao: []
      }
    case 'NOTIFICACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OPEN_MODAL_NOTIFICACAO_FILTROS_ITENS':
      return {
        ...state,
        openModalNotificacaoFiltrosItens: action.open
      }
    case 'OPEN_MODAL_NOTIFICACAO_TESTES':
      return {
        ...state,
        openModalNotificacaoTestes: action.open
      }
    case 'OPEN_MODAL_NOTIFICACAO_USUARIOS':
      return {
        ...state,
        openModalNotificacaoUsuarios: action.open
      }
    case 'NOTIFICACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'NOTIFICACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
