const initialState = {
  isLoading: false,
  totalRegistros: 0,
  checklistOperacao: [],
  open: false,
  id: 0,
  nome: '',
  instrucao: '',
  tipo: '',
  periodicidade: '',
  idCategoriaInsumo: '',
  todasLojas: false,
  checklistsOperacaoLojas: [],
  checklistsOperacaoItens: [],
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function checklistOperacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CHECKLIST_OPERACAO':
      return {
        ...state,
        checklistOperacao: action.checklistOperacao,
        totalRegistros: action.totalRegistros
      }
    case 'CHECKLIST_OPERACAO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        nome: action.nome,
        instrucao: action.instrucao,
        tipo: action.tipo,
        periodicidade: action.periodicidade,
        idCategoriaInsumo: action.idCategoriaInsumo,
        todasLojas: action.todasLojas,
        checklistsOperacaoLojas: action.checklistsOperacaoLojas,
        checklistsOperacaoItens: action.checklistsOperacaoItens,
        excluido: action.excluido,
      }
    case 'CHECKLIST_OPERACAO_UPDATED':
      return state
    case 'CHECKLIST_OPERACAO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'CHECKLIST_OPERACAO_CLEAR_ALL':
      return {
        ...state,
        checklistOperacao: []
      }
    case 'CHECKLIST_OPERACAO_CLEAR_LOJAS':
      return {
        ...state,
        checklistsOperacaoLojas: []
      }
    case 'CHECKLIST_OPERACAO_CLEAR_ITENS':
      return {
        ...state,
        checklistsOperacaoItens: []
      }
    case 'CHECKLIST_OPERACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CHECKLIST_OPERACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CHECKLIST_OPERACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
