import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment'
import { clienteAction, fidelizacaoAction, lojaAction, produtoAction, trocaAction, totvsVendaAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalClientesAdicionarTrocasManualmente = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const [chave, setChave] = React.useState('');
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  let now = moment();

  const formRef = React.createRef(null);
  const { match: { params } } = props;

  const loja = useSelector((state) => state.loja.loja)

  const { openModalClientesAdicionarTrocasManualmente, isLoading } = useSelector(
    (state) => state.cliente
  )

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  //const { produto } = useSelector(
  //  (state) => state.produto
  //)

  const { produto } = useSelector(
    (state) => state.produtoSelect
  )

  const options = produto.map(u => ({ id: u.value, nome: u.text, pontos: u.pontos, idTotvs: u.idTotvs }));

  useEffect(() => {
    if (props.troca.idLoja != undefined && props.troca.idLoja !== 0 && props.troca.idLoja !== '') {
      dispatch(produtoAction.getProdutoByLojaSelect(props.troca.idLoja))
    } else if (idLoja != 0 && idLoja != '') {
      dispatch(produtoAction.getProdutoByLojaSelect(idLoja));
    }
  }, [])

  useEffect(() => {
    setValue(null);
    setInputValue('');
    if (props.troca.idLoja != undefined && props.troca.idLoja !== 0 && props.troca.idLoja !== '') {
      dispatch(produtoAction.getProdutoByLojaSelect(props.troca.idLoja))
    } else if (idLoja != 0 && idLoja != '') {
      dispatch(produtoAction.getProdutoByLojaSelect(idLoja));
    }
  }, [props.troca.idLoja, idLoja])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selectedProduto = produto.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        produtoAction.changeDetailsProduto(
          selectedProduto[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleClose = () => {
    dispatch(clienteAction.openModalClientesAdicionarTrocasManualmente(false))
  }

  const handleChange = (prop) => (event) => {
    dispatch(trocaAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {
    event.preventDefault()


    let payload = {
      IdProduto: value ? value.idTotvs : 0,
      IdProdutoBD: value ? value.id : 0, 
      IdCliente: parseInt(params.id),
      Pontos: value ? value.pontos : 0,
      IdLoja: (idLoja != 0 && idLoja != '') ? idLoja : props.troca.idLoja
    }

    dispatch(trocaAction.createTroca(payload))
    dispatch(trocaAction.clear())
    dispatch(clienteAction.openModalClientesAdicionarTrocasManualmente(false))

  }

  useEffect(() => {
    dispatch(trocaAction.clear())
  }, [])

  useEffect(() => {
    dispatch(lojaAction.getLojaSelect())
  }, [])

  return (
    <Dialog
      open={openModalClientesAdicionarTrocasManualmente}
      onClose={handleClose}
      aria-labelledby="xTroca"
      maxWidth="sm"
      fullWidth
    >
      <ValidatorForm
        className={classes.form}
        ref={formRef}
        id="formAdicionarTrocasManualmente"
        onSubmit={(event) => handleSubmit(event)}
      >
        <DialogTitle id="form-dialog-title">
          Preencha as informações para realizar a troca
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', paddingTop: 0, marginTop: 0 }}>
          <Grid container spacing={1}>
            <Grid item md={5} xs={5}>
              <SelectValidator
                className={classes.textField}
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                variant="outlined"
                id="idLoja"
                label="Loja *"
                value={idLoja != 0 && idLoja != '' ? idLoja : props.troca.idLoja}
                onChange={handleChange('idLoja')}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                displayEmpty
                labelId="idLoja"
                disabled
              >
                <MenuItem disabled value="">
                  <em>Selecione uma loja *</em>
                </MenuItem>
                {!isEmpty(loja) &&
                  undefined !== loja &&
                  loja.length &&
                  loja.map((row, index) => (
                    <MenuItem key={index} value={row.value}>
                      {row.text}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>
            <Grid item md={6} xs={6}>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                margin="normal"
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                disabled={(idLoja == 0 || idLoja == '') && (props.troca.idLoja == undefined || props.troca.idLoja == 0 || props.troca.idLoja == '')}
                defaultValue={null}
                getOptionLabel={option => option.nome ? option.nome : ''}
                renderInput={(params) => <TextField {...params} label="Selecione um produto *" variant="outlined" fullWidth />}
                style={{ width: 312, marginTop: 16 }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            style={{ padding: 5 }}
            spacing={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                {isLoading
                  ? 'Realizando troca...'
                  : 'Realizar troca'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

ModalClientesAdicionarTrocasManualmente.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalClientesAdicionarTrocasManualmentePage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ModalClientesAdicionarTrocasManualmente))
)
export { connectedModalClientesAdicionarTrocasManualmentePage as ModalClientesAdicionarTrocasManualmente }
