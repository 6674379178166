import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp';
import { TextValidator } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import { certificadoAction } from '../../_actions'

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    display: 'none',
  },
}))

const FilePicker = ({ fileType, value, onChangeFile, download }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const downloadFile = async () => {
    dispatch(download(value))
  }

  return (
    <>
      <Grid>
        <Input
          id="arquivo"
          className={classes.input}
          onChange={onChangeFile}
          type="file"
          inputProps={{
            accept: fileType
          }}
          formControl
          margin="normal"
        />
        <label htmlFor="arquivo">
          <Button variant="contained" color="primary" component="span">
            Arquivo
          </Button>
        </label>
      </Grid>
      <Grid style={{ width: '100%' }}>
        <TextValidator
          disabled
          fullWidth
          margin="normal"
          label="Nome Arquivo *"
          value={value}
          variant="outlined"
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
          InputProps={
            download && value ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle download"
                    onClick={downloadFile}
                    download
                  >
                    <GetAppIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
            : null
          }
        />
      </Grid>
    </>
  )
}

export default FilePicker
