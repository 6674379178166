import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  Input,
  MenuItem
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { metaAction, lojaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import SaveIcon from '@material-ui/icons/Save'
import { EditorHeader, Page } from 'components'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import TipoMeta from '../../utils/enums/tipoMeta'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const MetaEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, meta, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idLoja, setIdLoja] = useState(0);
  const [textLoja, setTextLoja] = useState('');
  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const lojas = useSelector((state) => state.loja.loja)
  const [filteredLojas, setFilteredLojas] = useState([]);

  useEffect(() => {
    if (params.id) {
      dispatch(metaAction.getMetaById(params.id))
    } else {
      dispatch(metaAction.clear())
    }

    dispatch(lojaAction.getLojaSelect())

  }, []);

  useEffect(() => {

  }, [ready, props.meta.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(metaAction.onChangeProps(prop, event))
  }

  const handleSelectChangeState = prop => event => {
    setIdLoja(event.target.value);
    setTextLoja(event.nativeEvent.target.innerText);
  };

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      tipoMeta: props.meta.tipoMeta,
      dataInicio: moment(props.meta.dataInicio).format("yyyy-MM-DD"),
      dataTermino: moment(props.meta.dataTermino).format("yyyy-MM-DD"),
      valor: props.meta.valor,
      metasLojas: props.meta.metasLojas
    }

    if (params.id) {
      dispatch(metaAction.editMetaInfo(params.id, payload, true))
    } else {
      dispatch(metaAction.createMeta(payload))
    }
  };

  const handleAddOnList = e => {

    if (props.meta.metasLojas.filter(ts => ts.idLoja == parseInt(idLoja)).length > 0) {
      toast.error('Erro ao adicionar! Esta loja já existe!')
    }
    else {

      if (idLoja != '' && idLoja != '0') {
        props.meta.metasLojas.push({ 'idLoja': idLoja, 'idMeta': props.meta.id, 'loja': { 'nome': textLoja } });
        forceUpdate(n => !n);
        setIdLoja(0);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos uma loja!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {
    props.meta.metasLojas = props.meta.metasLojas.filter(ts => ts.idLoja != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.meta.metasLojas && props.meta.metasLojas.length > 0) {
      if (props.meta.metasLojas.filter(fl => fl.idLoja == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Meta'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Metas - Editar Meta'
          : 'Gerência de Metas - Nova Meta'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formMeta"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Metas"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Meta' tabela='Meta' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Tipo de Meta *"
                        labelId="tipoMeta"
                        errorMessages={['Campo obrigatório!']}
                        value={props.meta.tipoMeta}
                        onChange={handleChange('tipoMeta')}
                        name="tipoMeta"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        id="tipoMeta"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Tipo de Meta *</em>
                        </MenuItem>
                        <MenuItem value={0}>Participação</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="valor"
                        label="Valor *"
                        margin="normal"
                        type="number"
                        onChange={handleChange('valor')}
                        value={props.meta.valor || 0}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        inputProps={props.meta.tipoMeta == Object.values(TipoMeta).indexOf(TipoMeta.PARTICIPACAO) ? {
                          min: 0,
                          max: 100,
                          step: 0.01
                        } : { step: 0.01 }}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        id="dataInicio"
                        InputLabelProps={{ shrink: true }}
                        label="Data Início *"
                        onChange={handleChange('dataInicio')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        margin="normal"
                        value={props.meta.dataInicio ? moment(props.meta.dataInicio).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        id="dataTermino"
                        label="Data Término *"
                        onChange={handleChange('dataTermino')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        margin="normal"
                        value={props.meta.dataTermino ? moment(props.meta.dataTermino).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Divider />
                  </Grid>
                  <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idLoja"
                        label="Lojas vinculadas"
                        value={idLoja}
                        onChange={handleSelectChangeState('idLoja')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idLoja"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Loja *</em>
                        </MenuItem>
                        {!isEmpty(lojas) &&
                          undefined !== lojas &&
                          lojas.length &&
                          lojas.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px' }} name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          {(props.meta && props.meta.metasLojas && props.meta.metasLojas.length > 0 ?
                            props.meta.metasLojas.map(n => {
                              return (
                                (n.id !== '' ?
                                  <Button color="default" onClick={() => handleRemoveOnList(n.idLoja)} key={'button' + n.id}>
                                    <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                      {(n.loja ? n.loja.nome : n.idLoja)}
                                    </Badge>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                  : null)
                              );
                            })
                            :
                            null
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/Metas')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.meta.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.meta.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.meta.isLoading ? 'Atualizando meta...' : 'Atualizar') : (props.meta.isLoading ? 'Salvando meta...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

MetaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedMetaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(MetaEdit))
)
export { connectedMetaEditPage as MetaEdit }
