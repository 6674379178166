import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const totvsInsumoUnidadeAction = {
  getTotvsInsumoUnidade,
  getTotvsInsumoUnidadeSelect,
  getTotvsInsumoUnidadeSelectTotvsInsumo,
  getTotvsInsumoUnidadeFornecedor,
  getTotvsInsumoUnidadeVisualizacao,
  getTotvsInsumoUnidadeArquivo,
  addTotvsInsumoUnidade,
  getTotvsInsumoUnidadeById,
  changeDetailsTotvsInsumoUnidade,
  onChangeProps,
  editTotvsInsumoUnidadeInfo,
  editTotvsInsumoUnidadesDetails,
  createTotvsInsumoUnidade,
  deleteTotvsInsumoUnidadeById,
  postImportarPrecosPadrao,
  clear,
  clearTotvsInsumoUnidadesFornecedores,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getTotvsInsumoUnidade(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosUnidades' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumoUnidadesList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsTotvsInsumoUnidade(totvsInsumoUnidade) {
  return (dispatch) => {
    if (totvsInsumoUnidade) {
      dispatch(onChangeDetailsTotvsInsumoUnidade(totvsInsumoUnidade))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsTotvsInsumoUnidade(totvsInsumoUnidade) {
  return [
    {
      type: 'CHANGE_DETAILS_TOTVS_INSUMO_UNIDADE',
      id: totvsInsumoUnidade.id,
      nome: totvsInsumoUnidade.nome
    }
  ]
}


function getTotvsInsumoUnidadeSelect() {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosUnidades/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumoUnidadesList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getTotvsInsumoUnidadeSelectTotvsInsumo(id) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosUnidades/select/totvsInsumo/' + id;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumoUnidadesList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getTotvsInsumoUnidadeFornecedor(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumosUnidades/fornecedor' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumoUnidadesList(response.data, response.data.length))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTotvsInsumoUnidadeVisualizacao(id) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosUnidades/visualizacao/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTotvsInsumoUnidadesDetails(response.data))
    })
  }
}

function getTotvsInsumoUnidadeArquivo(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumosUnidades/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumoUnidadesList(response.data.items, response.data.totalRows))
        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, "insumos_unidades.csv");
        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')
        dispatch(notIsLoading())
        dispatch(clear())
        getTotvsInsumoUnidade()
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createTotvsInsumoUnidade(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumosUnidades/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createTotvsInsumoUnidadeInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/insumosUnidades')
        } else {
          toast.err('Oops! Erro ao cadastrar Unidade de Insumo! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar Unidade de Insumo! 😥')
        return err.response;
      })
  }
}


function getTotvsInsumoUnidadeById(id) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosUnidades/admin/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTotvsInsumoUnidadesDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editTotvsInsumoUnidadeInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'totvsInsumosUnidades/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedTotvsInsumoUnidadeInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/insumosUnidades')
          }
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar Unidade de Insumo! 😥')
        return err.response;
      })
  }
}

function deleteTotvsInsumoUnidadeById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumosUnidades/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTotvsInsumoUnidadesDetails())
      dispatch(getTotvsInsumoUnidade(filterModel))
      dispatch(notIsLoading())
    })
  }
}

function postImportarPrecosPadrao() {
  return (dispatch) => {
    const id = toast.loading("Importando preços padrão do Bling...")
    let apiEndpoint = 'totvsInsumosUnidades/importarBling/precosPadrao';
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.statusText == 'OK' || response.request.statusText == 'OK' || response.request.status == 200) {
          toast.update(id, { render: "Feito! Preços padrão do bling importados com sucesso! 😎", autoClose: 5000, type: "success", isLoading: false });
        }
      }).catch((err) => {
        toast.dismiss(id);
        toast.error('Oops! Erro ao importar Preços! 😥')
        return err.response;
      });
  }
}

export function addTotvsInsumoUnidade(payload) {
  return [{ type: 'ADD_TOTVS_INSUMO_UNIDADE', totvsInsumoUnidade: payload }, clear()]
}

export function changeTotvsInsumoUnidadesList(totvsInsumoUnidade, totalRows) {
  return {
    type: 'FETCHED_ALL_TOTVS_INSUMO_UNIDADE',
    totvsInsumoUnidade: totvsInsumoUnidade,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_CLEAR'
  }
}

export function clearTotvsInsumoUnidadesFornecedores() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_CLEAR_TOTVS_INSUMO_UNIDADES_FORNECEDORES'
  }
}

export function clearAll() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTotvsInsumoUnidadesDetails(totvsInsumoUnidade) {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_DETAIL',
    id: totvsInsumoUnidade.id,
    nome: totvsInsumoUnidade.nome,
    idTotvsInsumo: totvsInsumoUnidade.idTotvsInsumo,
    codigoExterno: totvsInsumoUnidade.codigoExterno,
    idExterno: totvsInsumoUnidade.idExterno,
    caminhoImagem: totvsInsumoUnidade.caminhoImagem,
    pesoLiquido: totvsInsumoUnidade.pesoLiquido,
    preco: totvsInsumoUnidade.preco,
    idCategoriaInsumo: totvsInsumoUnidade.idCategoriaInsumo,
    totvsInsumo: totvsInsumoUnidade.totvsInsumo,
    idUnidade: totvsInsumoUnidade.idUnidade,
    unidade: totvsInsumoUnidade.unidade,
    unidadeContagem: totvsInsumoUnidade.unidadeContagem,
    quantidadeUnidade: totvsInsumoUnidade.quantidadeUnidade,
    contagem: totvsInsumoUnidade.contagem,
    idUnidadeContagem: totvsInsumoUnidade.idUnidadeContagem,
    quantidadeUnidadeContagem: totvsInsumoUnidade.quantidadeUnidadeContagem,
    categoriaInsumo: totvsInsumoUnidade.categoriaInsumo,
    observacoes: totvsInsumoUnidade.observacoes,
    marcaObrigatoria: totvsInsumoUnidade.marcaObrigatoria,
    pedidoMinimo: totvsInsumoUnidade.pedidoMinimo,
    pedidoMultiplo: totvsInsumoUnidade.pedidoMultiplo,
    idUnidadeIntegracao: totvsInsumoUnidade.idUnidadeIntegracao,
    unidadeIntegracao: totvsInsumoUnidade.unidadeIntegracao,
    pesoLiquidoIntegracao: totvsInsumoUnidade.pesoLiquidoIntegracao,
    excluido: totvsInsumoUnidade.excluido,
    totvsInsumosUnidadesFornecedores: totvsInsumoUnidade.totvsInsumosUnidadesFornecedores
  }
}

export function updatedTotvsInsumoUnidadeInfo() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_UPDATED'
  }
}

export function createTotvsInsumoUnidadeInfo() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_CREATED_SUCCESSFULLY'
  }
}

export function deleteTotvsInsumoUnidadesDetails() {
  return {
    type: 'DELETED_TOTVS_INSUMO_UNIDADE_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_NOTISLOADING'
  }
}

export function isModalOpen() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_ISMODALOPEN'
  }
}

export function notIsModalOpen() {
  return {
    type: 'TOTVS_INSUMO_UNIDADE_NOTISMODALOPEN'
  }
}
