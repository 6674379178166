import React, { useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { clienteAction } from '../../src/_actions'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContentCliente = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [debounceTerm] = useDebounce(term, 1000);

  const { cliente } = useSelector(
    (state) => state.cliente
  )

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const handleExportar = () => {
    let filterModel;

    const fetchData = (page, limit, term) => {

      filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
      };

      dispatch(clienteAction.getClienteArquivo(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

    dispatch(clienteAction.getCliente(filterModel, true));
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
        <AccessControl
          rule={props.rule} //permissão necessária para acessar conteúdo
          yes={() => (
            <Grid item>
              <Grid item style={{
                display: 'flex',
                alignItems: 'right',
                flexDirection: 'row',
                textAlgin: 'right',
                justifyContent: 'right',
                spacing: 5
              }}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => handleExportar()}
                >
                  EXPORTAR
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </div>
  )
}

TitleContentCliente.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string
}
export default TitleContentCliente
