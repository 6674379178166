const initialState = {
  isLoading: false,
  totalRegistros: 0,
  tipoAjustePontuacao: [],
  id: 0,
  nome: '', 
  excluido: false
}

export function tipoAjustePontuacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TIPO_AJUSTE_PONTUACAO':
      return {
        ...state,
        tipoAjustePontuacao: action.tipoAjustePontuacao,
        totalRegistros: action.totalRegistros
      }
    case 'TIPO_AJUSTE_PONTUACAO_DETAIL':
      return action.tipoAjustePontuacao
    case 'TIPO_AJUSTE_PONTUACAO_UPDATED':
      return state
    case 'TIPO_AJUSTE_PONTUACAO_CLEAR':
      return initialState
    case 'TIPO_AJUSTE_PONTUACAO_CLEAR_ALL':
      return {
        ...state,
        tipoAjustePontuacao: []
      }
    case 'TIPO_AJUSTE_PONTUACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TIPO_AJUSTE_PONTUACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TIPO_AJUSTE_PONTUACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
