import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const dashboardCompraAction = {
  getDashboardComprasHome,
  getDashboardLoja,
  getDashboardLojaRankingPrecoMedio,
  clear
}

function getDashboardComprasHome(lojaId) {
  return (dispatch) => {
    dispatch(request({ lojaId }))
    let apiEndpoint = 'dashboardCompras/loja/home/' + lojaId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request(lojaId) {
    return { type: 'FETCHED_DASHBOARD_COMPRAS_HOME_REQUEST', lojaId }
  }

  function success(dashboardHomeResponse) {
    return {
      type: 'FETCHED_DASHBOARD_COMPRAS_HOME_SUCCESS',
      dashboardHome: dashboardHomeResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_COMPRAS_HOME_FAILURE', error }
  }
}

function getDashboardLoja(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboardCompras/loja' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_COMPRAS_LOJA_REQUEST',
      lojaId
    }
  }

  function success(dashboardLojaResponse) {
    return {
      type: 'FETCHED_DASHBOARD_COMPRAS_LOJA_SUCCESS',
      dashboardLoja: dashboardLojaResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_COMPRAS_LOJA_FAILURE', error }
  }

}

function getDashboardLojaRankingPrecoMedio(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboardCompras/loja/ranking/precoMedio' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_COMPRAS_LOJA_RANKING_PRECO_MEDIO_REQUEST',
      lojaId
    }
  }

  function success(rankingPrecoMedio, totalRankingPrecoMedio) {
    return {
      type: 'FETCHED_DASHBOARD_COMPRAS_LOJA_RANKING_PRECO_MEDIO_SUCCESS',
      rankingPrecoMedio: rankingPrecoMedio,
      totalRankingPrecoMedio: totalRankingPrecoMedio
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_COMPRAS_LOJA_RANKING_PRECO_MEDIO_FAILURE', error }
  }

}
export function clear() {
  return {
    type: 'DASHBOARD_COMPRAS_CLEAR'
  }
}
