import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const respostaChecklistOperacaoAction = {
  getRespostaChecklistOperacao,
  getRespostaChecklistOperacaoBySemana,
  getRespostasChecklistOperacaoBySemana,
  addRespostaChecklistOperacao,
  getRespostaChecklistOperacaoById,
  onChangeProps,
  changeChecklistOperacoesList,
  editRespostaChecklistOperacaoInfo,
  editChecklistOperacoesDetails,
  createRespostaChecklistOperacao,
  createRespostaChecklistOperacaoSemana,
  createRespostaChecklistOperacaoInfo,
  deleteRespostaChecklistOperacaoById,
  clear,
  clearAll,
  clearRespostasChecklistsOperacaoProducao,
  clearRespostasChecklistsOperacaoTemperatura,
  clearRespostasChecklistsOperacaoCheck,

}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'nome',
  Direction: 'asc'
};

function getRespostaChecklistOperacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'respostasChecklistsOperacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeChecklistOperacoesList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getRespostaChecklistOperacaoBySemana(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'respostasChecklistsOperacoes/semana' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeChecklistOperacoesList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getRespostasChecklistOperacaoBySemana(payload, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'respostasChecklistsOperacoes/semana/ids';
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        let list = []
        if (response.data) {
          const respostasChecklistMap = response.data.respostasChecklistMap

          for (const key in respostasChecklistMap) {
            list.push(respostasChecklistMap[key])
          }
          dispatch(isLoading())
          dispatch(changeChecklistOperacoesList(response.data, null))
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err.response);
        return err.response;
      })
  }
}

function createRespostaChecklistOperacao(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'respostasChecklistsOperacoes/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createRespostaChecklistOperacaoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(getRespostaChecklistOperacaoById(ret.key))
          dispatch(clear())
          dispatch(clearAll())
          dispatch(notIsLoading())
          history.push('/respostasChecklistsOperacoes')
        } else {
          toast.err('Oops! Erro ao cadastrar checklist de operação! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar checklist de operação! 😥')
        return err.response;
      })
  }
}

function createRespostaChecklistOperacaoSemana(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'respostasChecklistsOperacoes/semana'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          //var ret = JSON.parse(response.request.responseText);
          dispatch(createRespostaChecklistOperacaoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          //dispatch(getRespostaChecklistOperacaoById(ret.key))
          dispatch(clear())
          dispatch(clearAll())
          dispatch(notIsLoading())
          history.push('/checklistsOperacoesCards/categorias')
        } else {
          toast.err('Oops! Erro ao cadastrar checklist de operação! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar checklist de operação! 😥')
        return err.response;
      })
  }
}

function getRespostaChecklistOperacaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'respostasChecklistsOperacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editChecklistOperacoesDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'respostasChecklistsOperacaoProducao' || props == 'respostasChecklistsOperacaoTemperatura' || props == 'respostasChecklistsOperacaoCheck') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function editRespostaChecklistOperacaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'respostasChecklistsOperacoes/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedRespostaChecklistInfo())
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/respostasChecklistsOperacoes')
        }// retorna para a lista e atualiza        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar checklist de operação! 😥')
        return err.response;
      })
  }
}

function deleteRespostaChecklistOperacaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'respostasChecklistsOperacoes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteChecklistOperacoesDetails())
      dispatch(getRespostaChecklistOperacao(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addRespostaChecklistOperacao(payload, props) {
  return [{ type: 'ADD_RESPOSTA_CHECKLIST_OPERACAO', respostaChecklistOperacao: payload },
  clear()
  ]
}

function changeChecklistOperacoesList(respostaChecklistOperacao, totalRows) {
  return {
    type: 'FETCHED_ALL_RESPOSTA_CHECKLIST_OPERACAO',
    respostaChecklistOperacao: respostaChecklistOperacao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_ALL'
  }
}

export function clearRespostasChecklistsOperacaoProducao() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_RESPOSTASCHECKLIST_OPERACAOSOPERACAOPRODUCAO'
  }
}

export function clearRespostasChecklistsOperacaoTemperatura() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_RESPOSTASCHECKLIST_OPERACAOSOPERACAOTEMPERATURA'
  }
}

export function clearRespostasChecklistsOperacaoCheck() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_RESPOSTASCHECKLIST_OPERACAOSOPERACAOCHECK'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}


export function editChecklistOperacoesDetails(respostaChecklistOperacao) {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_DETAIL',
    id: respostaChecklistOperacao.id,
    idChecklistOperacao: respostaChecklistOperacao.idChecklistOperacao,
    dataHora: respostaChecklistOperacao.dataHora,
    nomeChecklistOperacao: respostaChecklistOperacao.nomeChecklistOperacao,
    nomeUsuario: respostaChecklistOperacao.nomeUsuario,
    idUsuario: respostaChecklistOperacao.idUsuario,
    idLoja: respostaChecklistOperacao.idLoja,
    excluido: respostaChecklistOperacao.excluido,
    respostasChecklistsOperacaoProducao: respostaChecklistOperacao.respostasChecklistsOperacaoProducao,
    respostasChecklistsOperacaoTemperatura: respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura,
    respostasChecklistsOperacaoCheck: respostaChecklistOperacao.respostasChecklistsOperacaoCheck,
  }
}

export function updatedRespostaChecklistInfo() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_UPDATED'
  }
}

export function createRespostaChecklistOperacaoInfo() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_CREATED_SUCCESSFULLY'
  }
}

export function deleteChecklistOperacoesDetails() {
  return {
    type: 'DELETED_RESPOSTA_CHECKLIST_OPERACAO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'RESPOSTA_CHECKLIST_OPERACAO_NOTISLOADING'
  }
}
