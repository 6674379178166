const initialState = {
  isLoading: false,
  isModalOpen: false,
  totalRegistros: 0,
  compra: [],
  open: false,
  id: 0,
  idLoja: '',
  idFornecedorFilial: '',
  dataHora: '',
  cnpjFornecedor: '',
  nomeFornecedor: '',
  natureza: '',
  valor: 0,
  valorDesconto: 0,
  linkXml: '',
  linkPdf: '',
  chave: '',
  excluido: '',
  loja: null,
  fornecedorFilial: null,
  comprasProdutos: [],
  ncmInfoDetail: {},
  gtinInfoDetail: {},
  page: 0,
  limit: 10,
  order: 'dataHora',
  direction: 'desc',
  term: '',
  naoClassificadas: false
}

export function compra(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_COMPRA':
      return {
        ...state,
        compra: action.compra,
        totalRegistros: action.totalRegistros
      }
    case 'COMPRA_DETAIL':
      return {
        ...state,
        isLoading: false,
        isModalOpen: false,
        id: action.id,
        idLoja: action.idLoja,
        idFornecedorFilial: action.idFornecedorFilial,
        dataHora: action.dataHora,
        cnpjFornecedor: action.cnpjFornecedor,
        nomeFornecedor: action.nomeFornecedor,
        natureza: action.natureza,
        valor: action.valor,
        valorDesconto: action.valorDesconto,
        linkXml: action.linkXml,
        linkPdf: action.linkPdf,
        chave: action.chave,
        excluido: action.excluido,
        loja: action.loja,
        fornecedorFilial: action.fornecedorFilial,
        comprasProdutos: action.comprasProdutos
      }
    case 'COMPRA_UPDATED':
      return state
    case 'COMPRA_CLEAR':
      return {
        ...initialState,
        ncmInfoDetail: state.ncmInfoDetail,
        gtinInfoDetail: state.gtinInfoDetail,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        naoClassificadas: state.naoClassificadas
      }
    case 'COMPRA_CLEAR_NCM':
      return {
        ...state,
        ncmInfoDetail: ''
      }
    case 'COMPRA_CLEAR_GTIN':
      return {
        ...state,
        gtinInfoDetail: ''
      }
    case 'COMPRA_CLEAR_ALL':
      return {
        ...state,
        compra: []
      }
    case 'COMPRA_CLEAR_PEDIDOSCOMPRASPRODUTOS':
      return {
        ...state,
        comprasProdutos: []
      }
    case 'COMPRA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'COMPRA_HANDLE_ON_CHANGE_PRODUTOS':
      return {
        ...state,
        comprasProdutos: Object.assign([action.props], action.value)
      }
    case 'FETCHED_COMPRA_NCM_INFO_DETAIL':
      return {
        ...state,
        ncmInfoDetail: action.ncmInfoDetail,
        isLoading: false
      }
    case 'FETCHED_COMPRA_GTIN_INFO_DETAIL':
      return {
        ...state,
        gtinInfoDetail: action.gtinInfoDetail,
        isLoading: false
      }
    case 'COMPRA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'COMPRA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'COMPRA_ISMODALOPEN':
      return {
        ...state,
        isModalOpen: true
      }
    case 'COMPRA_NOTISMODALOPEN':
      return {
        ...state,
        isModalOpen: false
      }
    default:
      return state
  }
}
