import { crudService } from '../_services'

export const blingAction = {
  getBlingProduto,
  getBlingProdutoLoja,
  getBlingProdutoByCodigo,  
  editBlingDetails,
  clear,
  clearAll,
}

function getBlingProduto() {
  return (dispatch) => {
    let apiEndpoint = 'bling/produtos';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeBlingList(response.data, response.data.length))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getBlingProdutoLoja(idLoja) {
  return (dispatch) => {
    let apiEndpoint = 'bling/produtos/loja/' + idLoja;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeBlingList(response.data, response.data.length))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getBlingProdutoByCodigo(codigo) {
  return (dispatch) => {
    let apiEndpoint = 'bling/produto/' + codigo
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editBlingDetails(response.data[0].produto))
    })
  }
}

export function changeBlingList(bling, totalRows) {
  return {
    type: 'FETCHED_ALL_BLING',
    bling: bling,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'BLING_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'BLING_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'BLING_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editBlingDetails(bling) {
  return {
    type: 'BLING_DETAIL',
    id: bling.id,
    codigo: bling.codigo,
    descricao: bling.descricao,
    tipo: bling.tipo,
    situacao: bling.situacao,
    unidade: bling.unidade,
    preco: bling.preco,
    precoCusto: bling.precoCusto,
    descricaoCurta: bling.descricaoCurta,
    descricaoComplementar: bling.descricaoComplementar,
    dataInclusao: bling.dataInclusao,
    dataAlteracao: bling.dataAlteracao,
    imageThumbnail: bling.imageThumbnail,
    urlVideo: bling.urlVideo,
    nomeFornecedor: bling.nomeFornecedor,
    codigoFabricante: bling.codigoFabricante,
    marca: bling.marca,
    class_fiscal: bling.class_fiscal,
    cest: bling.cest,
    origem: bling.origem,
    idGrupoProduto: bling.idGrupoProduto,
    linkExterno: bling.linkExterno,
    observacoes: bling.observacoes,
    grupoProduto: bling.grupoProduto,
    garantia: bling.garantia,
    descricaoFornecedor: bling.descricaoFornecedor,
    idFabricante: bling.idFabricante,
    categoria: bling.categoria,
    pesoLiq: bling.pesoLiq,
    pesoBruto: bling.pesoBruto,
    estoqueMinimo: bling.estoqueMinimo,
    estoqueMaximo: bling.estoqueMaximo,
    gtin: bling.gtin,
    gtinEmbalagem: bling.gtinEmbalagem,
    larguraProduto: bling.larguraProduto,
    alturaProduto: bling.alturaProduto,
    profundidadeProduto: bling.profundidadeProduto,
    unidadeMedida: bling.unidadeMedida,
    itensPorCaixa: bling.itensPorCaixa,
    volumes: bling.volumes,
    localizacao: bling.localizacao,
    crossdocking: bling.crossdocking,
    condicao: bling.condicao,
    freteGratis: bling.freteGratis,
    producao: bling.producao,
    dataValidade: bling.dataValidade,
    spedTipoItem: bling.spedTipoItem,
    imagem: bling.imagem,
    quantidadePedidoMedio: bling.quantidadePedidoMedio,
    vendas7dias: bling.vendas7dias,
    vendas14dias: bling.vendas14dias
  }
}

export function isLoading() {
  return {
    type: 'BLING_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'BLING_NOTISLOADING'
  }
}
