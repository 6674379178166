import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core'

import { Page } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',

      Content: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  }
}))

const AccessDenied = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Page
      className={classes.root}
      // title="Error 404"
    >
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        403: O acesso a essa página foi negado.
      </Typography>
      <Typography align="center" variant="subtitle2">
        Você não tem autorização para acessar essa página
      </Typography>
      <div className={classes.imageContainer}>
        <img
          alt="error-403"
          className={classes.image}
          src="/images/coruja-error.svg"
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/principal"
          variant="outlined"
        >
          Voltar para home
        </Button>
      </div>
    </Page>
  )
}

export default AccessDenied
