import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  icon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    top: '3px'
  }
}))

const Footer = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/*
      <Typography variant="body2">
        &copy; {1900 + new Date().getYear()}, feito com{' '}
        <Favorite className={classes.icon} size="small" color="primary" /> por{' '}
        <a href="https://fidelidade.quierocafe.com.br" rel="noreferrer" target="_blank">
          Quiero Café
        </a>{' '}
      </Typography>
       <Typography variant="caption">
        Created with love for the environment. By designers and developers who
        love to work together in offices!
      </Typography> */}
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

export default Footer
