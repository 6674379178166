import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { relatorioAction } from '../../_actions'
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { toast } from 'react-toastify'
import { history } from '../../utils'

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 25
  },
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const RelatorioChecklist = (props) => {

  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;

  var fornecedorAnterior = '';
  var cor = '#E6E6E6';

  const [headerHeight, setHeaderHeight] = useState(0);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
      history.push('/checklists');
    }

    let filterModel = {
      IdChecklist: params.id,
      IdLoja: idLoja
    };

    //alerts.waiting();
    dispatch(relatorioAction.getRelatorioChecklist(filterModel));

    setTimeout(() => {
      setReady(true);
    }, 1100);

  }, []);

  useEffect(() => {
    //console.log('props.relatorio', props.relatorio)
  }, [props.relatorio]);

  const onRendered = () => {
  };

  return (
    ((ready && props.relatorio != null && props.relatorio.relatorio != null && props.relatorio.relatorio.checklist != null) ?
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer height="100%" width="100%" >
          <Document onRender={onRendered}>
            <Page orientation="landscape" size="A4" style={styles.page} wrap={true} >

              <Header
                title={props.relatorio.relatorio.checklist.descricao}
                setHeaderHeight={setHeaderHeight}
              />

              <View style={[styles.row, { marginTop: 40 }]} key={0} >
                <View style={[styles.header, { width: 150 }]}>
                  <Text>Fornecedor</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>Item</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>Unidade de Contagem</Text>
                </View>
                <View style={[styles.header, { width: 70 }]}>
                  <Text></Text>
                </View>
                <View style={[styles.header, { width: 70 }]}>
                  <Text></Text>
                </View>
                <View style={[styles.header, { width: 70 }]}>
                  <Text></Text>
                </View>
                <View style={[styles.header, { width: 70 }]}>
                  <Text></Text>
                </View>
                <View style={[styles.header, { width: 70 }]}>
                  <Text></Text>
                </View>
              </View>

              {
                props.relatorio.relatorio.checklistsTotvsInsumosUnidades.map(n => {

                  if (fornecedorAnterior !== n.fornecedorPadrao) {
                    if (cor == '#FFFFFF') {
                      cor = '#E6E6E6'
                    }
                    else {
                      cor = '#FFFFFF'
                    }
                  }

                  var retorno = (
                    <View style={[styles.row, { backgroundColor: cor }]} key={n.id} >
                      <View style={[styles.cell, { width: 150 }]}>
                        <Text>{n.fornecedorPadrao}</Text>
                      </View>
                      <View style={[styles.cell, { width: 200 }]}>
                        <Text>{n.nome}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{((n.quantidadeUnidadeContagem) > 1 ? (n.unidadeContagem + ' C/ ' + n.quantidadeUnidadeContagem) : n.unidadeContagem)}</Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                      <View style={[styles.cell, { width: 35 }]}>
                        <Text></Text>
                      </View>
                    </View>
                  );

                  fornecedorAnterior = n.fornecedorPadrao;
                  return retorno;
                })
              }
             <Footer />
            </Page>
          </Document>
        </PDFViewer>
      </div>
      :
      null
    )
  );

}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioChecklistPage = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(RelatorioChecklist));
export { connectedRelatorioChecklistPage as RelatorioChecklist };
