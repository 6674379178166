import { crudService } from '../_services/'
import { lojaAction } from '_actions'
import { toast } from 'react-toastify'

export const usuarioLojaAction = {
  changeDetailsUsuarioLoja,
  getUsuarioLoja,
  getUsuarioLojas,
  addUsuarioByEmail,
  editUsuarioLojasDetails,
  deleteUsuarioById,
  clearSelected,
  clear,
  clearAll
}

function addUsuarioByEmail(payload) {
  return (dispatch) => {
    dispatch(request({ payload }))
    let apiEndpoint = 'usuariosLojas/email'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        toast.success('Usuário adicionado com sucesso! 😎')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(data) {
    return { type: 'USUARIO_LOJA_ADD_REQUEST', data }
  }

  function success(data) {
    return {
      type: 'USUARIO_LOJA_ADD_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'USUARIO_LOJA_ADD_FAILURE', error }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'usuariosEstabelecimentos/' + id
    crudService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(id) {
    return { type: 'USUARIO_LOJA_DELETE_REQUEST', id }
  }

  function success(data) {
    return {
      type: 'USUARIO_LOJA_DELETE_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'USUARIO_LOJA_DELETE_FAILURE', error }
  }
}

function changeDetailsUsuarioLoja(usuarioLoja) {
  return (dispatch) => {
    if (usuarioLoja) {
      dispatch(onChangeDetailsUsuarioLoja(usuarioLoja))
    } else {
      clear()
    }
  }
}

function getUsuarioLojas(usuarioLoja) {
  return (dispatch) => {
    dispatch(changeUsuarioLojasList(usuarioLoja))
    if (
      undefined !== usuarioLoja &&
      usuarioLoja.length === 1
    ) {
      dispatch(editUsuarioLojasDetails(usuarioLoja[0]))
    }
  }
}

function getUsuarioLoja(usuarioLoja) {
  return (dispatch) => {
    dispatch(editUsuarioLojasDetails(usuarioLoja))
  }
}

export function addUsuarioLoja(payload) {
  return [
    { type: 'ADD_USUARIO_LOJA', usuarioLoja: payload },
    clear()
  ]
}

export function changeUsuarioLojasList(usuarioLoja) {
  return {
    type: 'FETCHED_ALL_USUARIO_LOJA',
    usuarioLoja: usuarioLoja
  }
}

export function clear() {
  return {
    type: 'USUARIO_LOJA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'USUARIO_LOJA_CLEAR_ALL'
  }
}

export function clearSelected() {
  return {
    type: 'USUARIO_LOJA_CLEAR_SELECTED'
  }
}


export function onChangeDetailsUsuarioLoja(usuarioLoja) {
  return [
    {
      type: 'CHANGE_DETAILS_USUARIO_LOJA',
      id: usuarioLoja.id,
      idUsuario: usuarioLoja.idUsuario,
      idLoja: usuarioLoja.idLoja,
      excluido: usuarioLoja.excluido,
      loja: usuarioLoja.loja
    },
    lojaAction.getLojaById(
      usuarioLoja.idLoja
    )
  ]
}

export function editUsuarioLojasDetails(usuarioLoja) {
  return {
    type: 'USUARIO_LOJA_DETAIL',
    id: usuarioLoja.id,
    idUsuario: usuarioLoja.idUsuario,
    idLoja: usuarioLoja.idLoja,
    excluido: usuarioLoja.excluido,
    loja: usuarioLoja.loja
  }
}

export function updatedUsuarioLojaInfo() {
  return {
    type: 'USUARIO_LOJA_UPDATED'
  }
}

export function createUsuarioLojaInfo() {
  return {
    type: 'USUARIO_LOJA_CREATED_SUCCESSFULLY'
  }
}
