import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Divider, Drawer, withStyles } from '@material-ui/core'
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone'
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone'
import EmojiEventsTwoToneIcon from '@material-ui/icons/EmojiEventsTwoTone'
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone'
import FilterFramesTwoToneIcon from '@material-ui/icons/FilterFramesTwoTone';
import FreeBreakfastTwoToneIcon from '@material-ui/icons/FreeBreakfastTwoTone';
import KitchenTwoToneIcon from '@material-ui/icons/KitchenTwoTone';
import LaptopMacTwoToneIcon from '@material-ui/icons/LaptopMacTwoTone'
import LocalConvenienceStoreTwoToneIcon from '@material-ui/icons/LocalConvenienceStoreTwoTone'
import LocalOfferTwoToneIcon from '@material-ui/icons/LocalOfferTwoTone'
import LocalMallTwoToneIcon from '@material-ui/icons/LocalMallTwoTone';
import MessageTwoToneIcon from '@material-ui/icons/MessageTwoTone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/StoreTwoTone'
import SwapHorizTwoToneIcon from '@material-ui/icons/SwapHorizTwoTone';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone'
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import { authActions } from '../../../../_actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Profile, SidebarNav } from './components'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';

const styles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  nav: {
    marginBottom: theme.spacing(1)
  }
})

const Sidebar = ({ staticContext, ...props }) => {

  const { open, variant, onClose, className, ...rest } = props
  const dispatch = useDispatch()
  const { user } = props
  const [openMenuPedidos, setOpenMenuPedidos] = useState(false);
  const [openMenuFidelidades, setOpenMenuFidelidades] = useState(false);
  const [openMenuCadastros, setOpenMenuCadastros] = useState(false);
  const [openMenuRelatorios, setOpenMenuRelatorios] = useState(false);
  const [isClickedOptionSubmenu, setIsClickedOptionSubMenu] = useState(false);

  const logout = () => {
    dispatch(authActions.logout())
  }
  const { classes } = props

  const isDisabled = useSelector((state) => {
    return false
  })

  const handleClickPedidos = () => {
    setOpenMenuPedidos(!openMenuPedidos);
  };

  const handleClickFidelidades = () => {
    setOpenMenuFidelidades(!openMenuFidelidades);
  };

  const handleClickRelatorios = () => {
    setOpenMenuRelatorios(!openMenuRelatorios);
  };

  const handleClickCadastros = () => {
    setOpenMenuCadastros(!openMenuCadastros);
  };

  const pages = [
    {
      title: 'Home',
      href: '/home',
      icon: <LaptopMacTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Fidelidade',
      clicked: openMenuFidelidades,
      href: '',
      rule: 'dashboard',
      icon: <FreeBreakfastTwoToneIcon />,
      onClick: () => {
        handleClickFidelidades()
      }
    }
  ]

  const pagesFidelidadesItens = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Clientes',
      href: '/clientes',
      rule: 'dashboard.view',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <PeopleOutlineTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Dashboard',
      href: '/dashboard',
      rule: 'dashboard.view',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <DashboardTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Notificações',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/notificacoes',
      rule: 'notificacoes',
      icon: <NotificationsIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Ranking',
      href: '/ranking',
      rule: 'relatorios.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <EmojiEventsTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Trocas',
      href: '/relatorioTrocas',
      rule: 'relatorios.admin',
      icon: <SwapHorizTwoToneIcon />
    },
  ]

  const pagesPedidos = [
    {
      disabled: isDisabled,
      title: 'Pedidos',
      clicked: openMenuPedidos,
      href: '',
      rule: 'pedidosCompras',
      icon: <StorefrontTwoToneIcon />,
      onClick: () => {
        handleClickPedidos()
      }
    }
  ]

  const pagesPedidosItens = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Pedidos de Compra',
      href: '/pedidosCompras',
      divider: true,
      rule: 'pedidosCompras',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <ShoppingBasketTwoToneIcon />,
    },
    {
      disabled: isDisabled,
      title: 'Checklists de Operação',
      clickedSub: isClickedOptionSubmenu,
      href: '/checklistsOperacoes',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'checklistsOperacoes',
      icon: <CheckBoxTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Fornecedores',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/fornecedores',
      rule: 'fornecedores',
      icon: <LocalMallTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Insumos',
      href: '/insumos',
      rule: 'totvsInsumos',
      icon: <KitchenTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Listas de Preços',
      clickedSub: isClickedOptionSubmenu,
      href: '/listasPrecos',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'listasPrecos',
      icon: <LocalOfferTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Mensagens/Alertas',
      href: '/mensagensPedidosCompras',
      rule: 'mensagensPedidosCompras',
      icon: <MessageTwoToneIcon />
    },  
    {
      disabled: isDisabled,
      title: 'Planilhas de Contagem',
      clickedSub: isClickedOptionSubmenu,
      href: '/checklists',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'checklists',
      icon: <CheckBoxTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Fornecedores de Insumos',
      href: '/relatorioTotvsInsumosUnidadesFornecedores',
      rule: 'relatorios.admin',
      icon: <FilterFramesTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Nível de Serviço',
      href: '/relatorioNivelServico',
      rule: 'relatorios.admin',
      icon: <AttachMoneyTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Venda de Insumos',
      href: '/relatorioVendasInsumos',
      rule: 'relatorios.admin',
      icon: <AttachMoneyTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Respostas de Checklists de Operação',
      clickedSub: isClickedOptionSubmenu,
      href: '/respostasChecklistsOperacoes',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'respostasChecklists',
      icon: <CheckBoxTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Unidades de Insumos',
      href: '/insumosUnidades',
      rule: 'totvsInsumosUnidades',
      icon: <KitchenTwoToneIcon />
    }
  ]

  const pagesCadastros = [
    {
      disabled: isDisabled,
      title: 'Cadastros',
      href: '',
      rule: 'pedidosCompras',
      clicked: openMenuCadastros,
      icon: <AddBoxTwoToneIcon />,
      onClick: () => {
        handleClickCadastros()
      }
    }
  ]

  //const pagesRelatorios = [
  //  {
  //    disabled: isDisabled,
  //    title: 'Relatórios',
  //    href: '',
  //    rule: 'relatorios',
  //    clicked: openMenuRelatorios,
  //    icon: <AssignmentTwoToneIcon />,
  //    onClick: () => {
  //      handleClickRelatorios()
  //    }
  //  }
  //]

  //const pagesRelatoriosItens = [
   
  //]

  const pagesCadastrosItens = [
    {
      disabled: isDisabled,
      title: 'Grupos',
      href: '/grupos',
      rule: 'grupos',
      clickedSub: isClickedOptionSubmenu,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <StoreIcon />
    },
    {
      title: 'Lojas',
      href: `/lojas`,
      clickedSub: isClickedOptionSubmenu,
      rule: 'lojas',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <LocalConvenienceStoreTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Permissões',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/permissoes',
      rule: 'permissoes',
      icon: <VpnKeyIcon />
    },
    {
      disabled: isDisabled,
      title: 'Produtos',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/produtos',
      rule: 'produtos',
      icon: <ShoppingBasketTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Unidades',
      href: '/unidades',
      rule: 'unidades',
      clickedSub: isClickedOptionSubmenu,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <KitchenTwoToneIcon />
    },
    {
      title: 'Usuários',
      href: '/usuarios',
      rule: 'usuarios',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      divider: true,
      icon: <PersonIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      title: 'Parâmetros',
      clickedSub: isClickedOptionSubmenu,
      href: '/parametros',
      rule: 'parametros',
      icon: <BuildTwoToneIcon />
    }
  ]

  const pagesSair = [
    {
      title: 'Sair',
      href: '/login',
      icon: <ExitToAppTwoToneIcon />,
      onClick: () => {
        logout()
      }
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <PerfectScrollbar>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />
          <SidebarNav pages={pages} style={{ paddingTop: 0 }} />
          {openMenuFidelidades == false ?
            <Divider className={classes.divider} style={{ paddingBottom: 0, marginTop: 0 }} />
            : null}
          <div style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}>
            <Collapse in={openMenuFidelidades} timeout="auto">
              <List component="div" disablePadding>
                <ListItem style={{ paddingTop: 0 }}>
                  <SidebarNav pages={pagesFidelidadesItens} />
                </ListItem>
              </List>
            </Collapse>
          </div>
          {openMenuFidelidades ?
            <Divider className={classes.divider} />
            : null}
          <SidebarNav pages={pagesPedidos} style={{ paddingTop: 0 }} />
          <div>
            <Collapse in={openMenuPedidos} timeout="auto">
              <List component="div" disablePadding>
                <ListItem style={{ paddingTop: 0 }}>
                  <SidebarNav pages={pagesPedidosItens} />
                </ListItem>
              </List>
            </Collapse>
          </div>
          <SidebarNav pages={pagesCadastros} style={{ paddingTop: 0 }} />
          <div>
            <Collapse in={openMenuCadastros} timeout="auto">
              <List component="div" disablePadding>
                <ListItem style={{ paddingTop: 0 }}>
                  <SidebarNav pages={pagesCadastrosItens} />
                </ListItem>
              </List>
            </Collapse>
          </div>
          {/*<SidebarNav pages={pagesRelatorios} style={{ paddingTop: 0 }} />*/}
          {/*<div>*/}
          {/*  <Collapse in={openMenuRelatorios} timeout="auto">*/}
          {/*    <List component="div" disablePadding>*/}
          {/*      <ListItem style={{ paddingTop: 0 }}>*/}
          {/*        <SidebarNav pages={pagesRelatoriosItens} />*/}
          {/*      </ListItem>*/}
          {/*    </List>*/}
          {/*  </Collapse>*/}
          {/*</div>*/}
          <SidebarNav pages={pagesSair} />
        </div>
      </PerfectScrollbar>
    </Drawer>
  )
}


Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}
const connectedSidebarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Sidebar))
)
export { connectedSidebarPage as Sidebar }
