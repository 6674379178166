import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { mensagemAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import ModuloMensagem from '../../utils/enums/moduloMensagem'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Mensagem = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { mensagem } = props.mensagem
  const { totalRegistros } = props.mensagem
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.mensagem);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc'
    };

    dispatch(mensagemAction.getMensagem(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(mensagemAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(mensagemAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.mensagem.page = 0;
    dispatch(mensagemAction.onChangeProps('term', fakeEvent))
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Mensagem?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.mensagem.isLoading,
      confirmationText: props.mensagem.isLoading ? 'Excluindo Mensagem...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(mensagemAction.deleteMensagemById(id))
    })
  }

  return (
    <Page className={classes.root} title="Mensagem">
      <AccessControl
        rule={'mensagens'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContent
              rule={'mensagens.create'}
              length={undefined !== mensagem && mensagem.length > 0 ? mensagem.length : 0 }
              subTitle={'GERÊNCIA DE MENSAGEM'}
              title={'Lista de Mensagens'}
              href={'/mensagem'}
            />
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Título</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Módulo</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== mensagem && mensagem.length
                    ? mensagem.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.titulo}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {(n.tipo == 'A' ? 'Alerta' : (n.tipo == 'U' ? 'Urgência' : (n.tipo == 'I' ? 'Informativo' : '')))}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.modulo !== null ? Object.values(ModuloMensagem).at(n.modulo) : ''}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'mensagens.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/mensagem/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Editar'}>
                                      <Tooltip>
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'mensagens.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Mensagem.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    mensagem: state.mensagem
  }
}
const connectedMensagemPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Mensagem))
)
export { connectedMensagemPage as Mensagem }
