import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Input,
  Grid
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import { deepOrange } from '@material-ui/core/colors';
import { Skeleton } from '@material-ui/lab'
import { dashboardAction } from '../../../../_actions'
import { useDebounce } from 'use-debounce';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '100%',
    justifyContent: 'center'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  meses: {
    textAlign: 'center',
    padding: theme.spacing(1)
  }
}))

const TopProdutosTrocadosChartCard = (props) => {

  const dispatch = useDispatch()

  const { className, idLoja, dataInicio, dataFim, ...rest } = props
  const classes = useStyles()
  const { isLoadingTrocas } = useSelector((state) => state.dashboard)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('total');
  const [direction] = useState('desc');

  const topProdutosTrocas = useSelector((state) => (state.dashboard ? state.dashboard.topProdutosTrocas : undefined))
  const totalRegistros = useSelector((state) => (state.dashboard ? state.dashboard.totalProdutosTrocas : undefined))

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: idLoja,
        DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        DataFim: moment(dataFim).format("YYYY-MM-DD")
      };

      dispatch(dashboardAction.getDashboardLojaProdutosTrocas(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, dataInicio, dataFim, idLoja]);

  useEffect(() => {

  }, [totalRegistros]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <IconButton size="small">
        //     <RefreshIcon />
        //   </IconButton>
        // }
        title="PRODUTOS MAIS TROCADOS"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {isLoadingTrocas ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton
                animation="wave"
                style={{ marginTop: -120 }}
                height={500}
                width={570}
              />
            </div>
          ) : (
            <>
              <Grid item xl={12} lg={12} md={12} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={handleChange('term')}
                  fullWidth
                />
              </Grid>
              <br />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ paddingLeft: 64 }}>Nome</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {undefined !== topProdutosTrocas && null !== topProdutosTrocas && topProdutosTrocas.length > 0
                      ? topProdutosTrocas.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row" align="right">
                              {n.caminhoImagem ?
                                <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <Avatar
                                    alt={n.nome}
                                    className={classes.avatar}
                                    src={n.caminhoImagem}
                                  />
                                  <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>{n.nome} </span>
                                </Grid>
                                :
                                <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <Avatar
                                    sx={{ bgcolor: deepOrange[500] }}
                                    alt={n.nome}
                                    src="/broken-image.jpg"
                                  />
                                  <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>{n.nome} </span>
                                </Grid>
                              }
                            </TableCell>
                            <TableCell align="right">
                              {n.total}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalRegistros}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

TopProdutosTrocadosChartCard.propTypes = {
  className: PropTypes.string
}

export default TopProdutosTrocadosChartCard
