import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, totvsInsumoUnidadeAction, totvsInsumoPrecoReferenciaAction, estadoAction, lojaAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Input,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter, Prompt } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import EditIcon from '@material-ui/icons/Edit'
import { Page } from 'components'
import SearchIcon from '@material-ui/icons/Search'
import ConversoesCollapsableRow from './conversoesCollapsableRow.component'
import { apenasNumero, cnpjMask, history } from '../../utils'
import { isEmpty } from 'lodash'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  buttonGrid: {
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingRight: 0
    },
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
      alignItems: 'right',
      textAlign: 'right',
      justifyContent: 'right',
    }
  },
})

const RelatorioPrecos = (props) => {
  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio, isLoading } = props.relatorio
  const { totalRows } = relatorio

  const [loja, setLoja] = useState(0)
  const [tipoPreco, setTipoPreco] = useState(0)

  const [isEditing, setIsEditing] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)

  const [openCancelDialog, setOpenCancelDialog] = useState(false)

  const [direction, setDirection] = useState('desc');
  const [order, setOrder] = useState('divergencia');

  const estados = useSelector(state => state.estado.estado)
  const lojas = useSelector(state => state.loja.loja)

  const totvsInsumoPrecoReferencia = useSelector(state => state.totvsInsumoPrecoReferencia)
  const { idLoja } = useSelector(state => state.usuarioLoja)

  const headCells = [
    { id: 'insumo', sort: false, align: 'left', label: 'Insumo' },
    { id: 'preco', sort: true, align: 'left', label: 'Preço Referência' },
    { id: 'valorUnit', sort: true, align: 'left', label: 'Valor Unitário' },
    { id: 'divergencia', sort: true, align: 'left', label: '% Divergência' },
    { id: 'descricao', sort: false, align: 'left', label: 'Descrição' },
  ];

  useEffect(() => {
    dispatch(relatorioAction.clear());
    dispatch(estadoAction.getEstadoSelect())
    dispatch(lojaAction.getLojaSelect());
  }, [])

  useEffect(() => {
    // Exibe alerta ao recarregar a página com modificações não salvas
    window.onbeforeunload = isModified ? () => true : undefined;
    if (nextLocation && !window.onbeforeunload) {
      history.push(nextLocation);
    }
    return () => {
      window.onbeforeunload = undefined;
    };
  }, [isModified]);

  const handleChangeLoja = (event) => {
    setLoja(event.target.value)
  }
  const handleChangeTipoPreco = (event) => {
    setTipoPreco(event.target.value)
  }

  const handleChange = (prop) => (event) => {
    dispatch(totvsInsumoPrecoReferenciaAction.onChangeProps(prop, event))
  }

  // Funções de Filtragem -------------------------------
  const handleSubmit = (event, newDirection = direction, property = order) => {
    dispatch(relatorioAction.clear());

    if (totvsInsumoPrecoReferencia.idEstado && totvsInsumoPrecoReferencia.dataHoraAtualizacao) {
      let payload = {
        idEstado: totvsInsumoPrecoReferencia.idEstado,
        data: totvsInsumoPrecoReferencia.dataHoraAtualizacao,
        idLoja: loja || 0,
        tipoPreco: tipoPreco || 0,
        direction: newDirection,
        order: property,
      }

      dispatch(relatorioAction.getRelatorioPrecos(payload))
    }
  };

  const handleClean = () => {
    dispatch(totvsInsumoPrecoReferenciaAction.clear());
    dispatch(relatorioAction.clear());

    setLoja(0);
    setTipoPreco(0);
  }

  const handleSort = (event, property) => {
    const isAsc = order === property && direction === 'asc';
    const newDirection = isAsc ? 'desc' : 'asc';

    setDirection(newDirection);
    setOrder(property);

    handleSubmit(event, newDirection, property)
  }
  // ----------------------------------------------------

  // Funções Editar Preços Referência ------------------- 
  const handleChangeEdit = (event) => {
    const edit = !isEditing

    setIsEditing(edit)

    if (isModified) {
      handleSubmit(event)
    }
  }

  const handleChangePreco = (event, n) => {
    const items = props.relatorio?.relatorio.items;

    if (items?.length) {
      const index = items.findIndex(x => x.id == n.id);
      if (index >= 0) {
        const novosPrecosReferencia = items.map(x =>
          x.id == n.id ? { ...x, preco: Number(event.target.value), modified: true } : x
        );

        const newRelatorio = {
          items: novosPrecosReferencia
        }

        setIsModified(true)

        dispatch(relatorioAction.onChangeProps('relatorio', newRelatorio));
      }
    }
  }

  const handleCancelDialogOpen = () => {
    if (isModified) {
      setOpenCancelDialog(true)
    } else {
      setIsEditing(false)
    }
  };

  const handleCancelDialogClose = (confirm) => {
    setOpenCancelDialog(false)

    if (confirm) {
      handleChangeEdit()
      setIsModified(false)
      setIsEditing(false)
    }
  };

  const handleSave = () => {
    if (isModified) {
      const modifiedItems = relatorio.items.filter(i => i.modified == true)

      dispatch(totvsInsumoPrecoReferenciaAction.editTotvsInsumoPrecoReferenciaInfoByRelatorio(modifiedItems))

      setIsModified(false)
      setIsEditing(false)
    }
  }
  // ----------------------------------------------------

  // Funções de Bloqueio de Rota ------------------------
  const handleBlockedNavigation = (location) => {
    if (!isModified) return true;
    setNextLocation(location.pathname);
    setOpenDialog(true);
    return false;
  };

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      setIsModified(false);
    }
  };
  // ----------------------------------------------------

  return (
    <Page className={classes.root} title="Relatório Preços">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioPrecos"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Preços'}
                    />
                    <Grid container spacing={2} style={{ alignItems: 'center' }}>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <SelectValidator
                          id="estado"
                          label="Estado *"
                          name="estado"
                          value={totvsInsumoPrecoReferencia.idEstado || ''}
                          onChange={handleChange('idEstado')}
                          inputProps={{
                            name: "Estado *",
                            id: "Estado",
                            shrink: true
                          }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        >
                          <MenuItem disabled value="">
                            <em>Estado *</em>
                          </MenuItem>
                          {!isEmpty(estados) &&
                            undefined !== estados &&
                            estados.length &&
                            estados.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Data de Referência *"
                          type="date"
                          value={totvsInsumoPrecoReferencia.dataHoraAtualizacao}
                          onChange={handleChange('dataHoraAtualizacao')}
                          id="dataReferencia"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          id="loja"
                          label="Loja"
                          name="loja"
                          value={loja || ''}
                          onChange={handleChangeLoja}
                          inputProps={{
                            name: "Loja",
                            id: "loja",
                            shrink: true
                          }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        >
                          <MenuItem disabled value="">
                            <em>Loja</em>
                          </MenuItem>
                          {!isEmpty(lojas) &&
                            undefined !== lojas &&
                            lojas.length &&
                            lojas.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <SelectValidator
                          id="tipoPreco"
                          label="Tipo Preço *"
                          name="tipoPreco"
                          value={tipoPreco}
                          onChange={handleChangeTipoPreco}
                          inputProps={{
                            name: "Tipo Preço *",
                            id: "tipoPreco",
                            shrink: true
                          }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        >
                          <MenuItem disabled value="">
                            <em>Tipo Preço *</em>
                          </MenuItem>
                          <MenuItem value={0}>Preço Médio</MenuItem>
                          <MenuItem value={1}>Preço Máximo</MenuItem>
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlign: 'right',
                        justifyContent: 'right',
                        gap: 10
                      }}>
                        <Button
                          color="default"
                          onClick={() => handleClean()}
                          variant="contained"
                        >
                          Limpar
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={props.relatorio.isLoading}
                          startIcon={<SearchIcon />}
                          endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                        >
                          {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map(cell => {
                          return (
                            <TableCell
                              key={cell.id}
                              align={cell.align}
                              sortDirection={order === cell.id ? direction : false}
                            >
                              {
                                cell.sort ?
                                  <TableSortLabel
                                    active={order === cell.id}
                                    direction={order === cell.id ? direction : 'asc'}
                                    onClick={(event) => handleSort(event, cell.id)}
                                    disabled={isEditing}
                                  >
                                    {cell.label}
                                  </TableSortLabel>
                                : cell.label
                              }
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relatorio && relatorio.items?.length ?
                        relatorio.items.map(n => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell>
                                {n.insumo || ''}
                              </TableCell>
                              <TableCell>
                                {isEditing ?
                                  <ValidatorForm
                                    id="formRelatorioPrecos"
                                    onSubmit={(event) => handleSubmit(event)}
                                  >
                                    <TextValidator
                                      className={classes.textField}
                                      margin="normal"
                                      fullWidth
                                      type="number"
                                      value={n.preco ?? ''}
                                      onChange={(event) => handleChangePreco(event, n)}
                                      id="preco"
                                      validators={['required']}
                                      errorMessages={['Campo obrigatório!']}
                                    />
                                  </ValidatorForm>
                                  :
                                  n.preco ? 'R$' + n.preco.toLocaleString('pt-br', { minimumFractionDigits: 4 }) : ''
                                }
                              </TableCell>
                              <TableCell>
                                {n.valorUnit ? 'R$' + n.valorUnit.toLocaleString('pt-br', { minimumFractionDigits: 4 }) + '/' + n.abreviacao : ''}
                              </TableCell>
                              <TableCell>
                                {n.divergencia ? n.divergencia.toLocaleString('pt-br', { minimumFractionDigits: 2 }) + '%' : ''}
                              </TableCell>
                              <TableCell>
                                {n.descricao || ''}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>

                {relatorio && relatorio.items?.length ?
                  <Grid container lg={12} md={12} xl={12} xs={12} spacing={1} className={classes.buttonGrid}>
                    {!isEditing ?
                      <Grid item>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          startIcon={<EditIcon />}
                          onClick={handleChangeEdit}
                        >
                          Atualizar Preços
                        </Button>
                      </Grid>
                    :
                      <>
                        <Grid item>
                          <Button
                            color="default"
                            variant="contained"
                            onClick={handleCancelDialogOpen}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={!isModified}
                            onClick={handleSave}
                          >
                            Salvar
                          </Button>
                        </Grid>
                      </>          
                    }
                  </Grid>
                : null}
              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />

      <Dialog
        open={openCancelDialog}
        onClose={() => handleCancelDialogClose(false)}
      >
        <DialogTitle>{"CANCELAR OPERAÇÃO"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você tem certeza que gostaria de cancelar essa operação?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelDialogClose(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => handleCancelDialogClose(true)} variant="contained" color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Prompt
        when={isModified}
        message={handleBlockedNavigation}
      />
      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>{"Você quer sair dessa página?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Talvez as alterações feitas por você não sejam salvas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleDialogClose(true)} variant="contained" color="primary">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </Page >
  )
}
RelatorioPrecos.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioPrecosPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioPrecos))
)
export { connectedRelatorioPrecosPage as RelatorioPrecos }
