import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { PrivateRouteWithLayout } from './components'
import {
  Main as MainLayout,
  MainPrincipal as MainPrincipalLayout,
  Minimal as MinimalLayout,
  MainModules as MainModulesLayout
} from './layouts'

import {
  Dashboard as DashboardView,
  DashboardCompras as DashboardComprasView,
  Error404 as Error404View,
  Login as LoginView,
  Banner as BannerView,
  BannerEdit as BannerEditView,
  Campanha as CampanhaView,
  CampanhaEdit as CampanhaEditView,
  CampanhaParticipacoes as CampanhaParticipacoesView,
  CategoriaCompra as CategoriaCompraView,
  CategoriaCompraEdit as CategoriaCompraEditView,
  Certificado as CertificadoView,
  CertificadoEdit as CertificadoEditView,
  Checklist as ChecklistView,
  ChecklistEdit as ChecklistEditView,
  ChecklistOperacao as ChecklistOperacaoView,
  ChecklistOperacaoCard as ChecklistOperacaoCardView,
  ChecklistOperacaoCategoriasInsumosCards as ChecklistOperacaoCategoriasInsumosCardsView,
  ChecklistOperacaoEdit as ChecklistOperacaoEditView,
  ClienteEdit as ClienteEditView,
  Cliente as ClienteView,
  Compra as CompraView,
  CompraEdit as CompraEditView,
  EmailVerification as EmailVerificationView,
  Fornecedor as FornecedorView,
  FornecedorEdit as FornecedorEditView,
  PasswordReset as PasswordResetView,
  PasswordResetSuccess as PasswordResetSuccessView,
  Grupo as GrupoView,
  GrupoEdit as GrupoEditView,
  Insumo as InsumoView,
  InsumoEdit as InsumoEditView,
  InsumoUnidade as InsumoUnidadeView,
  InsumoUnidadeEdit as InsumoUnidadeEditView,
  ListaPreco as ListaPrecoView,
  ListaPrecoEdit as ListaPrecoEditView,
  Loja as LojaView,
  LojaStatus as LojaStatusView,
  LojaEdit as LojaEditView,
  Mensagem as MensagemView,
  MensagemEdit as MensagemEditView,
  Meta as MetaView,
  MetaEdit as MetaEditView,
  Notificacao as NotificacaoView,
  NotificacaoEdit as NotificacaoEditView,
  Parametro as ParametroView,
  ParametroEdit as ParametroEditView,
  PedidoCompra as PedidoCompraView,
  PedidoCompraEdit as PedidoCompraEditView,
  PedidoCompraRecebidoEdit as PedidoCompraRecebidoEditView,
  Principal as PrincipalView,
  InsumoUnidadeVisualizacao as InsumoUnidadeVisualizacaoView,
  Produto as ProdutoView,
  ProdutoCompraEdit as ProdutoCompraEditView,
  ProdutoEdit as ProdutoEditView,
  RelatorioVendaInsumo as RelatorioVendaInsumoView,
  RelatorioVendaInsumoPrint as RelatorioVendaInsumoPrintView,
  RelatorioChecklist as RelatorioChecklistView,
  RelatorioChecklistDiario as RelatorioChecklistDiarioView,
  RelatorioChecklistOperacao as RelatorioChecklistOperacaoView,
  RelatorioCPVABC as RelatorioCPVABCView,
  RelatorioCompras as RelatorioComprasView,
  RelatorioConversoes as RelatorioConversoesView,
  RelatorioLog as RelatorioLogView,
  RelatorioNivelServico as RelatorioNivelServicoView,
  RelatorioRanking as RelatorioRankingView,
  RelatorioTotvsInsumoUnidadeFornecedor as RelatorioTotvsInsumoUnidadeFornecedorView,
  RelatorioPonto as RelatorioPontoView,
  RelatorioPrecos as RelatorioPrecosView,
  RelatorioDivergenciasFichaTecnica as RelatorioDivergenciasFichaTecnicaView,
  RelatorioTroca as RelatorioTrocaView,
  RelatorioTrocaPrint as RelatorioTrocaPrintView,
  RespostaChecklistOperacao as RespostaChecklistOperacaoView,
  RespostaChecklistOperacaoEdit as RespostaChecklistOperacaoEditView,
  Sorteio as SorteioView,
  SorteioEdit as SorteioEditView,
  Unidade as UnidadeView,
  UnidadeEdit as UnidadeEditView,
  Usuario as UsuarioView,
  UsuarioEdit as UsuarioEditView,
  HomeFidelidade as HomeFidelidadeView,
  HomeCustos as HomeCustosView,
  Ajuda as AjudaView
} from './views'

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        component={CategoriaCompraView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/categoriasCompras"
      />
      <PrivateRouteWithLayout
        component={CategoriaCompraEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/categoriaCompra"
      />
      <PrivateRouteWithLayout
        component={CategoriaCompraEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/categoriaCompra/:id"
      />
      <PrivateRouteWithLayout
        component={CertificadoView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/certificados"
      />
      <PrivateRouteWithLayout
        component={CertificadoEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/certificado"
      />
      <PrivateRouteWithLayout
        component={CertificadoEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/certificado/:id"
      />
      <PrivateRouteWithLayout
        component={ChecklistOperacaoView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/checklistsOperacoes"
      />
      <PrivateRouteWithLayout
        component={ChecklistOperacaoCardView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/checklistsOperacoesCards/categoria/:id"
      />
      <PrivateRouteWithLayout
        component={ChecklistOperacaoCategoriasInsumosCardsView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/checklistsOperacoesCards/categorias"
      />
      <PrivateRouteWithLayout
        component={ChecklistOperacaoEditView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/checklistOperacao"
      />
      <PrivateRouteWithLayout
        component={ChecklistOperacaoEditView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/checklistOperacao/:id"
      />
      <PrivateRouteWithLayout
        component={HomeFidelidadeView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/fidelidade/home"
      />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/fidelidade/dashboard"
      />
      <PrivateRouteWithLayout
        component={FornecedorView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/fornecedores"
      />
      <PrivateRouteWithLayout
        component={FornecedorEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/fornecedor/:id"
      />
      <PrivateRouteWithLayout
        component={FornecedorEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/fornecedor"
      />
      <PrivateRouteWithLayout
        component={BannerView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/banners"
      />
      <PrivateRouteWithLayout
        component={BannerEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/banner"
      />
      <PrivateRouteWithLayout
        component={BannerEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/banner/:id"
      />
      <PrivateRouteWithLayout
        component={CampanhaView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/campanhas"
      />
      <PrivateRouteWithLayout
        component={CampanhaEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/campanha"
      />
      <PrivateRouteWithLayout
        component={CampanhaEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/campanha/:id"
      />
      <PrivateRouteWithLayout
        component={CampanhaParticipacoesView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/campanha/participacoes/:id"
      />
      <PrivateRouteWithLayout
        component={CampanhaEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/campanha"
      />
      <PrivateRouteWithLayout
        component={SorteioView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/sorteios"
      />
      <PrivateRouteWithLayout
        component={SorteioEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/sorteio/:id"
      />
      <PrivateRouteWithLayout
        component={SorteioEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/sorteio"
      />
      <PrivateRouteWithLayout
        component={GrupoView}
        type="G" //GERÊNCIA
        exact
        layout={MainModulesLayout}
        path="/grupos"
      />
      <PrivateRouteWithLayout
        component={GrupoEditView}
        type="G" //GERÊNCIA
        exact
        layout={MainModulesLayout}
        path="/grupo"
      />
      <PrivateRouteWithLayout
        component={GrupoEditView}
        type="G" //GERÊNCIA
        exact
        layout={MainModulesLayout}
        path="/grupo/:id"
      />
      {/*<PrivateRouteWithLayout*/}
      {/*  component={HomeView}*/}
      {/*  exact*/}
      {/*  layout={MainModulesLayout}*/}
      {/*//  type={changeValueType}*/}
      {/*  path="/home"*/}
      {/*/>*/}
      <PrivateRouteWithLayout
        component={InsumoView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/insumos"
      />
      <PrivateRouteWithLayout
        component={InsumoEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/insumo"
      />
      <PrivateRouteWithLayout
        component={InsumoEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/insumo/:id"
      />
      <PrivateRouteWithLayout
        component={InsumoUnidadeView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/insumosUnidades"
      />
      <PrivateRouteWithLayout
        component={InsumoUnidadeEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/insumoUnidade"
      />
      <PrivateRouteWithLayout
        component={InsumoUnidadeEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/insumoUnidade/:id"
      />
      <PrivateRouteWithLayout
        component={UnidadeView}
        layout={MainModulesLayout}
        type="G"
        exact
        path="/unidades"
      />
      <PrivateRouteWithLayout
        component={UnidadeEditView}
        layout={MainModulesLayout}
        type="G"
        exact
        path="/unidade"
      />
      <PrivateRouteWithLayout
        component={UnidadeEditView}
        layout={MainModulesLayout}
        type="G"
        exact
        path="/unidade/:id"
      />
      <PrivateRouteWithLayout
        component={LojaView}
        exact
        type="G" //GERÊNCIA
        layout={MainModulesLayout}
        path="/lojas"
      />
      <PrivateRouteWithLayout
        component={LojaStatusView}
        type="G" //GERÊNCIA
        exact
        layout={MainModulesLayout}
        path="/lojas/status"
      />
      <PrivateRouteWithLayout
        component={LojaEditView}
        type="G" //GERÊNCIA
        exact
        layout={MainModulesLayout}
        path="/loja"
      />
      <PrivateRouteWithLayout
        component={LojaEditView}
        type="G" //GERÊNCIA
        exact
        layout={MainModulesLayout}
        path="/loja/:id"
      />
      <PrivateRouteWithLayout
        component={ListaPrecoView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/listasPrecos"
      />
      <PrivateRouteWithLayout
        component={ListaPrecoEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/listaPreco"
      />
      <PrivateRouteWithLayout
        component={ListaPrecoEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/listaPreco/:id"
      />
      <PrivateRouteWithLayout
        component={ChecklistView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/checklists"
      />
      <PrivateRouteWithLayout
        component={RelatorioTrocaPrintView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/relatorioTroca"
      />
      <PrivateRouteWithLayout
        component={RelatorioVendaInsumoPrintView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/relatorioVendaInsumo"
      />
      <PrivateRouteWithLayout
        component={ChecklistEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/checklist"
      />
      <PrivateRouteWithLayout
        component={ChecklistEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/checklist/:id"
      />
      <PrivateRouteWithLayout
        component={MensagemView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/mensagens"
      />
      <PrivateRouteWithLayout
        component={MensagemEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/mensagem"
      />
      <PrivateRouteWithLayout
        component={MensagemEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/mensagem/:id"
      />
      <PrivateRouteWithLayout
        component={MetaView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/metas"
      />
      <PrivateRouteWithLayout
        component={MetaEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/meta/:id"
      />
      <PrivateRouteWithLayout
        component={MetaEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/meta"
      />
      <PrivateRouteWithLayout
        component={PrincipalView}
        exact
        layout={MainPrincipalLayout}
        path="/principal"
      />
      <PrivateRouteWithLayout
        component={PrincipalView}
        exact
        layout={MainPrincipalLayout}
        path="/"
      />
      <PrivateRouteWithLayout
        component={RelatorioRankingView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/ranking"
      />
      <PrivateRouteWithLayout
        component={RelatorioDivergenciasFichaTecnicaView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/relatorioDivergenciasFichaTecnica"
      />
      <PrivateRouteWithLayout
        component={RelatorioVendaInsumoView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/relatorioVendasInsumos"
      />
      <PrivateRouteWithLayout
        component={RelatorioChecklistView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/relatorioChecklist/:id"
      />
      <PrivateRouteWithLayout
        component={RelatorioChecklistDiarioView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/relatorioChecklistDiario/:id"
      />
      <PrivateRouteWithLayout
        component={RelatorioChecklistOperacaoView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/relatorioChecklistOperacao/:id/:dataInicio/:dataFim"
      />
      <PrivateRouteWithLayout
        component={RelatorioTotvsInsumoUnidadeFornecedorView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/relatorioTotvsInsumosUnidadesFornecedores"
      />
      <PrivateRouteWithLayout
        component={RelatorioNivelServicoView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/relatorioNivelServico"
      />
      <PrivateRouteWithLayout
        component={RelatorioLogView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/relatorioLogs"
      />
      <PrivateRouteWithLayout
        component={RelatorioPontoView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/relatorioPontos"
      />
      <PrivateRouteWithLayout
        component={RelatorioTrocaView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/relatorioTrocas"
      />
      <PrivateRouteWithLayout
        component={RespostaChecklistOperacaoView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/respostasChecklistsOperacoes"
      />
      <PrivateRouteWithLayout
        component={RespostaChecklistOperacaoEditView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/respostaChecklistOperacao"
      />
      <PrivateRouteWithLayout
        component={RespostaChecklistOperacaoEditView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/respostaChecklistOperacao/checklistOperacao/:id"
      />
      <PrivateRouteWithLayout
        component={NotificacaoView}
        exact
        type="F"
        layout={MainModulesLayout}
        path="/notificacoes"
      />
      <PrivateRouteWithLayout
        component={NotificacaoEditView}
        type="F"
        exact
        layout={MainModulesLayout}
        path="/notificacao"
      />
      <PrivateRouteWithLayout
        component={NotificacaoEditView}
        type="F"
        exact
        layout={MainModulesLayout}
        path="/notificacao/:id"
      />
      <PrivateRouteWithLayout
        component={PedidoCompraView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/pedidosCompras"
      />
      <PrivateRouteWithLayout
        component={PedidoCompraEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/pedidoCompra/:id"
      />
      <PrivateRouteWithLayout
        component={PedidoCompraEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/pedidoCompra"
      />
      <PrivateRouteWithLayout
        component={PedidoCompraEditView}
        exact
        layout={MainModulesLayout}
        type="P"
        path="/pedidoCompra"
      />
      {/*<PrivateRouteWithLayout*/}
      {/*  component={InsumoUnidadeVisualizacaoView}*/}
      {/*  exact*/}
      {/*  layout={MainModulesLayout}*/}
      {/*  type="O"*/}
      {/*  path="/pedidoCompra/visualizacao/:id"*/}
      {/*/>*/}
      <PrivateRouteWithLayout
        component={ProdutoView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/produtos"
      />
      <PrivateRouteWithLayout
        component={ProdutoCompraEditView}
        exact
        layout={MainModulesLayout}
        type="O"
        path="/produtoCompra/:id"
      />
      <PrivateRouteWithLayout
        component={ProdutoEditView}
        type="G"
        exact
        layout={MainModulesLayout}
        path="/produto"
      />
      <PrivateRouteWithLayout
        component={ProdutoEditView}
        type="G"
        exact
        layout={MainModulesLayout}
        path="/produto/:id"
      />
      <PrivateRouteWithLayout
        component={UsuarioView}
        type="G"
        exact
        layout={MainModulesLayout}
        path="/usuarios"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        type="G"
        exact
        layout={MainModulesLayout}
        path="/usuario"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        type="G"
        exact
        layout={MainModulesLayout}
        path="/usuario/:id"
      />
      <PrivateRouteWithLayout
        component={ClienteEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/cliente"
      />
      <PrivateRouteWithLayout
        component={ClienteEditView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/cliente/:id"
      />
      <PrivateRouteWithLayout
        component={ClienteView}
        exact
        layout={MainModulesLayout}
        type="F"
        path="/clientes"
      />
      <Route component={LoginView} exact path="/login" />
      <RouteWithLayout
        component={PedidoCompraRecebidoEditView}
        exact
        layout={MinimalLayout}
        path="/pedidoCompra/recebido/:id"
      />
      <Route
        component={AjudaView}
        exact
        path="/ajuda"
      />
      <Route
        component={EmailVerificationView}
        exact
        path="/email-verification"
      />
      <Route
        component={PasswordResetView}
        exact
        path="/password-reset"
      />
      <Route
        component={PasswordResetSuccessView}
        exact
        path="/password-reset-success"
      />
      {/*<PrivateRouteWithLayout*/}
      {/*  component={HomeView}*/}
      {/*  exact*/}
      {/*  layout={MainLayout}*/}
      {/*  path="/"*/}
      {/*/>*/}
      <RouteWithLayout
        component={AjudaView}
        exact
        layout={MinimalLayout}
        path="/ajuda"
      />
      <PrivateRouteWithLayout
        component={ParametroView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/parametros"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/parametro"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainModulesLayout}
        type="G"
        path="/parametro/:id"
      />
      <PrivateRouteWithLayout
        component={HomeCustosView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/compras/home"
      />
      <PrivateRouteWithLayout
        component={DashboardComprasView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/dashboardCompras"
      />
      <PrivateRouteWithLayout
        component={CompraView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/compras"
      />
      <PrivateRouteWithLayout
        component={CompraEditView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/compra/:id"
      />
      <PrivateRouteWithLayout
        component={RelatorioCPVABCView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/relatorioCpvAbc"
      />
      <PrivateRouteWithLayout
        component={RelatorioConversoesView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/relatorioConversoes"
      />
      <PrivateRouteWithLayout
        component={RelatorioComprasView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/relatorioCompras"
      />
      <PrivateRouteWithLayout
        component={RelatorioPrecosView}
        exact
        layout={MainModulesLayout}
        type="C"
        path="/relatorioPrecos"
      />
      <RouteWithLayout
        component={Error404View}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
