import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Input,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { dashboardCompraAction } from '../../../../_actions'
import { history } from 'utils'
import { AccessControl } from 'components/Utils/AccessControl'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '100%',
    justifyContent: 'center'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  meses: {
    textAlign: 'center',
    padding: theme.spacing(1)
  }
}))

const RankingPrecoMedioChartCard = (props) => {

  const dispatch = useDispatch()

  const { className, idLoja, dataInicio, dataFim, idTotvsInsumo, idTotvsInsumoUnidade, noEstado, ...rest } = props
  const classes = useStyles()
  const { isLoadingProdutos } = useSelector((state) => state.dashboardCompra)
  const { unidadeInsumo } = useSelector((state) => state.dashboardCompra.dashboardLoja)
  const { unidadeInsumoUnidade } = useSelector((state) => state.dashboardCompra.dashboardLoja)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('total');
  const [direction] = useState('asc');

  const rankingPrecoMedio = useSelector((state) => (state.dashboardCompra ? state.dashboardCompra.rankingPrecoMedio : undefined))
  const totalRegistros = useSelector((state) => (state.dashboardCompra ? state.dashboardCompra.totalRankingPrecoMedio : undefined))

  const usuarioLoja = useSelector((state) => state.usuarioLoja)

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: idLoja,
        IdTotvsInsumo: idTotvsInsumo,
        IdTotvsInsumoUnidade: idTotvsInsumoUnidade,
        NoEstado: noEstado || false,
        DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        DataFim: moment(dataFim).format("YYYY-MM-DD")
      };

      dispatch(dashboardCompraAction.getDashboardLojaRankingPrecoMedio(filterModel, true));

    };

    if (idTotvsInsumo != 0) {
      fetchData(page, limit, debounceTerm, order, direction);
    }

  }, [page, limit, debounceTerm, order, direction, dataInicio, dataFim, idLoja, idTotvsInsumo, idTotvsInsumoUnidade, noEstado]);

  useEffect(() => {

  }, [totalRegistros]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const viewReport = (id_loja) => {
    const data_inicio = moment(dataInicio).format("YYYY-MM-DD")
    const data_fim = moment(dataFim).format("YYYY-MM-DD")

    window.open(`/relatorioCompras?data_inicio=${data_inicio}&data_fim=${data_fim}&id_insumo=${idTotvsInsumo}&id_loja=${id_loja}`)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <IconButton size="small">
        //     <RefreshIcon />
        //   </IconButton>
        // }
        title="MENOR PREÇO MÉDIO ENTRE AS LOJAS"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {isLoadingProdutos ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Skeleton
                animation="wave"
                style={{ marginTop: -120 }}
                height={500}
                width={570}
              />
            </div>
          ) : (
            <>
              <Grid item xl={12} lg={12} md={12} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={handleChange('term')}
                  fullWidth
                />
              </Grid>
              <br />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Posição</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <AccessControl
                        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <TableCell>Ação</TableCell>
                        )}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {undefined !== rankingPrecoMedio && null !== rankingPrecoMedio && rankingPrecoMedio.length > 0
                      ? rankingPrecoMedio.map((n, i) => {
                        return (
                          <TableRow hover key={n.id}>
                            {usuarioLoja.loja.nome == n.nome ?
                              <TableCell component="th" scope="row">
                                <b>{(i + 1)}º</b>
                              </TableCell>
                              :
                              <TableCell component="th" scope="row">
                                {(i + 1)}º
                              </TableCell>
                            }
                            {usuarioLoja.loja.nome == n.nome ?
                              <TableCell component="th" scope="row" >
                                <b>{n.nome}</b>
                              </TableCell>
                              :
                              <TableCell component="th" scope="row">
                                {n.nome}
                              </TableCell>
                            }
                            {usuarioLoja.loja.nome == n.nome ?
                              <TableCell align="right" >
                                <b>{'R$ ' + parseFloat(n.total).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} / {(unidadeInsumoUnidade ? unidadeInsumoUnidade : (unidadeInsumo ? unidadeInsumo : ''))}</b>
                              </TableCell>
                              :
                              <TableCell align="right">
                                {'R$ ' + parseFloat(n.total).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} / {(unidadeInsumoUnidade ? unidadeInsumoUnidade : (unidadeInsumo ? unidadeInsumo : ''))}
                              </TableCell>
                            }
                            <AccessControl
                              rule={'relatorios.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <TableCell>
                                  <Tooltip title="Visualizar Listagem de Compras">
                                    <IconButton onClick={() => viewReport(n.idLoja)}>
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )}
                            />
                          </TableRow>
                        )
                      })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalRegistros}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

RankingPrecoMedioChartCard.propTypes = {
  className: PropTypes.string
}

export default RankingPrecoMedioChartCard
