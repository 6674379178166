const initialState = {
  isLoading: false,
  totalRegistros: 0,
  totvsInsumo: [],
  open: false,
  id: 0,
  codigo: '',
  nome: '',
  aproveitamento: '',
  embalagem: '',
  idUnidade: 0,
  revisado: false,
  contabilizado: false,
  excluido: false,
  totvsInsumosConversoes: [],
  totvsInsumosPrecosReferencias: [],
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: '',
  showExcluidos: false
}

export function totvsInsumo(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TOTVS_INSUMO':
      return {
        ...state,
        totvsInsumo: action.totvsInsumo,
        totalRegistros: action.totalRegistros
      }
    case 'TOTVS_INSUMO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        codigo: action.codigo,
        nome: action.nome,
        aproveitamento: action.aproveitamento,
        embalagem: action.embalagem,
        idUnidade: action.idUnidade,
        unidade: action.unidade,
        revisado: action.revisado,
        contabilizado: action.contabilizado,
        excluido: action.excluido,
        totvsInsumosConversoes: action.totvsInsumosConversoes,
        totvsInsumosPrecosReferencias: action.totvsInsumosPrecosReferencias
      }
    case 'TOTVS_INSUMO_UPDATED':
      return state
    case 'TOTVS_INSUMO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        showExcluidos: state.showExcluidos
      }
    case 'TOTVS_INSUMO_CLEAR_TOTVS_INSUMOS_FORNECEDORES':
      return {
        ...state,
        totvsInsumosFornecedores: []
      }
    case 'TOTVS_INSUMO_CLEAR_ALL':
      return {
        ...state,
        totvsInsumo: []
      }
    case 'TOTVS_INSUMO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TOTVS_INSUMO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TOTVS_INSUMO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
