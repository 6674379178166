import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction } from '../../_actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import {
  Card,
  CardContent,
  CircularProgress,
  Button,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tab,
  Box,
  Tabs,
  Typography
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import SearchIcon from '@material-ui/icons/Search';
import { Page } from 'components'
import palette from 'theme/palette'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  gridButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    textAlign: 'right'
  },
  selectedLoja: {
    fontWeight: 700
  }
})

const RelatorioRanking = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { relatorio } = props.relatorio
  const ultimaVendaImportada = props.relatorio.relatorio.ultimaVendaImportada
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [dataInicial, setDataInicial] = React.useState(undefined)
  const [dataFinal, setDataFinal] = React.useState(undefined)

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();

    setDataInicial(moment(dataIni).format("YYYY-MM-DD"));
    setDataFinal(moment(dataFi).format("YYYY-MM-DD"));

    dispatch(relatorioAction.clear());
  }, []);

  const handleSubmit = (event) => {

    if (dataInicial != undefined && dataFinal != undefined) {

      if (dataFinal > dataInicial) {
        let payload = {
          DataInicio: moment(dataInicial).format("YYYY-MM-DD"),
          DataFim: moment(dataFinal).format("YYYY-MM-DD")
        };

        dispatch(relatorioAction.getRelatorioRanking(payload, true));
      }
      else {
        toast.error('Data final deve ser maior que a Data inicial!')
      }
    } else {
      dispatch(relatorioAction.clear());
    }
  };

  const handleChangeDataInicial = (e) => {
    setDataInicial(e.target.value)
  }

  const handleChangeDataFinal = (e) => {
    setDataFinal(e.target.value)
  }

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [props.relatorio.isLoading])

  const handlePrint = () => {
    window.open('/relatorioRanking', '_blank');
  }

  function getTotal() {

    if (value == 0) {

      if (undefined !== relatorio && relatorio.cuponsFidelizados && relatorio.cuponsFidelizados.length) {
        let total = relatorio.cuponsFidelizados.reduce(function (prevVal, elem) {
          return prevVal + elem.total;
        }, 0);
        return total;
      } else {
        return 0;
      }

    } else if (value == 1) {

      if (undefined !== relatorio && relatorio.percentualParticipacao && relatorio.percentualParticipacao.length) {
        let total = relatorio.percentualParticipacao.length;
        return total;
      } else {
        return 0;
      }

    } else if (value == 2) {

      if (undefined !== relatorio && relatorio.clientesFidelizados && relatorio.clientesFidelizados.length) {
        let total = relatorio.clientesFidelizados.reduce(function (prevVal, elem) {
          return prevVal + elem.total;
        }, 0);
        return total;
      } else {
        return 0;
      }

    } else if (value == 3) {

      if (undefined !== relatorio && relatorio.novosClientesFidelizados && relatorio.novosClientesFidelizados.length) {
        let total = relatorio.novosClientesFidelizados.reduce(function (prevVal, elem) {
          return prevVal + elem.total;
        }, 0);
        return total;
      } else {
        return 0;
      }

    } else if (value == 4) {

      if (undefined !== relatorio && relatorio.trocas && relatorio.trocas.length) {
        let total = relatorio.trocas.reduce(function (prevVal, elem) {
          return prevVal + elem.total;
        }, 0);
        return total;
      } else {
        return 0;
      }

      //} else if (value == 5) {

      //  if (undefined !== relatorio && relatorio.clientesRecorrentes30dias && relatorio.clientesRecorrentes30dias.length) {
      //    let total = relatorio.clientesRecorrentes30dias.reduce(function (prevVal, elem) {
      //      return prevVal + elem.total;
      //    }, 0);
      //    return total;
      //  } else {
      //    return 0;
      //  }
    }
  }

  const getColor = () => {

    var diff = moment().diff(moment(ultimaVendaImportada, 'DD/MM/YYYY'), 'days')
    if (diff > 1) {
      return palette.error.main
    }
    else {
      return palette.text.primary
    }

  }

  const setCellStyle = (loja) => {
    let cellStyle = ''

    if (props.usuarioLoja.loja.nome == loja) {
      cellStyle += classes.selectedLoja
    }

    return cellStyle
  }

  return (
    <Page className={classes.root} title="Ranking">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioRankings"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Ranking'}
                    />
                    <Grid alignItems="space-between" container spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography component="h2" gutterBottom variant="overline">Última atualização:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h2" gutterBottom variant="overline" style={{ color: getColor() }}>{(ultimaVendaImportada != null && ultimaVendaImportada != '' ? ultimaVendaImportada : '')}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Início *"
                          type="date"
                          value={dataInicial}
                          onChange={handleChangeDataInicial}
                          id="dataInicial"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Final *"
                          type="date"
                          value={dataFinal}
                          onChange={handleChangeDataFinal}
                          id="dataFinal"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </Button>
                        </Grid>
                        {/*<Grid>*/}
                        {/*  <Button*/}
                        {/*    color="primary"*/}
                        {/*    type="submit"*/}
                        {/*    onClick={() => handlePrint()}*/}
                        {/*    variant="outlined"*/}
                        {/*    startIcon={<PrintIcon />}*/}
                        {/*  >*/}
                        {/*    Imprimir*/}
                        {/*  </Button>*/}
                        {/*</Grid>*/}
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>


                <Paper>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                      <Tab label="Participação" {...a11yProps(0)} />
                      <Tab label="Cupons Fidelizados" {...a11yProps(1)} />
                      <Tab label="Clientes" {...a11yProps(2)} />
                      <Tab label="Novos Clientes" {...a11yProps(3)} />
                      <Tab label="Trocas" {...a11yProps(4)} />
                      {/*<Tab label="Clientes Recorrentes (últ. 30 dias)" {...a11yProps(5)} />*/}
                    </Tabs>
                  </Box>

                  <TabPanel value={value} index={0}>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Posição</TableCell>
                            <TableCell>Loja</TableCell>
                            <AccessControl
                              rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <>
                                  <TableCell align="right">Meta</TableCell>
                                </>
                              )}
                            />
                            <TableCell align="right">Realizado</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {undefined !== relatorio && relatorio.percentualParticipacao && relatorio.percentualParticipacao.length
                            ? relatorio.percentualParticipacao.map((n, i) => {
                              return (
                                <TableRow hover key={n.id}>
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {n.loja || ''}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {n.loja || ''}
                                    </TableCell>
                                  }
                                  <AccessControl
                                    rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
                                    yes={() => (
                                      <>
                                        {props.usuarioLoja.loja.nome == n.loja ?
                                          <TableCell component="th" scope="row" align="right" style={{
                                            fontWeight: 700
                                          }}>
                                            {(n.meta != 0 ? n.meta : 0).toFixed(2) + '%'}
                                          </TableCell>
                                          :
                                          <TableCell component="th" scope="row" align="right">
                                            {(n.meta != 0 ? n.meta : 0).toFixed(2) + '%'}
                                          </TableCell>
                                        }
                                      </>
                                    )}
                                  />
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" align="right" style={{
                                      fontWeight: 700
                                    }}>
                                      {((n.totalVendas != 0 ? (((n.totalCuponsFidelizados / n.totalVendas) * 100)) : 0).toFixed(2) + '%')}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row" align="right">
                                      {((n.totalVendas != 0 ? (((n.totalCuponsFidelizados / n.totalVendas) * 100)) : 0).toFixed(2) + '%')}
                                    </TableCell>
                                  }
                                </TableRow>
                              )
                            })
                            :
                            (dataInicial != undefined && dataFinal != undefined ?
                              (isLoading ?
                                Array.apply(null, { length: 6 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <AccessControl
                                      rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <>
                                          <TableCell component="th" scope="row">
                                            <Skeleton animation="wave" />
                                          </TableCell>
                                        </>
                                      )}
                                    />
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow>
                                  <TableCell component="th" colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                              )
                              :
                              <TableRow>
                                <TableCell component="th" colSpan={3} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                              </TableRow>
                            )
                          }
                          {undefined !== relatorio && relatorio.cuponsFidelizados && relatorio.cuponsFidelizados.length
                            ?
                            <TableRow>
                              <TableCell rowSpan={3} colSpan={2}><b>Total</b></TableCell>
                              <TableCell rowSpan={3}><b>{getTotal()}</b></TableCell>
                            </TableRow>
                            :
                            null
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </TabPanel>
                  <TabPanel value={value} index={1}>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Posição</TableCell>
                            <TableCell>Loja</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {undefined !== relatorio && relatorio.cuponsFidelizados && relatorio.cuponsFidelizados.length
                            ? relatorio.cuponsFidelizados.map((n, i) => {
                              return (
                                <TableRow hover key={n.id}>
                                  <TableCell component="th" scope="row" className={setCellStyle(n.loja)}>
                                    {(i + 1) + 'º'}
                                  </TableCell>
                                  <TableCell component="th" scope="row" className={setCellStyle(n.loja)}>
                                    {n.loja || ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="right" className={setCellStyle(n.loja)}>
                                    {n.total || 0}
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            :
                            (dataInicial != undefined && dataFinal != undefined ?
                              (isLoading ?
                                Array.apply(null, { length: 6 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow>
                                  <TableCell component="th" colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                              )
                              :
                              <TableRow>
                                <TableCell component="th" colSpan={3} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                              </TableRow>
                            )
                          }
                          {undefined !== relatorio && relatorio.cuponsFidelizados && relatorio.cuponsFidelizados.length
                            ?
                            <TableRow>
                              <TableCell rowSpan={3} colSpan={2}><b>Total</b></TableCell>
                              <TableCell rowSpan={3}><b>{getTotal()}</b></TableCell>
                            </TableRow>
                            :
                            null
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </TabPanel>                  
                  <TabPanel value={value} index={2}>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Posição</TableCell>
                            <TableCell>Loja</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {undefined !== relatorio && relatorio.clientesFidelizados && relatorio.clientesFidelizados.length
                            ? relatorio.clientesFidelizados.map((n, i) => {
                              return (
                                <TableRow hover key={n.id}>
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {n.loja || ''}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {n.loja || ''}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" align="right" style={{
                                      fontWeight: 700
                                    }}>
                                      {n.total || 0}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row" align="right">
                                      {n.total || 0}
                                    </TableCell>
                                  }
                                </TableRow>
                              )
                            })
                            :
                            (dataInicial != undefined && dataFinal != undefined ?
                              (isLoading ?
                                Array.apply(null, { length: 6 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow>
                                  <TableCell component="th" colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                              )
                              :
                              <TableRow>
                                <TableCell component="th" colSpan={3} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                              </TableRow>
                            )
                          }
                          {undefined !== relatorio && relatorio.clientesFidelizados && relatorio.clientesFidelizados.length
                            ?
                            <TableRow>
                              <TableCell rowSpan={3} colSpan={2}><b>Total</b></TableCell>
                              <TableCell rowSpan={3}><b>{getTotal()}</b></TableCell>
                            </TableRow>
                            :
                            null
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </TabPanel>
                  <TabPanel value={value} index={3}>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Posição</TableCell>
                            <TableCell>Loja</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {undefined !== relatorio && relatorio.novosClientesFidelizados && relatorio.novosClientesFidelizados.length
                            ? relatorio.novosClientesFidelizados.map((n, i) => {
                              return (
                                <TableRow hover key={n.id}>
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {n.loja || ''}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {n.loja || ''}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" align="right" style={{
                                      fontWeight: 700
                                    }}>
                                      {n.total || 0}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row" align="right">
                                      {n.total || 0}
                                    </TableCell>
                                  }
                                </TableRow>
                              )
                            })
                            :
                            (dataInicial != undefined && dataFinal != undefined ?
                              (isLoading ?
                                Array.apply(null, { length: 6 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow>
                                  <TableCell component="th" colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                              )
                              :
                              <TableRow>
                                <TableCell component="th" colSpan={3} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                              </TableRow>
                            )
                          }
                          {undefined !== relatorio && relatorio.novosClientesFidelizados && relatorio.novosClientesFidelizados.length
                            ?
                            <TableRow>
                              <TableCell rowSpan={4} colSpan={2}><b>Total</b></TableCell>
                              <TableCell rowSpan={4}><b>{getTotal()}</b></TableCell>
                            </TableRow>
                            :
                            null
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </TabPanel>
                  <TabPanel value={value} index={4}>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Posição</TableCell>
                            <TableCell>Loja</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {undefined !== relatorio && relatorio.trocas && relatorio.trocas.length
                            ? relatorio.trocas.map((n, i) => {
                              return (
                                <TableRow hover key={n.id}>
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {(i + 1) + 'º'}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" style={{
                                      fontWeight: 700
                                    }}>
                                      {n.loja || ''}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row">
                                      {n.loja || ''}
                                    </TableCell>
                                  }
                                  {props.usuarioLoja.loja.nome == n.loja ?
                                    <TableCell component="th" scope="row" align="right"  style={{
                                      fontWeight: 700
                                    }}>
                                      {n.total || 0}
                                    </TableCell>
                                    :
                                    <TableCell component="th" scope="row" align="right">
                                      {n.total || 0}
                                    </TableCell>
                                  }
                                </TableRow>
                              )
                            })
                            :
                            (dataInicial != undefined && dataFinal != undefined ?
                              (isLoading ?
                                Array.apply(null, { length: 6 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow>
                                  <TableCell component="th" colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                              )
                              :
                              <TableRow>
                                <TableCell component="th" colSpan={3} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                              </TableRow>
                            )
                          }
                          {undefined !== relatorio && relatorio.trocas && relatorio.trocas.length
                            ?
                            <TableRow>
                              <TableCell rowSpan={3} colSpan={2}><b>Total</b></TableCell>
                              <TableCell rowSpan={3}><b>{getTotal()}</b></TableCell>
                            </TableRow>
                            :
                            null
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </TabPanel>

                  {/*<TabPanel value={value} index={5}>*/}

                  {/*  <TableContainer>*/}
                  {/*    <Table>*/}
                  {/*      <TableHead>*/}
                  {/*        <TableRow>*/}
                  {/*          <TableCell>Posição</TableCell>*/}
                  {/*          <TableCell>Loja</TableCell>*/}
                  {/*          <TableCell>Total</TableCell>*/}
                  {/*        </TableRow>*/}
                  {/*      </TableHead>*/}
                  {/*      <TableBody>*/}

                  {/*        {undefined !== relatorio && relatorio.clientesRecorrentes30dias && relatorio.clientesRecorrentes30dias.length*/}
                  {/*          ? relatorio.clientesRecorrentes30dias.map((n, i) => {*/}
                  {/*            return (*/}
                  {/*              <TableRow hover key={n.id}>*/}
                  {/*                {props.usuarioLoja.loja.nome == n.loja ?*/}
                  {/*                  <TableCell component="th" scope="row" style={{*/}
                  {/*                    fontWeight: 700*/}
                  {/*                  }}>*/}
                  {/*                    {(i + 1) + 'º'}*/}
                  {/*                  </TableCell>*/}
                  {/*                  :*/}
                  {/*                  <TableCell component="th" scope="row">*/}
                  {/*                    {(i + 1) + 'º'}*/}
                  {/*                  </TableCell>*/}
                  {/*                }*/}
                  {/*                {props.usuarioLoja.loja.nome == n.loja ?*/}
                  {/*                  <TableCell component="th" scope="row" style={{*/}
                  {/*                    fontWeight: 700*/}
                  {/*                  }}>*/}
                  {/*                    {n.loja || ''}*/}
                  {/*                  </TableCell>*/}
                  {/*                  :*/}
                  {/*                  <TableCell component="th" scope="row">*/}
                  {/*                    {n.loja || ''}*/}
                  {/*                  </TableCell>*/}
                  {/*                }*/}
                  {/*                {props.usuarioLoja.loja.nome == n.loja ?*/}
                  {/*                  <TableCell component="th" scope="row" style={{*/}
                  {/*                    fontWeight: 700*/}
                  {/*                  }}>*/}
                  {/*                    {n.total || 0}*/}
                  {/*                  </TableCell>*/}
                  {/*                  :*/}
                  {/*                  <TableCell component="th" scope="row">*/}
                  {/*                    {n.total || 0}*/}
                  {/*                  </TableCell>*/}
                  {/*                }*/}
                  {/*              </TableRow>*/}
                  {/*            )*/}
                  {/*          })*/}
                  {/*          :*/}
                  {/*          (dataInicial != undefined && dataFinal != undefined ?*/}
                  {/*            (isLoading ?*/}
                  {/*              Array.apply(null, { length: 6 }).map((e, i) => (*/}
                  {/*                <TableRow hover>*/}
                  {/*                  <TableCell component="th" scope="row">*/}
                  {/*                    <Skeleton animation="wave" />*/}
                  {/*                  </TableCell>*/}
                  {/*                  <TableCell component="th" scope="row">*/}
                  {/*                    <Skeleton animation="wave" />*/}
                  {/*                  </TableCell>*/}
                  {/*                  <TableCell component="th" scope="row">*/}
                  {/*                    <Skeleton animation="wave" />*/}
                  {/*                  </TableCell>*/}
                  {/*                </TableRow>*/}
                  {/*              ))*/}
                  {/*              :*/}
                  {/*              <TableRow>*/}
                  {/*                <TableCell component="th" colSpan={3} align="center">Nenhum registro encontrado</TableCell>*/}
                  {/*              </TableRow>*/}
                  {/*            )*/}
                  {/*            :*/}
                  {/*            <TableRow>*/}
                  {/*              <TableCell component="th" colSpan={3} align="center">Informe uma Data de Início e uma Data de Final</TableCell>*/}
                  {/*            </TableRow>*/}
                  {/*          )*/}
                  {/*        }*/}
                  {/*        {undefined !== relatorio && relatorio.clientesRecorrentes30dias && relatorio.clientesRecorrentes30dias.length*/}
                  {/*          ?*/}
                  {/*          <TableRow>*/}
                  {/*            <TableCell rowSpan={3} colSpan={2}><b>Total</b></TableCell>*/}
                  {/*            <TableCell rowSpan={3}><b>{getTotal()}</b></TableCell>*/}
                  {/*          </TableRow>*/}
                  {/*          :*/}
                  {/*          null*/}
                  {/*        }*/}

                  {/*      </TableBody>*/}
                  {/*    </Table>*/}
                  {/*  </TableContainer>*/}

                  {/*</TabPanel>*/}

                </Paper>

              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

RelatorioRanking.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedRelatorioRankingPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioRanking))
)
export { connectedRelatorioRankingPage as RelatorioRanking }
