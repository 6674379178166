import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { certificadoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  Chip,
  Select,
  MenuItem,
  TablePagination
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import moment from 'moment'
import { useDebounce } from 'use-debounce'
import StatusCertificado from '../../utils/enums/statusCertificado'
import StatusImportacaoCertificado from '../../utils/enums/statusImportacaoCertificado'
import { OpenInBrowserTwoTone } from '@material-ui/icons'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
})

const Certificado = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { match: { params } } = props;
  const { certificado, totalRegistros } = props.certificado
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, status, direction, order, statusImportacao } = useSelector(state => state.certificado);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm, status, statusImportacao]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'dataValidade',
      Direction: direction || 'asc',
      Status: status || 0,
      StatusImportacao: statusImportacao || 0
    };

    dispatch(certificadoAction.getCertificado(filterModel, true));
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Certificado?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.certificado.isLoading,
      confirmationText: props.certificado.isLoading ? 'Excluindo certificado...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(certificadoAction.deleteCertificadoById(id))
    })
  }

  const handleChange = prop => event => {
    dispatch(certificadoAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(certificadoAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.certificado.page = 0;
    dispatch(certificadoAction.onChangeProps('term', fakeEvent))
  }

  const handleChangeStatus = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.certificado.page = 0;
    dispatch(certificadoAction.onChangeProps('status', fakeEvent))
  }

  const handleChangeStatusImportacao = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.certificado.page = 0;
    dispatch(certificadoAction.onChangeProps('statusImportacao', fakeEvent))
  }

  const handleOpenNewTab = (idLoja) => {
    const newTab = window.open(`/loja/${idLoja}`, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const getStatus = (c) => {
    let statusLabel = ''
    let bgColor = ''

    let dataValidade = new Date(c.dataValidade).getTime()
    let dataAtual = new Date().setHours(0, 0, 0, 0)

    // Converte a diferença em milissegundos para dias
    var diferencaEmDias = Math.ceil((dataValidade - dataAtual) / (1000 * 60 * 60 * 24));

    if (dataValidade < dataAtual) {
      statusLabel = 'Vencido'
      bgColor = '#D2222D'
    }

    if (dataValidade == dataAtual) {
      statusLabel = 'À vencer'
      bgColor = '#ECB718'
    }

    if (diferencaEmDias > 0 && diferencaEmDias <= 15) {
      statusLabel = `Vence em ${diferencaEmDias} dia(s)`
      bgColor = '#238823'
    }

    if (statusLabel) {
      return <Chip label={statusLabel} style={{ backgroundColor: bgColor, color: "#FFF" }} size="small" />
    }

    return null
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Certificados - Editar Certificado'
          : 'Gerência de Certificados - Novo Certificado'
      }
    >
      <AccessControl
        rule={'certificados'}
        yes={() => (
          <div>
            <TitleContent
              rule={'certificados.create'}
              length={undefined !== certificado && certificado.length > 0 ? certificado.length : 0}
              subTitle={'GERÊNCIA DE CERTIFICADO'}
              title={'Lista de Certificados'}
              href={'/certificado'}
            />
            <Grid container spacing={3}>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={(event) => handleChangeTerm(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Select
                  id="status"
                  value={status}
                  onChange={(event) => handleChangeStatus(event.target.value)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Status</em>
                  </MenuItem>
                  {
                    Object.keys(StatusCertificado).map((row, index) => (
                      <MenuItem key={index} value={index}>
                        { Object.values(StatusCertificado).at(index) }
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Select
                  id="statusImportacao"
                  value={statusImportacao}
                  onChange={(event) => handleChangeStatusImportacao(event.target.value)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Status Importação</em>
                  </MenuItem>
                  {
                    Object.keys(StatusImportacaoCertificado).map((row, index) => (
                      <MenuItem key={index} value={index}>
                        {Object.values(StatusImportacaoCertificado).at(index)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Certificado</TableCell>
                    <TableCell>Data de Validade</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Loja</TableCell>
                    <TableCell>Importado</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== certificado && certificado.length
                    ? certificado.map((c) => {
                      
                      return (
                        <TableRow hover key={c.id}>
                          <TableCell component="th" scope="row">
                            {stringUtils.getFileNameFromUrl(c.nome)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(c.dataValidade).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {getStatus(c)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {c.loja ? c.loja.nome : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {c.importado ? 'SIM' : 'NÂO'}
                          </TableCell>

                          <TableCell align="right">
                            <AccessControl
                              rule={'certificados.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Open"
                                  onClick={() => handleOpenNewTab(c.idLoja)}
                                  size="small"
                                  target="_blank"
                                >
                                  <Tooltip title={'Abrir cadastro da Loja'}>
                                    <Tooltip>
                                      <OpenInNewIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'certificados.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/certificado/${c.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip>
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'certificados.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(c.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )  
}

Certificado.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    certificado: state.certificado
  }
}
const connectedCertificadoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Certificado))
)
export { connectedCertificadoPage as Certificado }
