import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const bannerAction = {
  getBanner,
  addBanner,
  getBannerById,
  onChangeProps,
  editBannerInfo,
  editBannersDetails,
  createBanner,
  deleteBannerById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getBanner(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'banners' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeBannersList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createBanner(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'banners/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createBannerInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/banners')
        } else {
          toast.err('Oops! Erro ao cadastrar banner! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar banner! 😥')
        return err.response;
      })
  }
}

function getBannerById(id) {
  return (dispatch) => {
    let apiEndpoint = 'banners/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editBannersDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editBannerInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'banners/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedBannerInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/banners')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar banner! 😥')
        return err.response;
      })
  }
}

function deleteBannerById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'banners/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteBannersDetails())
      dispatch(getBanner(filterModel))
      dispatch(notIsLoading())
      history.push('/banners')
    })
  }
}

export function addBanner(payload) {
  return [{ type: 'ADD_BANNER', banner: payload }, clear()]
}

export function changeBannersList(banner, totalRows) {
  return {
    type: 'FETCHED_ALL_BANNER',
    banner: banner,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'BANNER_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'BANNER_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'BANNER_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editBannersDetails(banner) {
  return [
    { type: 'BANNER_DETAIL', banner },
  ]
}

export function updatedBannerInfo() {
  return {
    type: 'BANNER_UPDATED'
  }
}

export function createBannerInfo() {
  return {
    type: 'BANNER_CREATED_SUCCESSFULLY'
  }
}

export function deleteBannersDetails() {
  return {
    type: 'DELETED_BANNER_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'BANNER_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'BANNER_NOTISLOADING'
  }
}
