import axios from 'axios'
import config from '../config/config'
import { toast } from 'react-toastify'
import { errorHandler } from './errorHandler'

export const crudService = {
  get,
  post,
  postWithToken,
  put,
  deleteDetail
}

function get(apiEndpoint) {
  return axios
    .get(config.baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response
    })
    .catch((error) => {
      return errorHandler(error)
    })
}

function post(apiEndpoint, payload) {
  return axios
    .post(config.baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response
    })
    .catch((error) => {
      return errorHandler(error)
    })
}

function postWithToken(apiEndpoint, payload, token) {
  let options = {}
  if (token) {
    options.headers = { 'Authorization': 'Bearer ' + token, 'Cache-Control': 'no-cache' };
  } else {
    options.headers = { 'Cache-Control': 'no-cache' };
  }

  return axios
    .post(config.baseUrl + apiEndpoint, payload, options)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return errorHandler(error)
    })
}

function put(apiEndpoint, payload) {
  return axios
    .put(config.baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      toast.success('Feito! Dados alterados com sucesso! 😎')
      return response
    })
    .catch((error) => {
      return errorHandler(error)
    })
}

function deleteDetail(apiEndpoint) {
  return axios
    .delete(config.baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      toast.success('Feito! Registro excluído com sucesso! 😎')
      return response
    })
    .catch((error) => {
      return errorHandler(error)
    })
}

function getOptions() {
  let options = {}
  if (localStorage.getItem('token')) {
    options.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Cache-Control': 'no-cache' };
  } else {
    options.headers = { 'Cache-Control': 'no-cache' };
  }
  return options
}
