const initialState = {
  loading: false,
  usuario: [],
  open: false,
  id: '',
  nome: '',
  idTipoUsuario: '',
}

export function tipoUsuario(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_TIPO_USUARIO':
      return {
        ...state,
        tipoUsuario: action.tipoUsuario
      }    
    default:
      return state
  }
}
