import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const check = (rule, permissaoTipoUsuario, permissaoUsuario) => {
  var todasPermissoes = permissaoTipoUsuario.concat(permissaoUsuario)
  const allowed = todasPermissoes.some(
    (item) => item.permissao.nome === rule && item.permitido
  )
  return allowed
}

const AccessControl = (props) =>
  check(props.rule, props.permissaoTipoUsuario, props.permissaoUsuario)
    ? props.yes()
    : props.no()

AccessControl.defaultProps = {
  yes: () => null,
  no: () => null
}

AccessControl.propTypes = {
  rule: PropTypes.string.isRequired
}

const mapStateToProps = (state, index) => {
  return {
    key:"access" + index,
    permissaoTipoUsuario:
      state.authentication.user.tipoUsuario !== undefined &&
      state.authentication.user.tipoUsuario.permissoesTiposUsuarios,
    permissaoUsuario:
      state.authentication.user.permissoesUsuarios !== undefined &&
      state.authentication.user.permissoesUsuarios
  }
}
const connectedAccessControlPage = connect(mapStateToProps)(AccessControl)
export { connectedAccessControlPage as AccessControl }
