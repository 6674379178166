import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {}
}))

const Header = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography component="h2" gutterBottom variant="overline">
        {props.subtitle}
      </Typography>
      <Typography component="h1" variant="h3">
        {props.title}
      </Typography>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default Header
