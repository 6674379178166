import { crudService } from '../_services/'

export const cidadeAction = {
  getCidade,
  getCidadeSelect,
  getCidadeByUf,
  getCidadeByIdEstado,
  addCidade,
  getCidadeById,
  onChangeProps,
  editCidadeInfo,
  editCidadesDetails,
  createCidade,
  deleteCidadeById,
  clear,
  clearAll
}

function getCidade() {
  return (dispatch) => {
    let apiEndpoint = 'cidades'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCidadesList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCidadeSelect() {
  return (dispatch) => {
    let apiEndpoint = 'cidades/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeCidadesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCidadeByUf(uf) {
  return (dispatch) => {
    let apiEndpoint = 'cidades/select/uf/' + uf
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeCidadesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCidadeByIdEstado(idEstado) {
  return (dispatch) => {
    let apiEndpoint = 'cidades/select/estado/' + idEstado
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeCidadesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createCidade(payload) {
  return (dispatch) => {
    let apiEndpoint = 'cidades/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createCidadeInfo())
      dispatch(clear())
    })
  }
}

function getCidadeById(id) {
  return (dispatch) => {
    let apiEndpoint = 'cidades/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCidadesDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}

function editCidadeInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'cidades/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedCidadeInfo())
      dispatch(clear())
    })
  }
}

function deleteCidadeById(id) {
  return (dispatch) => {
    let apiEndpoint = 'cidades/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteCidadesDetails())
      dispatch(getCidade())
    })
  }
}

export function addCidade(payload) {
  return [{ type: 'ADD_CIDADE', cidade: payload }, clear()]
}

export function changeCidadesList(cidade) {
  return {
    type: 'FETCHED_ALL_CIDADE',
    cidade: cidade
  }
}

export function clear() {
  return {
    type: 'CIDADE_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CIDADE_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CIDADE_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCidadesDetails(cidade) {
  return {
    type: 'CIDADE_DETAIL',
    id: cidade.id,
    nome: cidade.nome,
    estadoId: cidade.estadoId,
    ibge: cidade.ibge,
    latLon: cidade.latLon,
    estado: cidade.estado
  }
}

export function updatedCidadeInfo() {
  return {
    type: 'CIDADE_UPDATED'
  }
}

export function createCidadeInfo() {
  return {
    type: 'CIDADE_CREATED_SUCCESSFULLY'
  }
}

export function deleteCidadesDetails() {
  return {
    type: 'DELETED_CIDADE_DETAILS'
  }
}
