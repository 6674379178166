const initialState = {
  totalRegistros: 0,
  isLoading: false,
  usuario: [],
  open: false,
  id: '',
  email: '',
  nome: '',
  senha: '',
  sobrenome: '',
  telefone: '',
  idTipoUsuario: '',
  plataforma: '',
  versaoApp: '',
  caminhoImagem: '',
  emailVerificado: false,
  telefoneVerificado: false,
  dataHoraUltimoAcesso: false,
  dataHoraCadastro: false,
  openModalRequestPasswordReset: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function usuario(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USER_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        id: action.user.id,
        isLoading: false,
        email: action.user.email,
        nome: action.user.nome,
        senha: action.user.senha,
        sobrenome: action.user.sobrenome,
        telefone: action.user.telefone,
        caminhoImagem: action.user.caminhoImagem,
        idTipoUsuario: action.user.idTipoUsuario,
        telefoneVerificado: action.user.telefoneVerificado,
        emailVerificado: action.user.emailVerificado,
        dataHoraUltimoAcesso: action.user.dataHoraUltimoAcesso,
        dataHoraCadastro: action.user.dataHoraCadastro
      }
    case 'CREATE_USER_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_USER_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_USER_SUCCESS':
      return {
        ...state,
        id: action.user.id,
        isLoading: false,
        email: action.user.email,
        nome: action.user.nome,
        senha: action.user.senha,
        sobrenome: action.user.sobrenome,
        telefone: action.user.telefone,
        caminhoImagem: action.user.caminhoImagem,
        idTipoUsuario: action.user.idTipoUsuario,
        telefoneVerificado: action.user.telefoneVerificado,
        emailVerificado: action.user.emailVerificado,
        dataHoraUltimoAcesso: action.user.dataHoraUltimoAcesso,
        dataHoraCadastro: action.user.dataHoraCadastro
      }
    case 'EDIT_USER_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'REQUEST_RESET_PASSWORD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'REQUEST_RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        openModalRequestPasswordReset: false
      }
    case 'REQUEST_RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_USUARIO':
      return {
        ...state,
        usuario: action.usuario,
        totalRegistros: action.totalRegistros
      }
    case 'USUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        email: action.email,
        nome: action.nome,
        senha: action.senha,
        sobrenome: action.sobrenome,
        telefone: action.telefone,
        idTipoUsuario: action.idTipoUsuario,
        caminhoImagem: action.caminhoImagem,
        telefoneVerificado: action.telefoneVerificado,
        emailVerificado: action.emailVerificado,
        dataHoraUltimoAcesso: action.dataHoraUltimoAcesso,
        dataHoraCadastro: action.dataHoraCadastro
      }
    case 'USER_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'USUARIO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        usuario: [...state.usuario, action.data.usuario]
      }
    case 'USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OPEN_MODAL_REQUEST_PASSWORD_RESET':
      return {
        ...state,
        openModalRequestPasswordReset: action.open
      }
    case 'USUARIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
