const initialState = {
  isLoading: false,
  totalRegistros: 0,
  sorteio: [],
  id: 0,
  nome: '',
  descricao: '',
  dataHoraInicio: '',
  dataHoraInicioInscricao: '',
  dataHoraTermino: '',
  dataHoraSorteio: '',
  valorNumero: 0,
  linkRegulamento: '',
  caminhoImagem: '',
  caminhoImagemBanner: '',
  totalSorteiosClientes: 0,
  totalSorteiosClientesNumeros: 0,
  gerarNumerosSorte: false,
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function sorteio(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_SORTEIO':
      return {
        ...state,
        sorteio: action.sorteio,
        totalRegistros: action.totalRegistros
      }
    case 'SORTEIO_DETAIL':
      return {
        ...state,
        isLoading: false,
        caminhoImagem: action.sorteio.caminhoImagem,
        caminhoImagemBanner: action.sorteio.caminhoImagemBanner,
        dataHoraInicio: action.sorteio.dataHoraInicio,
        dataHoraInicioInscricao: action.sorteio.dataHoraInicioInscricao,
        dataHoraSorteio: action.sorteio.dataHoraSorteio,
        dataHoraTermino: action.sorteio.dataHoraTermino,
        descricao: action.sorteio.descricao,
        gerarNumerosSorte: action.sorteio.gerarNumerosSorte,
        excluido: action.sorteio.excluido,
        id: action.sorteio.id,
        linkRegulamento: action.sorteio.linkRegulamento,
        nome: action.sorteio.nome,
        totalSorteiosClientes: action.sorteio.totalSorteiosClientes,
        totalSorteiosClientesNumeros: action.sorteio.totalSorteiosClientesNumeros,
        valorNumero: action.sorteio.valorNumero
      }
    case 'SORTEIO_UPDATED':
      return state
    case 'SORTEIO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'SORTEIO_CLEAR_ALL':
      return {
        ...state,
        sorteio: []
      }
    case 'SORTEIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'SORTEIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'SORTEIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
