const initialState = {
  totalRegistros: 0,
  pontuacao: [],
  open: false,
  id: 0,
  dataHora: '',
  tipo: '',
  pontos: '',
  idCliente: 0,
  idFidelizacao: 0,
  idTroca: 0,
  idCampanha: 0,
  idTipoAjustePontuacao: 0,
  tipoAjustePontuacao: [],
  excluido: false,
}

export function pontuacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PONTUACAO':
      return {
        ...state,
        pontuacao: action.pontuacao,
        totalRegistros: action.totalRegistros
      }
    case 'PONTUACAO_DETAIL':
      return action.pontuacao
    case 'PONTUACAO_UPDATED':
      return state
    case 'PONTUACAO_CLEAR':
      return initialState
    case 'PONTUACAO_CLEAR_ALL':
      return {
        ...state,
        pontuacao: []
      }
    case 'PONTUACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
