import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { certificadoAction, lojaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { uploadFile } from '_services'
import { history, stringUtils } from '../../utils'
import SaveIcon from '@material-ui/icons/Save';
import { EditorHeader, FilePicker, Page } from 'components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const CertificadoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, certificado, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [valueLoja, setValueLoja] = useState(null)
  const [inputValueLoja, setInputValueLoja] = useState('')

  const [showPassword, setShowPassword] = React.useState(false)

  const optionsLojas = useSelector((state) => state.loja.loja)

  useEffect(() => {
    dispatch(lojaAction.getLojaSelect())

    if (params.id) {
      dispatch(certificadoAction.getCertificadoById(params.id))
    } else {
      dispatch(certificadoAction.clear())
    }
  }, []);

  useEffect(() => {
    if (props.certificado.idLoja) {
      let loja = optionsLojas.find((option) => option.value == props.certificado.idLoja)
      setValueLoja(loja)
    }
  }, [props.certificado.idLoja]);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    dispatch(certificadoAction.onChangeProps(prop, event))
  }

  const onChangeFile = (event) => {
    // data for submit
    var file = event.target.files[0]

    uploadFile(file, 'certificados', '.pfx')
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };

          dispatch(certificadoAction.onChangeProps('nome', event2))
        }, 1000);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.certificado.nome,
      dataValidade: moment(props.certificado.dataValidade).format("yyyy-MM-DD"),
      idLoja: valueLoja.value,
      senha: props.certificado.senha,
      importado: props.certificado.importado,
    }

    if (params.id) {
      dispatch(certificadoAction.editCertificadoInfo(params.id, payload, true))
    } else {
      dispatch(certificadoAction.createCertificado(payload))
    }
  };

  const handleDownload = (value) => {
    if (value) {
      dispatch(certificadoAction.download(value))
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Certificado'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Certificados - Editar Certificado'
          : 'Gerência de Certificados - Novo Certificado'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formCertificado"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Certificados"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Certificado' tabela='Certificados' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2} alignItems="center" >
                    <Grid container spacing={2} style={{ margin: "0px 2px"}}>
                      <Grid item lg={8} md={8} xl={8} xs={12} alignItems="center" style={{ display: "flex", gap: "15px" }}>
                        <FilePicker
                          fileType='.pfx'
                          value={stringUtils.getFileNameFromUrl(props.certificado.nome)}
                          onChangeFile={onChangeFile}
                          download={handleDownload}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Data de Validade *"
                          type="date"
                          value={props.certificado.dataValidade ? moment(props.certificado.dataValidade).format("yyyy-MM-DD") : ''}
                          onChange={handleChange('dataValidade')}
                          id="dataValidade"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} xl={5} xs={12}>
                      <Autocomplete
                        id="autocomplete-loja"
                        options={optionsLojas}
                        getOptionLabel={option => option.text}
                        value={valueLoja}
                        onChange={(event, loja) => {
                          setValueLoja(loja)
                        }}
                        inputValue={inputValueLoja}
                        onInputChange={(event, newInputValue) => {
                          setInputValueLoja(newInputValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="SELECIONE UMA LOJA...*" fullWidth variant="outlined" />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} md={5} xl={5} xs={12} style={{ paddingBottom: "15px" }}>
                      <TextValidator
                        id="dataValidade"
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Senha *"
                        type={showPassword ? 'text' : 'password'}
                        value={props.certificado.senha}
                        onChange={handleChange('senha')}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        InputLabelProps={{
                          shrink: true,
                          autoComplete: false
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.certificado.importado}
                            onChange={handleChange('importado')}
                            color="primary"
                          />
                        }
                        label="Importado"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/certificados')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.certificado.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.certificado.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.certificado.isLoading ? 'Atualizando certificado...' : 'Atualizar') : (props.certificado.isLoading ? 'Salvando certificado...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>

    </Page>
  )
}

CertificadoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedCertificadoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CertificadoEdit))
)
export { connectedCertificadoEditPage as CertificadoEdit }
