import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import { notificacaoFiltroAction } from '../_actions';

export const notificacaoAction = {
  getNotificacao,
  addNotificacao,
  getNotificacaoById,
  onChangeProps,
  editNotificacaoInfo,
  editNotificacaosDetails,
  duplicateNotificacao,
  createNotificacao,
  sendNotificacao,
  sendNotificacaoTeste,
  deleteNotificacaoById,
  openModalNotificacaoTestes,
  openModalNotificacaoFiltrosItens,
  openModalNotificacaoUsuarios,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'dataHora',
  Direction: 'desc'
};

function getNotificacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeNotificacaosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createNotificacao(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'notificacoes/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createNotificacaoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(getNotificacaoById(ret.key))
          dispatch(notIsLoading())
          history.push('/notificacao/' + ret.key)
      } else {
          toast.err('Oops! Erro ao cadastrar notificação!  😥')
      }
     }).catch ((err) => {
       //  console.log(err.response);
       dispatch(notIsLoading())
    toast.error('Oops! Erro ao cadastrar notificação! 😥')
    return err.response;
  })
  }
}

function duplicateNotificacao(id, redirect) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoes/duplicar/' + id
    crudService.post(apiEndpoint).then((response) => {
      var ret = JSON.parse(response.request.responseText);
      dispatch(createNotificacaoInfo())
      toast.success('Feito! Notificação duplicada com sucesso! 😎')
      dispatch(getNotificacao(filterModel))
    })
  }
}

function sendNotificacao(id) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoes/enviar/' + id;
    crudService.post(apiEndpoint).then((response) => {
      var ret = JSON.parse(response.request.responseText);
      dispatch(createNotificacaoInfo())
      toast.success('Notificação enviada com sucesso! 😎')
      dispatch(clear())
      history.push('/notificacoes')
    })
  }
}

function sendNotificacaoTeste(id, selectedClientes) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoes/enviarTeste/' + id;
    crudService.post(apiEndpoint, selectedClientes).then((response) => {
      var ret = JSON.parse(response.request.responseText);
      dispatch(createNotificacaoInfo())
      toast.success('Notificação de teste enviada com sucesso! 😎')
      dispatch(clear())
      history.push('/notificacoes')
    })
  }
}

function getNotificacaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'notificacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editNotificacaosDetails(response.data))
      dispatch(notificacaoFiltroAction.getNotificacaoFiltroByNotificacao(id))
      dispatch(notIsLoading())
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editNotificacaoInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'notificacoes/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedNotificacaoInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/notificacoes')
          } 
        }
      }).catch((err) => {
          dispatch(notIsLoading())
          toast.error('Oops! Erro ao alterar notificação! 😥')
          return err.response;
      })
  }
}

function deleteNotificacaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'notificacoes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteNotificacaosDetails())
      dispatch(getNotificacao(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addNotificacao(payload) {
  return [{ type: 'ADD_NOTIFICACAO', notificacao: payload }, clear()]
}

export function changeNotificacaosList(notificacao, totalRows) {
  return {
    type: 'FETCHED_ALL_NOTIFICACAO',
    notificacao: notificacao,
    totalRegistros: totalRows
  }
}

export function openModalNotificacaoFiltrosItens(open) {
  return {
    type: 'OPEN_MODAL_NOTIFICACAO_FILTROS_ITENS',
    open: open
  }
}

export function openModalNotificacaoTestes(open) {
  return {
    type: 'OPEN_MODAL_NOTIFICACAO_TESTES',
    open: open
  }
}

export function openModalNotificacaoUsuarios(open) {
  return {
    type: 'OPEN_MODAL_NOTIFICACAO_USUARIOS',
    open: open
  }
}

export function clear() {
  return {
    type: 'NOTIFICACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'NOTIFICACAO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'NOTIFICACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editNotificacaosDetails(notificacao) {
  return [
    { type: 'NOTIFICACAO_DETAIL', notificacao },
  ]
}

export function updatedNotificacaoInfo() {
  return {
    type: 'NOTIFICACAO_UPDATED'
  }
}

export function createNotificacaoInfo() {
  return {
    type: 'NOTIFICACAO_CREATED_SUCCESSFULLY'
  }
}

export function deleteNotificacaosDetails() {
  return {
    type: 'DELETED_NOTIFICACAO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'NOTIFICACAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'NOTIFICACAO_NOTISLOADING'
  }
}
