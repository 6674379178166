import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { checklistOperacaoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Button,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import { Skeleton } from "@material-ui/lab";
import PrintIcon from '@material-ui/icons/Print';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce'
import {
  CardChecklist
} from './components'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  gridCards: {
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2),
    "&:hover": {
      background: "#efefef",
      transition: '1s'
    },
    minHeight: 130
  }
})

const ChecklistOperacaoCard = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { match: { params } } = props;
  const { checklistOperacao, isLoading } = props.checklistOperacao
  const { totalRegistros } = props.checklistOperacao

  const { className, ...rest } = props

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [ready, setReady] = useState(false);
  const [printGroup, setPrintGroup] = useState(false);
  const [checkedItems, setCheckedItems] = useState([])

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: (idLoja && idLoja !== '' ? idLoja : 0),
        IdCategoriaInsumo: (params && params.id !== null ? params.id : 0)
      };

      dispatch(checklistOperacaoAction.getChecklistOperacaoDia(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idLoja]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.checklistOperacao.isLoading])

  useEffect(() => {

    //inicialmente marca todos como checked
    if (!isLoading && undefined !== checklistOperacao && checklistOperacao.length) {

      let newCheckedItems = [...checkedItems];

      checklistOperacao.map((n) => {

        if (!checkedItems.includes(n.id)) {
          newCheckedItems.push(n.id);
        }

      })

      setCheckedItems(newCheckedItems);
      console.log('newCheckedItems', newCheckedItems)

    }

  }, [checklistOperacao])

  const handleChangePrintGroup = () => {
    setPrintGroup(!printGroup)
  }

  const handleCheck = (checked, itemId) => {
    let newCheckedItems = [...checkedItems];

    if (checked && !checkedItems.includes(itemId)) {
      newCheckedItems.push(itemId);
    } else {
      newCheckedItems = newCheckedItems.filter(item => item !== itemId);
    }

    setCheckedItems(newCheckedItems);
  }

  const handlePrintGroup = () => {

    if (checkedItems.length === 0) {
      toast.error('Selecione itens para que seja possível imprimir!');
      return;
    }

    const itensId = checkedItems.toString()

    var dataInicio = moment().startOf('week').add('days', 1).format('YYYY-MM-DD');
    var dataFim = moment().endOf('week').add('days', 1).format('YYYY-MM-DD');

    window.open(`/relatorioCheckListOperacao/${itensId}/${dataInicio}/${dataFim}`, '_blank');
  }

  return (
    <Page className={classes.root} title="Checklists de Operação">
      <AccessControl
        rule={'checklistsOperacoes'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div {...rest} className={clsx(classes.root, className)}>

            <div {...rest} className={clsx(classes.root, className)}>
              <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography component="h2" gutterBottom variant="overline">
                    GERÊNCIA DE CHECKLIST DE OPERAÇÃO
                  </Typography>
                  <Typography component="h1" variant="h3">
                    Lista de Checklists de Operação
                  </Typography>
                </Grid>
                {!printGroup ?
                  <Button
                    onClick={handleChangePrintGroup}
                    variant="contained"
                    color={"primary"}
                    startIcon={<PrintIcon />}
                  >
                    Impressão conjunta
                  </Button>
                  :
                  <Grid item>
                    <Button
                      onClick={handlePrintGroup}
                      variant="contained"
                      color={"primary"}
                      startIcon={<PrintIcon />}
                    >
                      Imprimir
                    </Button>
                    <IconButton onClick={handleChangePrintGroup} color={"initial"}>
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>
              <br></br>
              <Typography variant="body2" align="left" gutterBottom>
                <b>{'Confira o andamento dos Checklists de Operação para o dia de hoje: ' + moment().format("DD/MM/YYYY")}</b>
              </Typography>
            </div>

            <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
              <Grid container className={classes.gridCards} spacing={2}>
                {!isLoading ?
                  undefined !== checklistOperacao && checklistOperacao.length
                    ? checklistOperacao.map((n) => {
                      return (
                        <Grid container lg={4} sm={6} xl={3} xs={12} spacing={2} key={n.id}>
                          <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <CardChecklist
                              variant="elevation"
                              className={classes.card}
                              id={n.id}
                              name={<Typography component="h1" gutterBottom variant="h6">
                                {(n.nome != undefined ? n.nome : 'CHECKLIST')}
                              </Typography>}
                              type={<Typography component="h1" variant="h6">
                                {((n.itensRealizados != undefined ? n.itensRealizados : 0) + '/' + (n.totalItens != null ? n.totalItens : 0))}
                              </Typography>}
                              progresso={(n.totalItens != undefined ? (n.itensRealizados / n.totalItens * 100) : 0)}
                              printGroup={printGroup}
                              handleCheck={handleCheck}
                              isChecked={checkedItems.some((x) => x == n.id)}
                            />
                          </Grid>
                        </Grid>
                      )
                    })
                    : null
                  :
                  Array.from(new Array(4)).map((_, index) => (
                    <Grid item lg={4} sm={6} xl={3} xs={12}>
                      <Skeleton className={classes.card} animation="wave" height={250} />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </div>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
ChecklistOperacaoCard.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    checklistOperacao: state.checklistOperacao
  }
}
const connectedChecklistOperacaoCardPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ChecklistOperacaoCard))
)
export { connectedChecklistOperacaoCardPage as ChecklistOperacaoCard }
