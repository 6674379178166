const initialState = {
  isLoading: false,
  totalRegistros: 0,
  grupo: [],
  open: false,
  id: 0,
  idGrupoReferencia: 0,
  nome: '',
  caminhoImagem: '',
  toGo: false,
  delivery: false,
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function grupo(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_GRUPO':
      return {
        ...state,
        grupo: action.grupo,
        totalRegistros: action.totalRegistros
      }
    case 'GRUPO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.grupo.id,
        idGrupoReferencia: action.grupo.idGrupoReferencia,
        nome: action.grupo.nome,
        caminhoImagem: action.grupo.caminhoImagem,
        toGo: action.grupo.toGo,
        delivery: action.grupo.delivery,
        excluido: action.grupo.excluido
      }
    case 'GRUPO_UPDATED':
      return state
    case 'GRUPO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'GRUPO_CLEAR_ALL':
      return {
        ...state,
        grupo: []
      }
    case 'GRUPO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'GRUPO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'GRUPO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
