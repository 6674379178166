const initialState = {
  isLoading: false,
  isModalOpen: false,
  totalRegistros: 0,
  pedidoCompra: [],
  open: false,
  id: 0,
  idLoja: '',
  idFornecedor: '',
  dataHora: '',
  dataHoraEnvio: '',
  situacao: '',
  numero: '',
  excluido: '',
  loja: null,
  fornecedor: null,
  lojaNome: '',
  lojaCnpj: '',
  fornecedorNome: '',
  fornecedorIntegracao: '',
  fornecedorIntegracaoArquivo: '',
  fornecedorEmail: '',
  pedidosComprasProdutos: [],
  page: 0,
  limit: 10,
  order: 'dataHora',
  direction: 'desc',
  term: ''
}

export function pedidoCompra(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PEDIDO_COMPRA':
      return {
        ...state,
        pedidoCompra: action.pedidoCompra,
        totalRegistros: action.totalRegistros
      }
    case 'PEDIDO_COMPRA_DETAIL':
      return {
        ...state,
        isLoading: false,
        isModalOpen: false,
        id: action.id,
        idLoja: action.idLoja,
        idFornecedor: action.idFornecedor,
        dataHora: action.dataHora,
        dataHoraEnvio: action.dataHoraEnvio,
        situacao: action.situacao,
        numero: action.numero,
        excluido: action.excluido,
        loja: action.loja,
        fornecedor: action.fornecedor,
        lojaNome: action.lojaNome,
        lojaCnpj: action.lojaCnpj,
        fornecedorNome: action.fornecedorNome,
        fornecedorIntegracao: action.fornecedorIntegracao,
        fornecedorIntegracaoArquivo: action.fornecedorIntegracaoArquivo,
        fornecedorEmail: action.fornecedorEmail,
        pedidosComprasProdutos: action.pedidosComprasProdutos
      }
    case 'PEDIDO_COMPRA_UPDATED':
      return state
    case 'PEDIDO_COMPRA_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'PEDIDO_COMPRA_CLEAR_ALL':
      return {
        ...state,
        pedidoCompra: []
      }
    case 'PEDIDO_COMPRA_CLEAR_PEDIDOSCOMPRASPRODUTOS':
      return {
        ...state,
        pedidosComprasProdutos: []
      }
    case 'PEDIDO_COMPRA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'PEDIDO_COMPRA_HANDLE_ON_CHANGE_PRODUTOS':
      return {
        ...state,
        pedidosComprasProdutos: Object.assign([action.props], action.value)
      }
    case 'PEDIDO_COMPRA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'PEDIDO_COMPRA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'PEDIDO_COMPRA_ISMODALOPEN':
      return {
        ...state,
        isModalOpen: true
      }
    case 'PEDIDO_COMPRA_NOTISMODALOPEN':
      return {
        ...state,
        isModalOpen: false
      }
    default:
      return state
  }
}
