import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const checklistTotvsInsumoUnidadeAction = {
  getChecklistTotvsInsumoUnidadeCategoriaInsumo,
  onChangeProps,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getChecklistTotvsInsumoUnidadeCategoriaInsumo(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'checklistsTotvsInsumosUnidades/categoriaInsumo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeChecklistTotvsInsumoUnidadesList(response.data, null))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'checklistsTotvsInsumosUnidades') {
      //dispatch(changeChecklistTotvsInsumoUnidadesList(event, 0))
      dispatch(handleOnChangeProps('checklistTotvsInsumoUnidade', event))
    } else {
      //if (event.target.type === 'checkbox') {
      //  dispatch(handleOnChangeProps(props, event.target.checked))
      //} else {
      //  dispatch(handleOnChangeProps(props, event.target.value))
      //}
    }
  }
}

export function changeChecklistTotvsInsumoUnidadesList(checklistTotvsInsumoUnidade, totalRows) {
  return {
    type: 'FETCHED_ALL_CHECKLIST_TOTVS_INSUMO_UNIDADE',
    checklistTotvsInsumoUnidade: checklistTotvsInsumoUnidade,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CHECKLIST_TOTVS_INSUMO_UNIDADE_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CHECKLIST_TOTVS_INSUMO_UNIDADE_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CHECKLIST_TOTVS_INSUMO_UNIDADE_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function isLoading() {
  return {
    type: 'CHECKLIST_TOTVS_INSUMO_UNIDADE_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CHECKLIST_TOTVS_INSUMO_UNIDADE_NOTISLOADING'
  }
}
