import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { pedidoCompraAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination
} from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import EditIcon from '@material-ui/icons/Edit'
import SendIcon from '@material-ui/icons/Send'
import ShareIcon from '@material-ui/icons/Share'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import { PedidoCompraModal } from './pedidoCompraModal.component';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const PedidoCompra = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { pedidoCompra } = props.pedidoCompra
  const { isLoading } = props.pedidoCompra
  const { totalRegistros } = props.pedidoCompra
  const { page, limit, term, direction, order } = useSelector(state => state.pedidoCompra);
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const [debounceTerm] = useDebounce(term, 1000)

  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState(0);
  const toggle = () => setModal(!modal);

  useEffect(() => {

    const fakeEvent = {
      type: 'text',
      target: 'dataHora'
    }
    dispatch(pedidoCompraAction.onChangeProps('order', fakeEvent))

    const fakeEvent2 = {
      type: 'text',
      target: 'desc'
    }
    dispatch(pedidoCompraAction.onChangeProps('direction', fakeEvent2))

  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm, modal]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'dataHora',
      Direction: direction || 'desc',
      IdLoja: (idLoja && idLoja !== '') ? idLoja : 0
    };
    dispatch(pedidoCompraAction.getPedidoCompra(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(pedidoCompraAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(pedidoCompraAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.pedidoCompra.page = 0;
    dispatch(pedidoCompraAction.onChangeProps('term', fakeEvent))
  }

  const handleDeletePedidoCompra = (id) => {
    confirm({
      title: 'Você deseja excluir este Pedido de Compra?',
      description: 'Essa operação é irreversível',
      disabled: props.pedidoCompra.isLoading,
      confirmationText: props.pedidoCompra.isLoading ? 'Excluindo Pedido de Compra...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(pedidoCompraAction.deletePedidoCompraById(id))
    })
  }

  const handleDeletePedidoCompraAdmin = (id) => {
    confirm({
      title: 'Você deseja excluir este Pedido de Compra?',
      description: 'Essa operação é irreversível',
      disabled: props.pedidoCompra.isLoading,
      confirmationText: props.pedidoCompra.isLoading ? 'Excluindo Pedido de Compra...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(pedidoCompraAction.deletePedidoCompraAdminById(id))
    })
  }

  const handleDuplicate = (id) => {
    confirm({
      title: 'Você deseja duplicar este Pedido de Compra?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, duplicar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(pedidoCompraAction.duplicatePedidoCompra(id))
    })
  }

  //const handleSend = (id) => {
  //  confirm({
  //    title: 'Você deseja enviar este Pedido de Compra?',
  //    description: 'Essa operação é irreversível',
  //    confirmationText: 'Sim, enviar!',
  //    cancellationText: 'Não!'
  //  }).then(() => {
  //    dispatch(pedidoCompraAction.sendPedidoCompra(id))
  //  })
  //}

  const handleCopy = (id) => {
    setModalId(id);
    toggle();
  }

  return (
    <Page className={classes.root} title="Pedidos de Compras">
      <AccessControl
        rule={'pedidosCompras'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== pedidoCompra && pedidoCompra.length > 0 ? (
              <TitleContent
                rule={'pedidosCompras.create'}
                length={pedidoCompra.length}
                subTitle={'GERÊNCIA DE PEDIDO DE COMPRA'}
                title={'Lista de Pedidos de Compras'}
                href={'/pedidoCompra'}
              />
            ) : (
              <TitleContent
                rule={'pedidosCompras.create'}
                length={0}
                subTitle={'GERÊNCIA DE PEDIDO DE COMPRA'}
                title={'Lista de Pedidos de Compras'}
                href={'/pedidoCompra'}
              />
            )}
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data/Hora</TableCell>
                    {/*<TableCell>Data/Hora Envio</TableCell>*/}
                    {/*<TableCell>Número</TableCell>*/}
                    <TableCell>Loja</TableCell>
                    <TableCell>Fornecedor</TableCell>
                    <TableCell>Situação</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== pedidoCompra && pedidoCompra.length
                    ? pedidoCompra.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {moment(n.dataHora).format("DD/MM/YYYY HH:mm")}
                          </TableCell>
                          {/*<TableCell component="th" scope="row">*/}
                          {/*  {(n.dataHoraEnvio != null ? moment(n.dataHoraEnvio).format("DD/MM/YYYY HH:mm") : '')}*/}
                          {/*</TableCell>*/}
                          {/*<TableCell component="th" scope="row">*/}
                          {/*  {(n.numero ? n.numero : '')}*/}
                          {/*</TableCell>*/}
                          <TableCell component="th" scope="row">
                            {n.lojaNome || ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.fornecedorNome || ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {(n.situacao == 'A' ? 'ATENDIDO' : (n.situacao == 'E' ? 'ENVIADO' : (n.situacao == 'N' ? 'PENDENTE' : (n.situacao == 'R' ? 'RECEBIDO' : ''))))}
                          </TableCell>
                          <TableCell align="right">
                            {/*<AccessControl*/}
                            {/*  rule={'pedidosCompras.edit'} //permissão necessária para acessar conteúdo*/}
                            {/*  yes={() => (*/}
                            {/*    <IconButton*/}
                            {/*      aria-label="Duplicate"*/}
                            {/*      component="a"*/}
                            {/*      onClick={() => handleDuplicate(n.id)}*/}
                            {/*      size="small"*/}
                            {/*    >*/}
                            {/*      <Tooltip title="Duplicar e editar">*/}
                            {/*        <Tooltip>*/}
                            {/*          <FileCopyIcon />*/}
                            {/*        </Tooltip>*/}
                            {/*      </Tooltip>*/}
                            {/*    </IconButton>*/}
                            {/*  )}*/}
                            {/*/>*/}
                            <AccessControl
                              rule={'pedidosCompras.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/pedidoCompra/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              //permissão para os usuários tipo 2
                              rule={'pedidosCompras.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleDeletePedidoCompra(n.id)}
                                  size="small"
                                  disabled={n.dataHoraEnvio != null || n.situacao == 'A' || n.situacao == 'E' || n.situacao == 'R'}
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              //permissão para os usuários tipo 1
                              rule={'pedidosCompras.deleteAdmin'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleDeletePedidoCompraAdmin(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'pedidosCompras.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Send"
                                  component="a"
                                  onClick={() => handleCopy(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Revisar e enviar">
                                    <Tooltip>
                                      <SendIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 6 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )

                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />

      {(modal ?
        <PedidoCompraModal toggle={toggle} modal={modal} props={props} id={modalId} />
        :
        null
      )}

    </Page>
  )
}
PedidoCompra.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    pedidoCompra: state.pedidoCompra
  }
}
const connectedPedidoCompraPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(PedidoCompra))
)
export { connectedPedidoCompraPage as PedidoCompra }
