import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import {
  Input,
  Grid,
  Table,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  CircularProgress
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { history } from '../../../../utils'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Skeleton } from "@material-ui/lab";
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { clienteAction } from '../../../../_actions'
import { useDebounce } from 'use-debounce';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const ClientesInfoModal = ({ modal, toggle, dataInicio, dataFim, dia1, setDia1, dia2, setDia2 }, props) => {

  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 2000);
  const [order, setOrder] = useState('nomeCliente');
  const [direction, setDirection] = useState('asc');
  const formRef = React.createRef(null);

  const { cliente, totalRegistros, isLoading } = useSelector(
    (state) => state.cliente
  )

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: idLoja,
        Dia1: dia1,
        Dia2: dia2,
        DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        DataFim: moment(dataFim).format("YYYY-MM-DD")
      };

      dispatch(clienteAction.clear())
      dispatch(clienteAction.getFidelizacoesByDias(filterModel, true));
    }

    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction, dia1, dia2]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  const handleSubmit = (event) => {

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdLoja: idLoja,
      Dia1: dia1,
      Dia2: dia2,
      DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataFim: moment(dataFim).format("YYYY-MM-DD")
    };

    dispatch(clienteAction.clear())
    dispatch(clienteAction.getFidelizacoesByDias(filterModel, true));

  };

  return (
    <Dialog
      open={modal}
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="form-dialog-title">
        Clientes que estiveram na loja no período de: {moment(dataInicio).format("DD/MM/YYYY")}  a  {moment(dataFim).format("DD/MM/YYYY")}
        <ValidatorForm
          ref={formRef}
          id="formGrupo"
          onSubmit={(event) => handleSubmit(event)}
        >
          <Grid container style={{ alignItems: 'end', alignContent: 'end', display: 'flex', flexDirection: 'row' }} spacing={2}>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TextValidator
                id="dia1"
                label="Dia 1"
                onChange={(event) => setDia1(event.target.value)}
                value={dia1}
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TextValidator
                id="dia2"
                label="Dia 2"
                onChange={(event) => setDia2(event.target.value)}
                value={dia2}
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item lg={3} md={3} xl={3} xs={2}>
              <Button
                color="primary"
                type="submit"
                size="small"
                variant="contained"
                disabled={isLoading}
                startIcon={<SearchTwoToneIcon fontSize="small" />}
              >
                Pesquisar
              </Button>
            </Grid>
            <Grid item lg={3} md={3} xl={3} xs={12} align="right">
              {isLoading && <CircularProgress size={25} />}
            </Grid>
          </Grid>
        </ValidatorForm>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Pesquisar por cliente"
                value={term}
                onChange={handleChange('term')}
              />
            </Grid>
            <br />
            <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>E-mail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(undefined !== cliente && cliente.length
                      ? cliente.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" style={{ cursor: 'pointer' }} scope="row" onClick={() => history.push(window.open(`/cliente/${n.id}`))}>
                              {n.nomeCliente ? n.nomeCliente.toUpperCase() : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.email ? n.email : ''}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      :
                      (
                        isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                      )
                    )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalRegistros}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </Grid>
          </>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          style={{ padding: 5 }}
          spacing={2}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={toggle} variant="outlined" color="primary">
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
