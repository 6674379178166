import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { relatorioAction } from '../../_actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import {
  Card,
  CardContent,
  CircularProgress,
  Button,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import SearchIcon from '@material-ui/icons/Search';
import { Page } from 'components'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  gridButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    textAlign: 'right'
  },
  selectedLoja: {
    fontWeight: 700
  },
  negative: {
    backgroundColor: '#FFEBEE'
  }
})

const RelatorioPonto = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { isLoading } = props.relatorio

  const [dataInicial, setDataInicial] = React.useState(undefined)
  const [dataFinal, setDataFinal] = React.useState(undefined)

  useEffect(() => {
    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();

    setDataInicial(moment(dataIni).format("YYYY-MM-DD"));
    setDataFinal(moment(dataFi).format("YYYY-MM-DD"));

    dispatch(relatorioAction.clear());
  }, []);

  const handleSubmit = (event) => {
    if (dataInicial != undefined && dataFinal != undefined) {

      if (dataFinal > dataInicial) {
        let payload = {
          IdLoja: 0,
          DataInicio: moment(dataInicial).format("YYYY-MM-DD"),
          DataFim: moment(dataFinal).format("YYYY-MM-DD"),
        };

        dispatch(relatorioAction.getRelatorioPonto(payload, true));
      }
      else {
        toast.error('Data final deve ser maior que a Data inicial!')
      }
    } else {
      dispatch(relatorioAction.clear());
    }
  };

  const handleChangeDataInicial = (e) => {
    setDataInicial(e.target.value)
  }

  const handleChangeDataFinal = (e) => {
    setDataFinal(e.target.value)
  }

  const setCellStyle = (n) => {
    let cellStyle = ''

    if (props.usuarioLoja.loja.nome == n.loja) {
      cellStyle += classes.selectedLoja
    }

    if (n.totalDiferenca < 0) {
      cellStyle += " " + classes.negative
    }

    return cellStyle
  }

  return (
    <Page className={classes.root} title="Relatório - Pontos por Período">
      <AccessControl
        rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioPontos"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Pontos por Período'}
                    />
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Início *"
                          type="date"
                          value={dataInicial}
                          onChange={handleChangeDataInicial}
                          id="dataInicial"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Final *"
                          type="date"
                          value={dataFinal}
                          onChange={handleChangeDataFinal}
                          id="dataFinal"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} xs={12} style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Posição</TableCell>
                        <TableCell>Loja</TableCell>
                        <TableCell>Tipo de Integração</TableCell>
                        <TableCell align="right">Total Pontos Fidelizados</TableCell>
                        <TableCell align="right">Total Pontos Trocados</TableCell>
                        <TableCell align="right">Diferença</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n, i) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row" className={setCellStyle(n)}>
                                {(i + 1) + 'º'}
                              </TableCell>
                              <TableCell component="th" scope="row" className={setCellStyle(n)}>
                                {n.loja || ''}
                              </TableCell>
                              <TableCell component="th" scope="row" className={setCellStyle(n)}>
                                {n.tipoIntegracao || ''}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right" className={setCellStyle(n)}>
                                {n.totalPontosFidelizados || 0}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right" className={setCellStyle(n)}>
                                {n.totalPontosTrocados || 0}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right" className={setCellStyle(n)}>
                                {n.totalDiferenca || 0}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (dataInicial != undefined && dataFinal != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 5 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={7} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RelatorioPonto.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedRelatorioPontoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioPonto))
)
export { connectedRelatorioPontoPage as RelatorioPonto }
