import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import { pontuacaoAction } from '_actions'

export const fidelizacaoAction = {
  getFidelizacao,
  addFidelizacao,
  getFidelizacaoById,
  createFidelizacaoManualmente,
  onChangeProps,
  /*  editFidelizacaoInfo,*/
  editFidelizacaosDetails,
  //createFidelizacao,
  deleteFidelizacaoById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getFidelizacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'fidelizacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeFidelizacaosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getFidelizacaoByLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'fidelizacoes/loja/' + id;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeFidelizacaosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createFidelizacaoManualmente(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'fidelizacoes/app'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        toast.success('Feito! Pontos contabilizados com sucesso! 😎')

        let filterModel = {
          Page: 0,
          Limit: 10,
          Term: '',
          Direction: 'desc',
          IdCliente: payload.IdCliente
        };

        dispatch(pontuacaoAction.getPontuacao(filterModel))
      })
      .catch((err) => {
        console.log('err', err)
        dispatch(failure(err))
      })
  }
}

function request(id) {
  return { type: 'EDIT_FIDELIZACAO_REQUEST', id }
}

function failure(error) {
  return { type: 'EDIT_FIDELIZACAO_FAILURE', error }
}

//function createFidelizacao(payload) {
//  return (dispatch) => {
//  //  console.log('entrou na action');
//    let apiEndpoint = 'fidelizacoes/'
//    crudService.post(apiEndpoint, payload).then(() => {
//      dispatch(createFidelizacaoInfo())
//      toast.success('Feito! Cadastro realizado com sucesso! 😎')
//      dispatch(clear())
//      history.push('/fidelizacoes')
//    })
//  }
//}

function getFidelizacaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'fidelizacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      // console.log('response.data', response.data)
      dispatch(editFidelizacaosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

//function editFidelizacaoInfo(id, payload, redirect) {
//  return (dispatch) => {
//    let apiEndpoint = 'fidelizacoes/' + id
//    crudService.put(apiEndpoint, payload).then(() => {
//      dispatch(updatedFidelizacaoInfo())
//      dispatch(clear())
//      if (redirect) {
//        history.push('/fidelizacoes')
//      }
//    })
//  }
//}

function deleteFidelizacaoById(filterModel, id) {
  return (dispatch) => {
    let apiEndpoint = 'fidelizacoes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteFidelizacaosDetails())
      dispatch(getFidelizacao(filterModel))
    })
  }
}

export function addFidelizacao(payload) {
  return [{ type: 'ADD_FIDELIZACAO', fidelizacao: payload }, clear()]
}

export function changeFidelizacaosList(fidelizacao, totalRows) {
  return {
    type: 'FETCHED_ALL_FIDELIZACAO',
    fidelizacao: fidelizacao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'FIDELIZACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'FIDELIZACAO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'FIDELIZACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editFidelizacaosDetails(fidelizacao) {
  return [
    { type: 'FIDELIZACAO_DETAIL', fidelizacao },
  ]
}

export function updatedFidelizacaoInfo() {
  return {
    type: 'FIDELIZACAO_UPDATED'
  }
}

export function createFidelizacaoInfo() {
  return {
    type: 'FIDELIZACAO_CREATED_SUCCESSFULLY'
  }
}

export function deleteFidelizacaosDetails() {
  return {
    type: 'DELETED_FIDELIZACAO_DETAILS'
  }
}
