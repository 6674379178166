import React, { useLayoutEffect } from 'react'
import { Router } from 'react-router-dom'
import { Chart } from 'react-chartjs-2'
import { ThemeProvider } from '@material-ui/styles'
import validate from 'validate.js'
import { ConfirmProvider } from 'material-ui-confirm'
import './App.css'
import { chartjs } from './utils'
import theme from './theme'
import './assets/scss/index.scss'
import validators from './common/validators'
import Routes from './Routes'
import Mensagens from './components/Utils'
import { authActions } from './_actions'
import { useDispatch } from 'react-redux'
import { history } from './utils'

//importação para datas e time
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import brLocale from 'date-fns/locale/pt-BR'

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
})

validate.validators = {
  ...validate.validators,
  ...validators
}

const App = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(authActions.refresh())
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale} key={1333}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <div className="App">
            <Mensagens />
          </div>
          <Router history={history} >
            <Routes />
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}
export default App
