import React, { useState, useEffect } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Input
} from '@material-ui/core'
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import { notificacaoAction, notificacaoFiltroAction, lojaAction, produtoAction, grupoAction, clienteAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce';
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalNotificacaoFiltrosItens = (props) => {

  const [selectedItems, setSelectedItems] = useState([])
  const dispatch = useDispatch()
  const { classes } = props
  const formRef = React.createRef(null);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order, setOrder] = useState('nome');
  const [direction, setDirection] = useState('asc');

  const { openModalNotificacaoFiltrosItens, loading } = useSelector(
    (state) => state.notificacao
  )

  const { loja } = useSelector(
    (state) => state.loja
  )

  const isLoadingLoja = useSelector(
    (state) => state.loja.isLoading
  )

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const totalRegistrosLojas = useSelector(
    (state) => state.loja.totalRegistros
  )

  const { produto } = useSelector(
    (state) => state.produto
  )

  const isLoadingProduto = useSelector(
    (state) => state.produto.isLoading
  )

  const totalRegistrosProdutos = useSelector(
    (state) => state.produto.totalRegistros
  )

  const { grupo } = useSelector(
    (state) => state.grupo
  )

  const { cliente } = useSelector(
    (state) => state.cliente
  )

  const isLoadingGrupo = useSelector(
    (state) => state.grupo.isLoading
  )

  const totalRegistrosGrupos = useSelector(
    (state) => state.grupo.totalRegistros
  )

  const fetchData = (page, limit, term) => {

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
    };

    if (props.notificacaoFiltro.filtro === 'lojasFidelizacoes' || props.notificacaoFiltro.filtro === 'lojasTrocas') {
      dispatch(lojaAction.getLoja(filterModel, true));
    }
    else if (props.notificacaoFiltro.filtro === 'produtosFidelizacoes' || props.notificacaoFiltro.filtro === 'produtosTrocas') {
      dispatch(produtoAction.getProduto(filterModel, true));
    }
    else if (props.notificacaoFiltro.filtro === 'gruposFidelizacoes' || props.notificacaoFiltro.filtro === 'gruposTrocas') {
      dispatch(grupoAction.getGrupo(filterModel, true));
    }

    else {
      dispatch(lojaAction.clear())
      dispatch(produtoAction.clear())
      dispatch(grupoAction.clear())
    }

  };

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
    setSelectedItems([])
  }, [props.notificacaoFiltro.filtro]);

  useEffect(() => {

  }, [totalRegistrosLojas, totalRegistrosProdutos, totalRegistrosGrupos]);

  useEffect(() => {
    setSelectedItems([])
  }, [openModalNotificacaoFiltrosItens]);

  const handleClose = () => {
    dispatch(notificacaoAction.openModalNotificacaoFiltrosItens(false))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      id: 0,
      idNotificacao: props.notificacao.id,
      filtro: props.notificacaoFiltro.filtro,
      notificacoesFiltrosItens: selectedItems ? selectedItems : []
    }

    dispatch(notificacaoFiltroAction.createNotificacaoFiltro(payload))
    dispatch(notificacaoAction.openModalNotificacaoFiltrosItens(false))

  }

  const handleClick = (id) => {
    const isSelected = selectedItems.some((n) => n.chave == id)
    if (isSelected) {
      const newSelected = selectedItems.filter((n) => n.chave !== id)
      setSelectedItems(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idNotificaoFiltro: 0,
        chave: id
      }
      setSelectedItems([...selectedItems, newSelecteds])
    }
  }

  useEffect(() => {

  }, [selectedItems])

  const isItemSelected = (id) => {
    return selectedItems.some((n) => n.chave == id)
  }

  const handleChangeNotificacaoFiltro = (prop) => (event) => {
    dispatch(notificacaoFiltroAction.onChangeProps(prop, event))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Dialog
      open={openModalNotificacaoFiltrosItens}
      onClose={handleClose}
      aria-labelledby="Descrição tipo de ingresso"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Seleção de itens para o filtro
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <ValidatorForm
            className={classes.form}
            ref={formRef}
            id="formNotificacao"
            onSubmit={(event) => handleSubmit(event)}
          >
            <Grid container>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <SelectValidator
                  className={classes.textField}
                  validators={['required']}
                  label="Filtro"
                  labelId="filtro"
                  errorMessages={['Campo obrigatório!']}
                  value={props.notificacaoFiltro.filtro || ''}
                  onChange={handleChangeNotificacaoFiltro('filtro')}
                  name="filtro"
                  fullWidth
                  inputProps={{
                    name: "Filtro",
                    id: "filtro"
                  }}
                  input={<Input id="filtro" />}
                  margin="normal"
                  variant="outlined"
                  id="filtro"
                >
                  <MenuItem value={'usuariosCadastradosApp'}>Todos usuários cadastrados no App</MenuItem>
                  <MenuItem value={'mulheresCadastradasApp'}>Todos usuários do sexo feminino cadastrados no App</MenuItem>
                  <MenuItem value={'homensCadastradosApp'}>Todos usuários do sexo masculino cadastrados no App</MenuItem>
                  <MenuItem value={'lojasFidelizacoes'}>Usuários que fidelizaram nas Lojas</MenuItem>
                  <MenuItem value={'lojasTrocas'}>Usuários que realizaram trocas nas Lojas</MenuItem>
                  <MenuItem value={'produtosFidelizacoes'}>Usuários que fidelizaram os Produtos</MenuItem>
                  <MenuItem value={'produtosTrocas'}>Usuários que trocaram pelos Produtos</MenuItem>
                  <MenuItem value={'gruposFidelizacoes'}>Usuários que fidelizaram os Grupos</MenuItem>
                  <MenuItem value={'gruposTrocas'}>Usuários que trocaram pelos Grupos</MenuItem>
                </SelectValidator>
              </Grid>
              {props.notificacaoFiltro.filtro !== 'usuariosCadastradosApp' ?
                <>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Input
                      id="term"
                      label="term"
                      placeholder="Filtro"
                      value={term || ''}
                      onChange={handleChange('term')}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(props.notificacaoFiltro.filtro === 'lojasFidelizacoes' || props.notificacaoFiltro.filtro === 'lojasTrocas') ?
                            (undefined !== loja && loja.length
                              ? loja.map((n) => {
                                return (
                                  <TableRow hover key={n.id}>
                                    <TableCell component="th" scope="row">
                                      {n.nome}
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={() => handleClick(n.id)}
                                        color="primary"
                                        checked={isItemSelected(n.id)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              : (
                                isLoadingLoja ?
                                  Array.apply(null, { length: 10 }).map((e, i) => (
                                    <TableRow hover>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                  :
                                  <TableRow>
                                    <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                                  </TableRow>
                              )
                            )
                            : (props.notificacaoFiltro.filtro === 'produtosFidelizacoes' || props.notificacaoFiltro.filtro === 'produtosTrocas') ?
                              (undefined !== produto && produto.length
                                ? produto.map((n) => {
                                  return (
                                    <TableRow hover key={n.id}>
                                      <TableCell component="th" scope="row">
                                        {n.nome}
                                      </TableCell>
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          onClick={() => handleClick(n.id)}
                                          color="primary"
                                          checked={isItemSelected(n.id)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                                :
                                (
                                  isLoadingProduto ?
                                    Array.apply(null, { length: 10 }).map((e, i) => (
                                      <TableRow hover>
                                        <TableCell component="th" scope="row">
                                          <Skeleton animation="wave" />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Skeleton animation="wave" />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                    :
                                    <TableRow>
                                      <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                                    </TableRow>
                                )
                              )
                              : (props.notificacaoFiltro.filtro === 'gruposFidelizacoes' || props.notificacaoFiltro.filtro === 'gruposTrocas') ?
                                (undefined !== grupo && grupo.length
                                  ? grupo.map((n) => {
                                    return (
                                      <TableRow hover key={n.id}>
                                        <TableCell component="th" scope="row">
                                          {n.nome}
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            onClick={() => handleClick(n.id)}
                                            color="primary"
                                            checked={isItemSelected(n.id)}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })
                                  :
                                  (
                                    isLoadingGrupo ?
                                      Array.apply(null, { length: 10 }).map((e, i) => (
                                        <TableRow hover>
                                          <TableCell component="th" scope="row">
                                            <Skeleton animation="wave" />
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            <Skeleton animation="wave" />
                                          </TableCell>
                                        </TableRow>
                                      ))
                                      :
                                      <TableRow>
                                        <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                                      </TableRow>
                                  )
                                )
                                : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      count={((props.notificacaoFiltro.filtro === 'lojasFidelizacoes' ||
                        props.notificacaoFiltro.filtro === 'lojasTrocas') ? totalRegistrosLojas
                        : (props.notificacaoFiltro.filtro === 'produtosFidelizacoes' || props.notificacaoFiltro.filtro === 'produtosTrocas') ? totalRegistrosProdutos
                          : (props.notificacaoFiltro.filtro === 'gruposFidelizacoes' ||
                            props.notificacaoFiltro.filtro === 'gruposTrocas') ? totalRegistrosGrupos
                            : 0)}

                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeLimit}
                    />
                  </Grid>
                </>
                : null}
            </Grid>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Grid
            style={{ padding: 5 }}
            spacing={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={loading && <CircularProgress size={24} />}
              >
                {loading
                  ? 'Salvando...'
                  : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ModalNotificacaoFiltrosItens.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalNotificacaoFiltrosItensPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ModalNotificacaoFiltrosItens))
)
export { connectedModalNotificacaoFiltrosItensPage as ModalNotificacaoFiltrosItens }
