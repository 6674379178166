const initialState = {
  isLoading: false,
  totalRegistros: 0,
  categoriaInsumo: [],
  open: false,
  id: 0,
  nome: '',
  excluido: false,
}

export function categoriaInsumo(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CATEGORIAINSUMO':
      return {
        ...state,
        categoriaInsumo: action.categoriaInsumo,
        totalRegistros: action.totalRegistros
      }
    case 'CATEGORIAINSUMO_DETAIL':
      return action.categoriaInsumo
    case 'CATEGORIAINSUMO_UPDATED':
      return state
    case 'CATEGORIAINSUMO_CLEAR':
      return initialState
    case 'CATEGORIAINSUMO_CLEAR_ALL':
      return {
        ...state,
        categoriaInsumo: []
      }
    case 'CATEGORIAINSUMO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CATEGORIAINSUMO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CATEGORIAINSUMO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
