import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const dashboardAction = {
  getDashboardHome,
  getDashboardLoja,
  getDashboardLojaProdutosFidelizacoes,
  getDashboardLojaProdutosTrocas,
  getDashboardLojaClientesFidelizacoes,
  getDashboardLojaClientesAniversariantes,
  getDashboardLojaClientesSexo,
  getDashboardLojaClientesFaixaEtaria,
  getDashboardLojaClientesRecorrenciaDias,
  clear
}

function getDashboardHome(lojaId) {
  return (dispatch) => {
    dispatch(request({ lojaId }))
    let apiEndpoint = 'dashboard/loja/home/' + lojaId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return { type: 'FETCHED_DASHBOARD_HOME_REQUEST', lojaId }
  }

  function success(dashboardHomeResponse) {
    return {
      type: 'FETCHED_DASHBOARD_HOME_SUCCESS',
      dashboardHome: dashboardHomeResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_HOME_FAILURE', error }
  }
}

function getDashboardLoja(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_REQUEST',
      lojaId
    }
  }

  function success(data) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_SUCCESS',
      dashboardLoja: data.dashboardLoja,
      mensagens: data.mensagens
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_FAILURE', error }
  }

}

function getDashboardLojaProdutosFidelizacoes(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/produtos/fidelizacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_PRODUTOS_FIDELIZACOES_REQUEST',
      lojaId
    }
  }

  function success(topProdutosFidelizacoes, totalProdutosFidelizacoes) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_PRODUTOS_FIDELIZACOES_SUCCESS',
      topProdutosFidelizacoes: topProdutosFidelizacoes,
      totalProdutosFidelizacoes: totalProdutosFidelizacoes
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_PRODUTOS_FIDELIZACOES_FAILURE', error }
  }

}

function getDashboardLojaProdutosTrocas(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/produtos/trocas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_PRODUTOS_TROCAS_REQUEST',
      lojaId
    }
  }

  function success(topProdutosTrocas, totalProdutosTrocas) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_PRODUTOS_TROCAS_SUCCESS',
      topProdutosTrocas: topProdutosTrocas,
      totalProdutosTrocas: totalProdutosTrocas
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_PRODUTOS_TROCAS_FAILURE', error }
  }

}

function getDashboardLojaClientesFidelizacoes(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/clientes/fidelizacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_FIDELIZACOES_REQUEST',
      lojaId
    }
  }

  function success(topClientesFidelizacoes, totalClientesFidelizacoes) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_FIDELIZACOES_SUCCESS',
      topClientesFidelizacoes: topClientesFidelizacoes,
      totalClientesFidelizacoes: totalClientesFidelizacoes
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_FIDELIZACOES_FAILURE', error }
  }

}

function getDashboardLojaClientesAniversariantes(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/clientes/aniversariantes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_ANIVERSARIANTES_REQUEST',
      lojaId
    }
  }

  function success(clientesAniversariantes, totalClientesAniversariantes) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_ANIVERSARIANTES_SUCCESS',
      clientesAniversariantes: clientesAniversariantes,
      totalClientesAniversariantes: totalClientesAniversariantes
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_ANIVERSARIANTES_FAILURE', error }
  }

}


function getDashboardLojaClientesSexo(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/clientes/sexo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_SEXO_REQUEST',
      lojaId
    }
  }

  function success(clientesSexo, totalClientesSexo) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_SEXO_SUCCESS',
      clientesSexo: clientesSexo,
      totalClientesSexo: totalClientesSexo
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_SEXO_FAILURE', error }
  }

}

function getDashboardLojaClientesFaixaEtaria(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/clientes/faixaetaria' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_FAIXAETARIA_REQUEST',
      lojaId
    }
  }

  function success(clientesFaixaEtaria, totalClientesFaixaEtaria) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_FAIXAETARIA_SUCCESS',
      clientesFaixaEtaria: clientesFaixaEtaria,
      totalClientesFaixaEtaria: totalClientesFaixaEtaria
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_FAIXAETARIA_FAILURE', error }
  }

}

function getDashboardLojaClientesRecorrenciaDias(filterModel) {
  return (dispatch) => {
    dispatch(request(filterModel.IdLoja))
    let apiEndpoint = 'dashboard/loja/clientes/recorrenciaDias' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request(lojaId) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_RECORRENCIA_DIAS_REQUEST',
      lojaId
    }
  }

  function success(clientesRecorrenciaDias, totalClientesRecorrenciaDias) {
    return {
      type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_RECORRENCIA_DIAS_SUCCESS',
      clientesRecorrenciaDias: clientesRecorrenciaDias,
      totalClientesRecorrenciaDias: totalClientesRecorrenciaDias
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_LOJA_CLIENTES_RECORRENCIA_DIAS_FAILURE', error }
  }

}

export function clear() {
  return {
    type: 'DASHBOARD_CLEAR'
  }
}
