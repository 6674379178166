import React, { createRef, useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  CircularProgress,
  Grid,
  TextField
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { cnpjMask, history } from '../../utils'
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { fornecedorAction, fornecedorFilialAction } from '../../_actions'
import { AccessControl } from '../../components/Utils/AccessControl'
import DialogActions from '@material-ui/core/DialogActions';
import { StickyContainer, Sticky } from 'react-sticky';
import SaveIcon from '@material-ui/icons/Save'

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const CompraClassificarFornecedorModal = ({ modal, toggle, cnpjFornecedor, fornecedorFilial, idCompra }, props) => {

  const dispatch = useDispatch()
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { className, ...rest } = props
  const classes = props
  const [open, setOpen] = useState(false)

  const formRef = createRef(null);

  const { fornecedor } = useSelector(state => state.fornecedor)

  const options = Array.isArray(fornecedor) ? fornecedor?.map(u => ({ id: u.value, nome: u.text })) : [];

  useEffect(() => {
    dispatch(fornecedorAction.getFornecedorSelect())
  }, [])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selectedFornecedor = fornecedor.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        fornecedorAction.changeDetailsFornecedor(
          selectedFornecedor[0]
        )
      )
    } else {

    }
    setOpen(false)
  }


  const handleSubmit = (event) => {
    let payload = {
      idFornecedor: value.id,
      cnpj: cnpjFornecedor
    }

    if (payload) {
      dispatch(fornecedorFilialAction.createFornecedorFilial(payload, idCompra))
      toggle()
    }
  };

  return (
    <Dialog
      open={modal}
      onClose={toggle}
      maxWidth='md'
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              CLASSIFICAR FORNECEDOR
            </Typography>
          </Grid>
          <AccessControl
            rule={'fornecedores.list'}
            yes={() => (
              <Grid item xs={6} align="right">
                <Button
                  color="default"
                  variant="contained"
                  endIcon={<CloseIcon />}
                  onClick={toggle}
                >
                  Fechar
                </Button>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles}>
        <ValidatorForm
          ref={formRef}
          id="formClassificarFornecedor"
          onSubmit={(event) => handleSubmit(event)}
        >
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item lg={4} md={4} xl={4} xs={12}>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                margin="normal"
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                defaultValue={null}
                getOptionLabel={option => option.nome ? option.nome : ''}
                renderInput={(params) => <TextField {...params} label="Selecione um fornecedor *" variant="outlined" fullWidth />}
                style={{ width: 312, marginTop: 9 }}
                fullWidth
              />
            </Grid>
            <Grid item lg={4} md={4} xl={4} xs={12}>
              <TextValidator
                style={{ marginLeft: 10 }}
                fullWidth
                id="cnpjFornecedor"
                label="CNPJ Fornecedor *"
                margin="normal"
                value={cnpjMask(cnpjFornecedor)}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} xl={3} xs={12}>
              <Button
                color="primary"
                className={classes.textField}
                type="submit"
                variant="contained"
                disabled={fornecedorFilial.isLoading}
                startIcon={<SaveIcon />}
                endIcon={fornecedorFilial.isLoading && <CircularProgress size={24} />}
              >
                {fornecedorFilial.isLoading ? 'Salvando...' : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </DialogContent>
      <DialogActions>
        <StickyContainer>
          <Sticky relative={false} isSticky={true}>
            {({ style }) => (
              <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between", marginRight: 12 }} spacing={3}>

              </Grid>
            )}
          </Sticky>
        </StickyContainer>
      </DialogActions>
    </Dialog >
  );
}
