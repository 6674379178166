const initialState = {
  isLoading: false,
  totalRegistros: 0,
  campanha: [],
  id: 0,
  nome: '',
  dataHoraInicio: '',
  dataHoraTermino: '',
  pontos: 0,
  tipoCampanha: 0,
  qrCode: '',
  pontuacaoUnica: false,
  totalPontuacoes: 0,
  observacao: '',
  excluido: false,
  campanhasLojas: [],
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function campanha(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CAMPANHA':
      return {
        ...state,
        campanha: action.campanha,
        totalRegistros: action.totalRegistros
      }
    case 'CAMPANHA_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.campanha.id,
        nome: action.campanha.nome,
        dataHoraInicio: action.campanha.dataHoraInicio,
        dataHoraTermino: action.campanha.dataHoraTermino,
        pontos: action.campanha.pontos,
        tipoCampanha: action.campanha.tipoCampanha,
        qrCode: action.campanha.qrCode,
        pontuacaoUnica: action.campanha.pontuacaoUnica,
        totalPontuacoes: action.campanha.totalPontuacoes,
        observacao: action.campanha.observacao,
        excluido: action.campanha.excluido,
        campanhasLojas: action.campanha.campanhasLojas
      }
    case 'CAMPANHA_UPDATED':
      return state
    case 'CAMPANHA_CLEAR':
      return {
        ...initialState,
        campanhasLojas: [],
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'CAMPANHA_CLEAR_ALL':
      return {
        ...state,
        campanha: []
      }
    case 'CAMPANHA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CAMPANHA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CAMPANHA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
