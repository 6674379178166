const initialState = {
  isLoading: false,
  totalRegistros: 0,
  relatorio: []
}

export function relatorio(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_RELATORIO':
      return {
        ...state,
        relatorio: action.relatorio,
        totalRegistros: action.totalRegistros
      }
    case 'RELATORIO_CLEAR':
      return initialState
    case 'RELATORIO_CLEAR_ALL':
      return {
        ...state,
        relatorio: []
      }
    case 'RELATORIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'RELATORIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'RELATORIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
