const initialState = {
  isLoading: false,
  totalRegistros: 0,
  produto: [],
  open: false,
  id: 0,
  nome: '',
  descricao: '',
  idProdutoReferencia: '',
  caminhoImagem: '',
  trocavel: false,
  pontos: 0,
  fatorPontuacao: 0,
  preco: 0,
  total: 0,
  idLoja: 0
}

export function produtoSelect(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PRODUTO_SELECT':
      return {
        ...state,
        produto: action.produto,
        totalRegistros: action.totalRegistros
      }
    case 'PRODUTO_SELECT_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        nome: action.nome,
        descricao: action.descricao,
        idProdutoReferencia: action.idProdutoReferencia,
        caminhoImagem: action.caminhoImagem,
        trocavel: action.trocavel,
        pontos: action.pontos,
        fatorPontuacao: action.fatorPontuacao,
        preco: action.preco,
        total: action.total,
        idLoja: action.idLoja
      }
    case 'PRODUTO_SELECT_UPDATED':
      return state
    case 'PRODUTO_SELECT_CLEAR':
      return initialState
    case 'PRODUTO_SELECT_CLEAR_ALL':
      return {
        ...state,
        produto: []
      }
    case 'PRODUTO_SELECT_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'PRODUTO_SELECT_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'PRODUTO_SELECT_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
