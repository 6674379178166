/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
  Button,
  Dialog,
  Hidden,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useDispatch, useSelector } from 'react-redux'  
import { usuarioLojaAction } from '_actions'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  }
}))

const SelectLoja = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const { usuarioLoja, loja, idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const options = usuarioLoja.map(u => ({ id: u.loja.id, nome: u.loja.nome }));

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const hasLojaSelected = useSelector((state) => {
    //console.log('hasLojaSelected')
    //console.log('state.usuarioLoja', state.usuarioLoja)
    //console.log('usuarioLoja', usuarioLoja)
    if (
      state.usuarioLoja.idLoja === '' ||
      state.usuarioLoja.idLoja === undefined
    ) {
      return false
    }
    return true
  })

  const dispatch = useDispatch()

  useEffect(() => {
    setInitialUsuarioLoja()
  }, [usuarioLoja])

  useEffect(() => {
    //console.log('idLoja', idLoja);
  }, [idLoja])

  useEffect(() => {
    setValue(idLoja)
  }, [])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)    }
  }, [value])

  //const handleChange = (event) => {
  //  if (event.target.value > 0) {
  //    let selectedUsuarioLoja = usuarioLoja.filter(
  //      (item) => item.idLoja === event.target.value
  //    )
  //    console.log('selectedUsuarioLoja', selectedUsuarioLoja)
  //    dispatch(
  //      usuarioLojaAction.changeDetailsUsuarioLoja(
  //        selectedUsuarioLoja[0]
  //      )
  //    )
  //  } else {
  //    dispatch(
  //      usuarioLojaAction.clearSelected()
  //    )
  //  }
  //  setOpen(false)
  //}

  const handleChangeAutoComplete = (obj) => {
    if (obj != null && obj.id > 0) {
      let selectedUsuarioLoja = usuarioLoja.filter(
        (item) => item.idLoja === obj.id
      )
      //console.log('selectedUsuarioLoja', selectedUsuarioLoja)
      dispatch(
        usuarioLojaAction.changeDetailsUsuarioLoja(
          selectedUsuarioLoja[0]
        )
      )
    } else {
      //dispatch(
      //  usuarioLojaAction.clearSelected()
      //)
    }
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
    //if (undefined !== usuarioLoja && usuarioLoja.length) {
    //  setDisabled(false)
    //} else {
    //  setDisabled(true)
    //}
    setDisabled(false)
  }

  const setInitialUsuarioLoja = () => {
    if (
      usuarioLoja.length === 0 ||
      usuarioLoja === undefined
    ) {
      return null
    } else if (
      undefined !== usuarioLoja &&
      usuarioLoja.length === 1
    ) {
      dispatch(
        usuarioLojaAction.changeDetailsUsuarioLoja(
          usuarioLoja[0]
        )
      )
    } else if (!hasLojaSelected) {
      //setOpen(true)
      //setDisabled(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDisabled(false)
  }

  const handleClear = () => {
    dispatch(
      usuarioLojaAction.clearSelected()
    )
    setOpen(false)
    setDisabled(false)
  }

  const returnContent = () => {
    return (
      <div>
        <Hidden smDown>
          <Typography variant="overline" color="inherit">
            Loja selecionada:
            <Typography variant="button" color="inherit">
              {' '}
              <b>{(hasLojaSelected ? loja.nome : 'TODAS')}</b>
            </Typography>
          </Typography>
        </Hidden>
        <Tooltip title="Trocar loja">
          <IconButton color="inherit" onClick={handleClickOpen}>
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>
            {' '}
            <Typography variant="overline" color="inherit">
              Minhas lojas
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form className={classes.container}>

              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                defaultValue={null}
                getOptionLabel={option => option.nome}
                renderInput={(params) => <TextField {...params} label="SELECIONE ..." fullWidth />}
                style={{ width: "300px" }}
                fullWidth
              />

              {/*
              <br/>
              <FormControl className={classes.formControl}>                

                <InputLabel id="loja-select-label">
                  VER LOJAS
                </InputLabel>
                <Select
                  labelId="loja-select-label"
                  id="loja-select"
                  value={(hasLojaSelected ? usuarioLoja.idLoja : 0)}
                  onChange={handleChange}
                  input={<Input />}
                >
                  <MenuItem value={0}>
                    <em>TODAS</em>
                  </MenuItem>
                  {usuarioLoja.map((row) => (
                    <MenuItem
                      key={row.loja.id}
                      loja={row.loja}
                      value={row.idLoja}
                      disabled={
                        row.idLoja === loja.id
                          ? true
                          : false
                      }
                    >
                      {row.loja.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              */}

            </form>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={disabled}
              align="left"
              onClick={handleClear}
              color="primary"
            >
              TODAS
            </Button>
            <Button
              disabled={disabled}
              align="left"
              onClick={handleClose}
              color="primary"
            >
              OK
            </Button>
            {/* <Button onClick={handleClose} color="primary">
              Selecionar
        </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  return (
    <div>
      {returnContent()}
    </div>
  )
}

SelectLoja.propTypes = {
  className: PropTypes.string
}
export default SelectLoja
