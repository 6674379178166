import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import Image from 'material-ui-image'
import { history } from 'utils'
import PropTypes from 'prop-types'
const useStyles = makeStyles((theme) => ({
  root: {},
  noResultText: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  image: { marginTop: theme.spacing(1) },
  button: {
    webkitTransition: ' all 300ms ease',
    transition: 'all 300ms ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 9px 40px -14px #E18C26',
      webkitTransition: ' all 300ms ease',
      transition: 'all 300ms ease',
      letterSpacing: '1.2px'
    }
  }
}))
const MessageData = ({ text, buttonText, buttonHref, srcImage }) => {
  const classes = useStyles()

  const handleClickContaBancaria = () => {
    history.push(buttonHref)
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <Image
            className={classes.image}
            disableTransition
            style={{ width: '300px', height: '120px' }}
            color="transparent"
            src={srcImage}
            aspectRatio={16 / 9}
          />
        </Grid>
        <Grid item>
          <Typography
            className={classes.noResultText}
            gutterBottom
            variant="h4"
            color="textPrimary"
          >
            {!!text && text}
          </Typography>
        </Grid>
        {buttonText && (
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              onClick={handleClickContaBancaria}
              color="primary"
            >
              {!!buttonText && buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

MessageData.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  srcImage: PropTypes.string
}

export default MessageData
