import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { relatorioAction, logAction } from '../../_actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import {
  withStyles,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import SearchIcon from '@material-ui/icons/Search';
import { Page } from 'components'
import moment from 'moment'
import { isEmpty } from 'validate.js'
import TipoLog from '../../utils/enums/tipoLog'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  gridButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    textAlign: 'right'
  }
})

const RelatorioLog = (props) => {
  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { relatorio, totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio
  const { tabelas } = props.log

  const [tabela, setTabela] = useState('')
  const [campo, setCampo] = useState('')
  const [usuario, setUsuario] = useState('')
  const [dataInicial, setDataInicial] = useState(undefined)
  const [dataFinal, setDataFinal] = useState(undefined)
  const [exibirVisualizacao, setExibirVisualizacao] = useState(false)

  useEffect(() => {
    const dataInicio = moment().subtract(1, "months")
    const dataFim = moment();

    setDataInicial(moment(dataInicio).format("YYYY-MM-DD"));
    setDataFinal(moment(dataFim).format("YYYY-MM-DD"));

    dispatch(relatorioAction.clear());
    dispatch(logAction.getTabelas())
  }, []);

  const handleSubmit = (event) => {
    if (tabela != null && dataInicial != undefined && dataFinal != undefined) {

      if (dataFinal >= dataInicial) {
        let payload = {
          Tabela: tabela,
          Campo: campo || '',
          Usuario: usuario || '',
          DataInicial: moment(dataInicial).format("YYYY-MM-DD"),
          DataFinal: moment(dataFinal).format("YYYY-MM-DD"),
          ExibirVisualizacao: exibirVisualizacao || false
        };

        dispatch(relatorioAction.getRelatorioLogs(payload, true));
      }
      else {
        toast.error('Data final deve ser maior que a Data inicial!')
      }
    } else {
      dispatch(relatorioAction.clear());
    }
  };

  const handleChangeTabela = (e) => {
    setTabela(e.target.value)
  }

  const handleChangeCampo = (e) => {
    setCampo(e.target.value)
  }

  const handleChangeUsuario = (e) => {
    setUsuario(e.target.value)
  }

  const handleChangeDataInicial = (e) => {
    setDataInicial(e.target.value)
  }

  const handleChangeDataFinal = (e) => {
    setDataFinal(e.target.value)
  }

  const handleChangeExibirVisualizacao = (e) => {
    const currentCheck = !exibirVisualizacao

    setExibirVisualizacao(currentCheck)
  }

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  return (
    <Page className={classes.root} title="Relatório - Logs do Sistema">
      <AccessControl
        rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioLogs"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Logs do Sistema'}
                    />
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          className={classes.textField}
                          id="idTabela"
                          label="Tabela *"
                          margin="normal"
                          value={tabela}
                          onChange={handleChangeTabela}
                          fullWidth
                          displayEmpty
                          labelId="idCategoriaInsumo"
                          variant="outlined"
                          isDisabled={(params.id != 0 ? true : false)}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        >
                          <MenuItem disabled value="">
                            <em>Tabela *</em>
                          </MenuItem>
                          {!isEmpty(tabelas) &&
                            undefined !== tabelas &&
                            tabelas.length &&
                            tabelas.map((row, index) => (
                              <MenuItem key={index} value={row}>
                                {row}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Campo"
                          value={campo}
                          onChange={handleChangeCampo}
                          id="usuario"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Usuário"
                          value={usuario}
                          onChange={handleChangeUsuario}
                          id="usuario"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          id="dataInicial"
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Data Intervalo Início *"
                          type="date"
                          value={dataInicial}
                          onChange={handleChangeDataInicial}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          id="dataFinal"
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Data Intervalo Final *"
                          type="date"
                          value={dataFinal}
                          onChange={handleChangeDataFinal}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={exibirVisualizacao}
                              onChange={handleChangeExibirVisualizacao}
                              color="primary"
                            />
                          }
                          label="Exibir Logs de Visualização"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right',
                        gap: 5
                      }}>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Código</TableCell>
                        <TableCell>Tabela</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Usuário</TableCell>
                        <TableCell>Campo</TableCell>
                        <TableCell>Valor Antigo</TableCell>
                        <TableCell>Valor Novo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row" style={{ width: '150px' }}>
                                {moment(n.dataHora).format("DD/MM/YYYY HH:mm")}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.codigo || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.tabela || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {TipoLog[n.tipo]}
                              </TableCell>
                              <TableCell component="th" scope="row" style={{ width: '150px' }}>
                                {n.usuario || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.campo || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.valorAntigo || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.valorNovo || ''}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (dataInicial != undefined && dataFinal != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 7 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={7} align="center">Informe uma Data de Início e uma Data de Final</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={4} colSpan={7}><b>Total</b></TableCell>
                          <TableCell rowSpan={4}><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RelatorioLog.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedRelatorioLogPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioLog))
)
export { connectedRelatorioLogPage as RelatorioLog }
