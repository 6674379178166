import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Switch
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { unidadeAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import SaveIcon from '@material-ui/icons/Save';
import { EditorHeader, Page } from 'components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const UnidadeEdit = (props) => {
  const dispatch = useDispatch()
  const { classes, unidade, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    if (params.id) {
      dispatch(unidadeAction.getUnidadeById(params.id))
    } else {
      dispatch(unidadeAction.clear())
    }

  }, []);

  useEffect(() => {

  }, [ready, props.unidade.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(unidadeAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.unidade.nome,
      abreviacao: props.unidade.abreviacao,
      arredondado: props.unidade.arredondado
    }

    if (params.id) {
      dispatch(unidadeAction.editUnidadeInfo(params.id, payload, true))
    } else {
      dispatch(unidadeAction.createUnidade(payload))
    }
  };

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Unidade'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Unidades - Editar Unidade'
          : 'Gerência de Unidades - Nova Unidade'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >       
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formUnidade"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Unidades"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Unidade' tabela='Unidade' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.unidade.nome}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="abreviacao"
                        label="Abreviação *"
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('abreviacao')}
                        value={props.unidade.abreviacao}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.unidade.arredondado}
                            onChange={handleChange('arredondado')}
                            color="primary"
                          />
                        }
                        label="Arredondar valores"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/unidades')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.unidade.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.unidade.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.unidade.isLoading ? 'Atualizando unidade...' : 'Atualizar') : (props.unidade.isLoading ? 'Salvando unidade...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

UnidadeEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedUnidadeEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(UnidadeEdit))
)
export { connectedUnidadeEditPage as UnidadeEdit }
